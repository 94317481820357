import { Box, Button, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import { IconPencil } from '@tabler/icons-react'
import styled from 'styled-components'
import {
  MOBILE_NAV_HEIGHT,
  MOBILE_SEARCH_HEIGHT,
} from '../../../../helpers/static-constants'

interface ProfileBioScrollBoxProps {
  isSearchOpen: boolean
  isEdit?: boolean
}

export const UserPreferencesGridContainer = styled(Grid).attrs(() => ({
  container: true,
  pb: { md: 2 },
}))(({ theme }) => ({}))

export const UserPreferencesHeaderGridContainer = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  textAlign: 'end',
}))(({ theme }) => ({}))

export const UserPreferencesHeaderIconPencil = styled(IconPencil).attrs(() => ({
  size: '18px',
  stroke: 2,
}))(({ theme }) => ({}))

export const UserPreferencesSidebarGridContainer = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  md: 3.6,
}))(({ theme }) => ({}))

export const UserPreferencesSidebarInnerContainer = styled(Box).attrs(() => ({
  p: 2,
  color: 'black',
}))(() => {
  const theme = useTheme()
  return {
    [theme.breakpoints.down('md')]: {
      marginRight: '10px  !important',
    },
    [theme.breakpoints.up('md')]: {
      borderRight: `1px solid ${theme.palette.grey[200]}`,
      marginLeft: '0',
    },
  }
})

export const UserPreferencesStepContainer = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    overflow: 'auto',
    gap: '8px',
    pt: '16px',
  },
}))``

export const UserPreferencesContentGridContainer = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  md: 8.4,
  p: { xs: '16px 0px 0px 0px', md: 3 },
}))(({ theme }) => ({}))

export const UserPreferencesContentInnerContainer = styled(
  ({ isSearchOpen, ...rest }: ProfileBioScrollBoxProps & any) => (
    <Box {...rest} />
  ),
).attrs<ProfileBioScrollBoxProps>(({ isSearchOpen }) => {
  return {
    sx: {
      height: {
        xs: `calc(100vh - ${
          isSearchOpen
            ? `${MOBILE_SEARCH_HEIGHT + MOBILE_NAV_HEIGHT + 302}px`
            : `${MOBILE_NAV_HEIGHT + 302}px`
        })`,
        md: '100%',
      },
      overflow: 'auto',
      color: 'black',
      bgcolor: 'white',
    },
  }
})<ProfileBioScrollBoxProps>(({ theme }) => ({}))

export const UserPreferencesEditButton = styled(Button).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'contained',
    size: 'large',
    disableElevation: true,
    sx: {
      bgcolor: 'white',
      color: theme.palette.primary.light,
      textTransform: 'none',
      lineHeight: 'normal',
      borderRadius: '10px',
      border: `1px solid ${theme.palette.primary.light}`,
      py: '8px',
      px: '16px',
      height: '36px',
      width: '82px',
      '&:hover': {
        bgcolor: 'white',
        border: `1px solid ${theme.palette.primary.light}`,
      },
    },
    disableRipple: true,
    style: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0.02em',
      lineHeight: '20px',
    },
  }
})(({ theme }) => ({}))

export const UserPreferencesUpdateButton = styled(Button).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'contained',
    size: 'large',
    disableElevation: true,
    sx: {
      bgcolor: theme.palette.primary.light,
      textTransform: 'none',
      lineHeight: 'normal',
      borderRadius: '10px',
      py: '8px',
      px: '16px',
      height: '36px',
      width: '82px',
      '&:hover': {
        bgcolor: theme.palette.primary.light,
      },
    },
    disableRipple: true,
    style: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '0.02em',
      lineHeight: '20px',
    },
  }
})(() => {
  const theme = useTheme()
  return {
    [theme.breakpoints.down('md')]: {
      marginRight: '10px  !important',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '0',
    },
  }
})

export const UserPreferencesStepsTypography = styled(Typography).attrs(() => ({
  p: 1,
  variant: 'body2',
  noWrap: true,
  sx: { cursor: 'pointer', textTransform: { xs: 'capitalize', md: 'none' } },
  style: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.02em',
    lineHeight: '20px',
  },
}))(({ theme }) => ({}))

export const ProfileBioContainer = styled(Box).attrs(() => ({
  sx: {
    width: '840px',
    pt: { xs: '16px', md: '40px' },
  },
}))(({ theme }) => ({}))

export const ProfileBioScrollBox = styled(
  ({
    isSearchOpen,
    isEdit = false,
    ...rest
  }: ProfileBioScrollBoxProps & any) => <Box {...rest} />,
).attrs<ProfileBioScrollBoxProps>(({ isSearchOpen, isEdit }) => {
  return {
    sx: {
      height: {
        xs: `calc(100vh - ${
          isSearchOpen
            ? isEdit
              ? `${MOBILE_SEARCH_HEIGHT + MOBILE_NAV_HEIGHT + 324}px`
              : `${MOBILE_SEARCH_HEIGHT + MOBILE_NAV_HEIGHT + 270}px`
            : isEdit
            ? `${MOBILE_NAV_HEIGHT + 324}px`
            : `${MOBILE_NAV_HEIGHT + 270}px`
        })`,
        md: '100%',
      },
      overflow: 'auto',
    },
  }
})<ProfileBioScrollBoxProps>(({ theme }) => ({}))
