import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store'
import {
  setMessage,
  setOpen,
  setSeverity,
  subscribeToNewsletter,
} from '../store/authSlice'
import { selectEater } from '../store/profileSlice'
import { NewsletterSubscriptionRequest } from '../types'
import CustomButton from './ui/CustomButton'
import InputComponent from './ui/InputComponent'

const SubscribeForm: React.FC = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useAppDispatch()
  const eater = useAppSelector(selectEater)
  const [email, setEmail] = useState<string>('')

  const onNewsletterSubscribe = async (): Promise<void> => {
    if (email.length > 0) {
      if (!isValidEmail(email)) {
        dispatch(setOpen(true))
        dispatch(setMessage('Please Enter valid Email'))
        dispatch(setSeverity('error'))
      } else {
        const nsr: NewsletterSubscriptionRequest = {
          email,
          firstName: eater?.firstName ?? '',
          lastName: eater?.lastName ?? '',
        }
        const result = await dispatch(subscribeToNewsletter(nsr))
        if (subscribeToNewsletter.fulfilled.match(result)) {
          dispatch(setOpen(true))
          dispatch(setMessage('Thank you for subscribing!'))
          dispatch(setSeverity('success'))
          setEmail('')
        }
      }
    } else {
      dispatch(setOpen(true))
      dispatch(setMessage('Please Enter Email'))
      dispatch(setSeverity('error'))
    }
  }

  const isValidEmail = (email): boolean => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailPattern.test(email)
  }

  return (
    <Box
      sx={{
        flexGrow: 0,
        width: isSmallScreen ? '100%' : 'auto',
      }}
      className="order-2 tab-order-1"
    >
      <Typography
        variant="subtitle1"
        color={theme.palette.common.white}
        mb="6px"
      >
        Join Our Newsletter
      </Typography>
      <div style={{ display: 'flex' }}>
        <InputComponent
          id="email"
          value={email}
          setValue={setEmail}
          placeholder="Enter email here"
          type="fill"
          sx={{
            width: isSmallScreen ? '100%' : 'auto',
            marginRight: '10px',
          }}
          inputProps={{ autoComplete: 'off' }}
        />
        <CustomButton
          title="Subscribe"
          textColor={theme.palette.grey.A100}
          backgroundColor={theme.palette.primary.main}
          textVariant="subtitle1"
          action={() => {
            void onNewsletterSubscribe()
          }}
        />
      </div>
    </Box>
  )
}

export default SubscribeForm
