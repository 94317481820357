import {
  Box,
  styled as muiStyled,
  Typography,
  TypographyProps,
} from '@mui/material'
import styled from 'styled-components'

export const CuisineToTryMainContainer = styled(Box).attrs(() => ({
  sx: {
    mt: { xs: '40px', sm: '80px', md: '110px' },
  },
}))(({ theme }) => ({}))

export const CuisineToTryBoxContainer = styled(Box).attrs(() => ({
  sx: {
    position: 'relative',
    width: '100%',
    height: '70%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${'/new/images/downloadAppBg.svg'})`,
    backgroundRepeat: 'no-repeat',
    py: { xs: '36px', sm: '40px', md: '56px' },
  },
}))(({ theme }) => ({}))

export const CuisineToTryInnerContainer = styled(Box).attrs(() => ({
  sx: {
    position: 'relative',
    mt: { xs: '40px', sm: '60px', md: '80px' },
  },
}))(({ theme }) => ({}))

export const CuisineToTrySwiperSlideContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
  }
})

export const CuisineToTrySwiperImgContainer = styled(Box).attrs(() => ({
  sx: {
    width: { xs: '81px', sm: '120px', md: '170px' },
    height: { xs: '96px', sm: '140px', md: '170px' },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))(({ theme }) => ({}))

export const CuisineToTryTypographyTitle = muiStyled(
  Typography,
)<TypographyProps>(() => {
  return {
    textAlign: 'center',
  }
})

export const CuisineToTryNoDataTypographyTitle = muiStyled(
  Typography,
)<TypographyProps>(() => {
  return {
    fontWeight: 600,
    textAlign: 'center',
    marginTop: '159px',
    marginBottom: '159px',
  }
})
