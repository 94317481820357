import {
  Box,
  CircularProgress,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  IconBookmark,
  IconBookmarkFilled,
  IconPointFilled,
} from '@tabler/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper/types'
import ImgIcon from '../ui/ImgIcon'
import TagChip, { IMatchData } from '../ui/TagChip'

import { useAuth0 } from '@auth0/auth0-react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useAppDispatch, useAppSelector } from '../../store'
import { selectSavedRestaurantIds } from '../../store/profileSlice'
import { favoriteRestaurant } from '../../store/restaurantSlice'

interface Data {
  percentage: string
  title?: string
  matchData?: IMatchData[]
}

export interface RestaurantCardProps {
  imgList: string[]
  title: string
  subTitle: string
  distance?: string
  tagChipData?: Data[]
  tagChipBgColor?: 'orange' | 'liteOrange' | 'white'
  alias?: string
  id: string
}

const RestaurantCard: React.FC<RestaurantCardProps> = ({
  imgList,
  title,
  subTitle,
  distance,
  tagChipData,
  tagChipBgColor = 'orange',
  alias = '',
  id,
}) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { isAuthenticated } = useAuth0()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const saveRestaurants = useAppSelector(selectSavedRestaurantIds)
  const [isSaved, setIsSaved] = useState(false)
  const [staticLoader, setStaticLoader] = useState(false)
  const [loadingImage, setLoadingImage] = useState(true)
  const swiperRef = useRef<SwiperType | null>(null)

  useEffect(() => {
    if (swiperRef.current?.autoplay) {
      swiperRef.current.autoplay.stop()
    }
  }, [])

  useEffect(() => {
    if (saveRestaurants?.length) {
      const isSaved = saveRestaurants.includes(id ?? '')
      setIsSaved(isSaved)
    }
  }, [saveRestaurants])

  const handleMouseEnter = (): void => {
    swiperRef.current?.autoplay.start()
  }

  const handleMouseLeave = (): void => {
    swiperRef.current?.autoplay.stop()
  }

  const onClickBookmark = async (e): Promise<void> => {
    e.stopPropagation()
    setStaticLoader(true)
    await dispatch(favoriteRestaurant(alias))
    setStaticLoader(false)
  }

  return (
    <div>
      <Box
        id="imgSection"
        sx={{
          height: { xs: '182px', md: '200px' },
          marginBottom: '12px',
          position: 'relative',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="restaurant-card-swipe">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            effect={imgList.length > 1 ? 'fade' : undefined}
            loop={imgList.length > 1}
            autoplay={
              imgList.length > 1
                ? {
                    delay: 100,
                    disableOnInteraction: false,
                  }
                : undefined
            }
            pagination={imgList.length > 1 ? { clickable: true } : undefined}
            grabCursor={imgList.length > 1}
            modules={[Autoplay, Pagination, Navigation, EffectFade]}
            className="mySwiper"
            onSwiper={(swiper) => {
              swiperRef.current = swiper
            }}
          >
            {imgList.map((img: string, index: number) => (
              <SwiperSlide
                key={index}
                virtualIndex={index}
                style={{
                  background: 'transparent',
                }}
              >
                {loadingImage && (
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      borderRadius: '20px',
                      height: { xs: 182, md: 200 },
                    }}
                    width="100%"
                  />
                )}
                <img
                  className="no-select"
                  src={img}
                  alt={`Resactly | ${title}`}
                  style={{
                    borderRadius: '20px',
                    objectFit: 'cover',
                    height: isMediumScreen ? '182px' : '200px',
                    display: !loadingImage ? 'block' : 'none',
                    filter: 'brightness(80%) saturate(115%)',
                  }}
                  onLoad={() => setLoadingImage(false)}
                  onError={() => setLoadingImage(false)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div
          style={{
            position: 'absolute',
            top: '6px',
            left: '6px',
            zIndex: 100,
          }}
        >
          {isAuthenticated && tagChipData && tagChipData[0].percentage && (
            <TagChip data={tagChipData} bgColor={tagChipBgColor} />
          )}
        </div>
        <Box
          id={isMediumScreen || isSaved ? '' : 'saveBtn'}
          style={{
            position: 'absolute',
            top: '7px',
            right: '7px',
            zIndex: 100,
            cursor: 'pointer',
          }}
        >
          {isAuthenticated && (
            <ImgIcon
              onClick={(evt) => {
                void onClickBookmark(evt)
              }}
              alt="bookmark"
              iconElement={
                <>
                  {staticLoader ? (
                    <CircularProgress
                      style={{
                        width: '30px',
                        height: '30px',
                        color: theme.palette.background.paper,
                      }}
                      color="inherit"
                    />
                  ) : isSaved ? (
                    <IconBookmarkFilled
                      color={theme.palette.background.paper}
                      height={20}
                      width={20}
                    />
                  ) : (
                    <IconBookmark
                      stroke={2}
                      color="white"
                      height={20}
                      width={20}
                    />
                  )}
                </>
              }
              wrapperStyle={{
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                height: '30px',
                width: '30px',
                boxShadow: `0px 0px 4px 0px #00000040`,
              }}
            />
          )}
        </Box>
      </Box>

      <Box sx={{ px: '4px' }}>
        <Typography
          noWrap
          variant="body2"
          style={{ textTransform: 'capitalize', textAlign: 'left' }}
          sx={{ mb: '4px' }}
          maxWidth="100%"
        >
          {title}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Typography
            noWrap
            sx={{
              textOverflow: 'ellipsis',
              maxWidth: { xs: '65%', md: '70%' },
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textTransform: 'capitalize',
              color: theme.palette.grey[400],
              flexShrink: 1,
              flexGrow: 1,
            }}
            variant="subtitle1"
          >
            {subTitle}
          </Typography>
          {distance && (
            <>
              <IconPointFilled
                style={{
                  marginLeft: '8px',
                  marginRight: '8px',
                  color: theme.palette.grey[400],
                }}
                height={17}
                width={17}
              />
              <Typography
                variant="subtitle1"
                style={{
                  textTransform: 'capitalize',
                  color: theme.palette.grey[400],
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'left',
                }}
              >
                {distance} Mi
              </Typography>
            </>
          )}
        </div>
      </Box>
    </div>
  )
}

export default RestaurantCard
