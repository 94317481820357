import {
  Box,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { IconInfoCircle } from '@tabler/icons-react'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import OnboardSearch from '../../pages/eater/onboardPages/OnboardSearch'
import theme from '../../theme'
import {
  IFilterItem,
  IFilterResponseItem,
  IFilterTabItems,
  IFilterTabList,
  restaurantFilterRefMethods,
} from '../../types'
import { debugLog } from '../../utils/log-helper'

interface FilterProps {
  tabList: IFilterTabList[]
  currentValue: IFilterResponseItem
}

interface PanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const TabPanel = (props: PanelProps): JSX.Element => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{
        width: '100%',
      }}
      {...other}
    >
      {value === index && <Box sx={{ p: 2, width: '100%' }}>{children}</Box>}
    </div>
  )
}

const a11yProps = (index: number): { id: string; 'aria-controls': string } => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const CheckboxFilter = ({
  items,
  selectedValues,
  updateSelectedValues,
  tabKey,
  allowSearch = false,
}: {
  items: IFilterTabItems[]
  selectedValues: IFilterResponseItem
  updateSelectedValues: (tabKey: string, values: any) => void
  tabKey: string
  allowSearch: boolean
}): JSX.Element => {
  const theme = useTheme()

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: IFilterItem,
  ): void => {
    const updatedValues = { ...selectedValues }
    updatedValues[tabKey] = updatedValues[tabKey] || []
    if (event.target.checked) {
      updatedValues[tabKey].push(item)
    } else {
      updatedValues[tabKey] = updatedValues[tabKey].filter(
        (selectedItem: IFilterItem) => selectedItem.key !== item.key,
      )
    }
    updateSelectedValues(tabKey, updatedValues[tabKey])
  }
  const setSuggestedSearch = useCallback(
    (item: string): void => debugLog(item.toLocaleLowerCase()),
    [],
  )

  return (
    <Box>
      {allowSearch && (
        <div
          style={{
            marginBottom: '16px',
          }}
        >
          <OnboardSearch
            indexKey={'REACT_APP_RESTAURANT_INDEX'}
            key={`restaurant-filter`}
            setWord={setSuggestedSearch}
            searchFieldName={'name'}
            searchWidth="100%"
          />
        </div>
      )}
      {items.map((item) => (
        <FormControlLabel
          key={item.key}
          sx={{
            '&.MuiFormControlLabel-root': {
              width: '100%',
              padding: '12px',
              '& .MuiFormControlLabel-label': {
                fontSize: theme.typography.subtitle1,
                paddingLeft: '16px',
                fontWeight: 500,
                color: theme.palette.grey[800],
              },
            },
          }}
          control={
            <Checkbox
              checked={
                selectedValues[tabKey]?.some(
                  (selectedItem: {
                    key: number
                    name: string
                    value: string
                  }) => selectedItem.key === item.key,
                ) || false
              }
              sx={{
                color: theme.palette.grey[300],
                textTransform: 'capitalize',
                padding: '0px',
                '&.Mui-checked': {
                  color: theme.palette.secondary.main,
                },
              }}
              onChange={(event) => handleChange(event, item)}
            />
          }
          label={item.name}
        />
      ))}
    </Box>
  )
}

const RadioFilter = ({
  items,
  selectedValues,
  updateSelectedValues,
  tabKey,
}: {
  items: IFilterTabItems[]
  selectedValues: IFilterResponseItem
  updateSelectedValues: (tabKey: string, value: any) => void
  tabKey: string
}): JSX.Element => {
  const theme = useTheme()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const selectedItem = items.find(
      (item) => item.key === parseInt(event.target.value),
    )
    if (selectedItem) {
      updateSelectedValues(tabKey, selectedItem)
    }
  }

  return (
    <RadioGroup
      value={selectedValues[tabKey]?.key.toString() || ''}
      onChange={handleChange}
    >
      {items.map((item) => (
        <FormControlLabel
          sx={{
            '&.MuiFormControlLabel-root': {
              width: '100%',
              padding: '12px',
              '& .MuiFormControlLabel-label': {
                fontSize: '14px',
                fontWeight: 500,
                paddingLeft: '16px',
                color: theme.palette.grey[800],
              },
            },
          }}
          color={theme.palette.grey[300]}
          key={item.key}
          value={item.key.toString()}
          control={
            <Radio
              sx={{
                color: theme.palette.grey[300],
                padding: '0px',
                '&.Mui-checked': {
                  color: theme.palette.secondary.main,
                },
              }}
            />
          }
          label={item.name}
        />
      ))}
    </RadioGroup>
  )
}

const RangeFilter = ({
  items,
  selectedValues,
  updateSelectedValues,
  tabKey,
  priceText,
}: {
  items: IFilterTabList
  selectedValues: IFilterResponseItem
  updateSelectedValues: (tabKey: string, value: number | number[]) => void
  tabKey: string
  priceText: string
}): JSX.Element => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleChange = (event: Event, newValue: number | number[]): void => {
    updateSelectedValues(tabKey, newValue)
  }
  const marks = items.items.map((item) => {
    return { value: Number(item.value), label: '' }
  })

  return (
    <Box width={'100%'}>
      {priceText === 'Price' && (
        <Box
          padding={1}
          borderRadius="4px"
          bgcolor={theme.palette.grey[100]}
          display="flex"
          justifyContent="start"
          alignItems="center"
          marginBottom="8px"
        >
          <IconInfoCircle
            stroke={2}
            size={14}
            style={{
              color: theme.palette.grey[500],
            }}
          />
          <Typography
            variant="subtitle2"
            paddingLeft="10px"
            color={theme.palette.grey[500]}
            component="p"
          >
            The price is based on per person
          </Typography>
        </Box>
      )}
      <Stack
        sx={{ height: { xs: priceText === 'Price' ? 220 : 260, md: 'auto' } }}
        spacing={1}
        direction={{ xs: 'row', md: 'column' }}
      >
        <Box
          sx={{
            display: { xs: 'flex' },
            justifyContent: { xs: 'space-between' },
            flexDirection: { xs: 'column', md: 'row' },
            flexFlow: { xs: 'column-reverse', md: 'row' },
            width: { xs: '40px', md: 'auto' },
            order: { md: 1 },
          }}
        >
          {items.items.map((item, index) => {
            return (
              <Typography
                key={index}
                variant="subtitle2"
                sx={{
                  cursor: 'pointer',
                  fontWeight:
                    (selectedValues[tabKey] || marks[0].value) ===
                    Number(item.value)
                      ? 600
                      : 500,
                }}
                color={
                  (selectedValues[tabKey] || marks[0].value) ===
                  Number(item.value)
                    ? theme.palette.secondary.dark
                    : theme.palette.grey[800]
                }
              >
                {item.name}
              </Typography>
            )
          })}
        </Box>
        <Slider
          value={selectedValues[tabKey] || marks[0].value}
          onChange={handleChange}
          aria-labelledby="discrete-slider"
          step={items.rangeStep ?? 5}
          orientation={isSmallScreen ? 'vertical' : 'horizontal'}
          marks={marks}
          min={marks[0].value}
          max={marks[marks.length - 1].value}
          sx={{
            '&.MuiSlider-root': {
              color: theme.palette.primary.light,
              width: { md: '95%' },
            },
            '& .MuiSlider-mark': {
              width: '0',
            },

            '& .MuiSlider-markLabelActive': {
              color: theme.palette.secondary.dark,
              fontWeight: 600,
            },
            '& .MuiSlider-thumb': {
              width: 24,
              height: 24,
              backgroundColor: theme.palette.background.paper,
              border: `5px solid ${theme.palette.primary.light}`,
              '&::before': {
                boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
              },
              '&:hover, &.Mui-focusVisible, &.Mui-active': {
                boxShadow: 'none',
              },
            },
            '& .MuiSlider-thumb::after': {
              width: 24,
              height: 24,
            },
          }}
        />
      </Stack>
    </Box>
  )
}

const SelectedItemCount = ({
  tabName,
  selectedItemTabName,
}: {
  tabName: string
  selectedItemTabName: IFilterResponseItem
}): JSX.Element => {
  const theme = useTheme()
  return (
    <Typography
      color="white"
      component="p"
      sx={{
        marginLeft: '7px',
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      variant="subtitle2"
      bgcolor={theme.palette.secondary.main}
    >
      {selectedItemTabName[tabName].length}
    </Typography>
  )
}

const FilterList = forwardRef<restaurantFilterRefMethods, FilterProps>(
  function FilterList(props: FilterProps, ref) {
    const { tabList, currentValue } = props
    const [value, setValue] = useState<number>(0)
    const [selectedValues, setSelectedValues] =
      useState<IFilterResponseItem>(currentValue)
    const filterRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      const defaultValues = { ...currentValue }
      tabList.forEach((tab) => {
        if (
          tab.filterType === 'range' &&
          !defaultValues[tab.name.toLowerCase()]
        ) {
          const defaultValue = tab.items[0]?.value
            ? Number(tab.items[0].value)
            : 0

          defaultValues[tab.name.toLowerCase()] = defaultValue
        }
      })
      setSelectedValues(defaultValues)
    }, [tabList, currentValue])

    useImperativeHandle(ref, () => ({
      getSelectedValues: () => selectedValues,
    }))

    const handleChange = (
      event: React.SyntheticEvent,
      newValue: number,
    ): void => {
      setValue(newValue)
    }

    const updateSelectedValues = (tabKey: string, values: any): void => {
      setSelectedValues((prevValues: any) => ({
        ...prevValues,
        [tabKey]: values,
      }))
    }

    const renderFilterContent = (tab: IFilterTabList): JSX.Element | null => {
      switch (tab.filterType) {
        case 'checkbox':
          return (
            <CheckboxFilter
              items={tab.items}
              selectedValues={selectedValues}
              updateSelectedValues={updateSelectedValues}
              tabKey={tab.name.toLowerCase()}
              allowSearch={tab.allowSearch ?? false}
            />
          )
        case 'radio':
          return (
            <RadioFilter
              items={tab.items}
              selectedValues={selectedValues}
              updateSelectedValues={updateSelectedValues}
              tabKey={tab.name.toLowerCase()}
            />
          )
        case 'range':
          return (
            <RangeFilter
              items={tab}
              selectedValues={selectedValues}
              updateSelectedValues={updateSelectedValues}
              tabKey={tab.name.toLowerCase()}
              priceText={tab.name}
            />
          )
        default:
          return null
      }
    }

    return (
      <div ref={filterRef}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex',
            marginY: '16px',
            borderTop: `1px solid ${theme.palette.grey[200]}`,
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              width: '130px',
              textAlign: 'start',
            }}
          >
            {tabList.map((tab, index) => (
              <Tab
                sx={{
                  textTransform: 'capitalize',
                  paddingY: '10px',
                  alignItems: 'start',
                  px: { xs: '0px', sm: '16px' },
                  '&.MuiTab-root': {
                    fontSize: '14px',
                    fontWeight: 500,
                    color: theme.palette.grey[800],
                    minHeight: '48px',
                    justifyContent: 'start',
                    alignItems: 'center',
                    '&.Mui-selected': {
                      color: theme.palette.secondary.dark,
                    },
                  },
                }}
                icon={
                  tab.filterType === 'checkbox' &&
                  selectedValues?.[tab.name.toLowerCase()]?.length !== 0 &&
                  Object.keys(selectedValues).includes(
                    tab.name.toLowerCase(),
                  ) ? (
                    <SelectedItemCount
                      tabName={tab.name.toLowerCase()}
                      selectedItemTabName={selectedValues}
                    />
                  ) : undefined
                }
                iconPosition="end"
                label={tab.name}
                {...a11yProps(index)}
                key={index}
              />
            ))}
          </Tabs>
          <div style={{ height: '300px', overflow: 'auto', width: '376px' }}>
            {tabList.map((tab, index) => (
              <TabPanel key={index} value={value} index={index}>
                {renderFilterContent(tab)}
              </TabPanel>
            ))}
          </div>
        </Box>
      </div>
    )
  },
)

export default FilterList
