import { useEffect, useState } from 'react'
import { RudderAnalytics } from '@rudderstack/analytics-js'
import { debugLog } from './utils/log-helper'

const useRudderStackAnalytics = (): RudderAnalytics | undefined => {
  const [analytics, setAnalytics] = useState<RudderAnalytics>()

  useEffect(() => {
    if (!analytics) {
      const analyticsInstance = new RudderAnalytics()
      analyticsInstance.load(
        process.env.REACT_APP_RUDDERSTACK_SOURCE ?? '',
        'https://okaoincoomexbi.dataplane.rudderstack.com',
      )

      analyticsInstance.ready(() => {
        debugLog('We are all set!!!')
      })

      setAnalytics(analyticsInstance)
    }
  }, [analytics])

  return analytics
}

export default useRudderStackAnalytics
