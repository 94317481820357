import { Fab, useMediaQuery, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { IconDotsVertical, IconPencil } from '@tabler/icons-react'
import React, { useState } from 'react'
import { ProfileMoreButtonCustomButton } from '../styled/MoreButton.styled'
import {
  ProfileHeaderContainer,
  ProfileHeaderCustomButtonCancel,
  ProfileHeaderCustomButtonEdit,
  ProfileHeaderCustomButtonUpdate,
  ProfileHeaderRightContainer,
  ProfileHeaderTitleTypography,
} from '../styled/ProfilePage.styled'
import ProfilePopup from './ProfilePopup'

interface ProfileHeaderProps {
  currentTab: string
  isEdit: boolean
  isValid: boolean
  onCancel: () => void
  onUpdate: () => void
  onEditProfile: (isEditing: boolean) => void
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  currentTab,
  isEdit,
  isValid,
  onCancel,
  onUpdate,
  onEditProfile,
}) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const onPopupOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (currentTab === 'Preferences' && isSmallScreen) {
      onEditProfile(true)
    } else {
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    }
  }

  return (
    <>
      {!isSmallScreen ? (
        <ProfileHeaderContainer>
          <ProfileHeaderTitleTypography>Profile</ProfileHeaderTitleTypography>
          {(currentTab === 'Bio' || currentTab === 'Preferences') && (
            <ProfileHeaderRightContainer>
              {isEdit ? (
                <>
                  <ProfileHeaderCustomButtonCancel
                    title="Cancel"
                    onClick={onCancel}
                  />
                  <ProfileHeaderCustomButtonUpdate
                    title="Update"
                    disabled={isValid}
                    onClick={onUpdate}
                  />
                </>
              ) : (
                <>
                  <ProfileMoreButtonCustomButton
                    icon={<IconDotsVertical size={16} stroke={2} />}
                    aria-controls={open ? 'edit-button' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={onPopupOpen}
                  />
                  <ProfileHeaderCustomButtonEdit
                    title="Edit"
                    onClick={() => onEditProfile(true)}
                    startIcon={<IconPencil size={18} stroke={2} />}
                  />
                </>
              )}
            </ProfileHeaderRightContainer>
          )}
        </ProfileHeaderContainer>
      ) : (
        <>
          {(currentTab === 'Bio' || currentTab === 'Preferences') && (
            <Box>
              {!isEdit ? (
                <Fab
                  color="primary"
                  size="small"
                  id="edit-button"
                  aria-controls={open ? 'edit-button' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={onPopupOpen}
                  sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                  }}
                >
                  <IconPencil stroke={2} size={16} />
                </Fab>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    position: 'fixed',
                    bottom: 0,
                    right: 0,
                    p: '8px',
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 100,
                    width: '100%',
                  }}
                >
                  <ProfileHeaderCustomButtonCancel
                    title="Cancel"
                    onClick={onCancel}
                    fullWidth
                  />
                  <ProfileHeaderCustomButtonUpdate
                    title="Update"
                    disabled={isValid}
                    onClick={onUpdate}
                    fullWidth
                  />
                </Box>
              )}
            </Box>
          )}
        </>
      )}
      <ProfilePopup
        anchorEl={anchorEl}
        id="edit-button"
        openPop={open}
        setAnchorEl={setAnchorEl}
        setEditProfile={onEditProfile}
      />
    </>
  )
}

export default ProfileHeader
