import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import { selectSocialLinks, setSocialLinks } from '../../../store/onboardSlice'
import { OnboardSocialRefMethods, SocialLinksState } from '../../../types'
import OnboardTitleTypography from './OnboardTitleTypography'
import { OnboardRequestTextField } from './styled/OnboardRequest.styled'
import { OnboardSocialWarper } from './styled/OnboardSocial.styled'

interface OnboardSocialProps {
  title?: string
  isEditPreference?: boolean
  alignItems?: 'center' | 'right' | 'left'
}

const OnboardSocial = forwardRef<OnboardSocialRefMethods, OnboardSocialProps>(
  function OnboardSocial(
    { title, isEditPreference = false, alignItems = 'center' },
    ref,
  ): JSX.Element {
    const dispatch = useAppDispatch()
    const socialLinks = useAppSelector(selectSocialLinks)
    const [socialLinksData, setSocialLinksData] =
      useState<SocialLinksState>(socialLinks)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { id, value } = event.target
      setSocialLinksData((prev) => ({ ...prev, [id]: value }))
    }

    const getPayload = (): SocialLinksState => {
      dispatch(setSocialLinks(socialLinksData))
      return socialLinksData
    }

    useImperativeHandle(ref, () => ({ getPayload }))

    return (
      <>
        {title && (
          <OnboardTitleTypography
            title={title}
            subTitle=""
            isEditPreference={isEditPreference}
            alignItems={alignItems}
          />
        )}

        <OnboardSocialWarper>
          <OnboardRequestTextField
            disabled={isEditPreference}
            id="facebook"
            label="Facebook"
            value={socialLinksData.facebook}
            onChange={handleChange}
          />
          <OnboardRequestTextField
            disabled={isEditPreference}
            id="instagram"
            label="Instagram"
            value={socialLinksData.instagram}
            onChange={handleChange}
          />
          <OnboardRequestTextField
            disabled={isEditPreference}
            id="x"
            label="Twitter"
            value={socialLinksData.x}
            onChange={handleChange}
          />
          <OnboardRequestTextField
            disabled={isEditPreference}
            id="tiktok"
            label="Tiktok"
            value={socialLinksData.tiktok}
            onChange={handleChange}
          />
        </OnboardSocialWarper>
      </>
    )
  },
)

export default OnboardSocial
