import Box from '@mui/material/Box'
import React from 'react'
import { Outlet, useLocation } from 'react-router'
import NavBar from '../components/navBar/NavBar'
import ScrollToTop from '../components/ScrollToTop'
import Footer from '../components/Footer'
import { ROUTES } from '../helpers/routes-helper'

const WebLayout: React.FC = () => {
  const location = useLocation()
  const hideFooterPaths = [ROUTES.HOME, ROUTES.ABOUT]
  const showFooter = hideFooterPaths.includes(location.pathname)
  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <NavBar />
      <Outlet />
      {showFooter && <Footer />}
      <ScrollToTop />
    </Box>
  )
}

export default WebLayout
