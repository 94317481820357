import { UserInfo } from '../types'
import { store } from '../store'
import { jwtDecode } from './jwt-helper'
interface DecodedToken {
  exp: number
  [key: string]: any
}

export const getAccessToken = (): string => {
  return (
    store.getState().auth.accessToken ??
    window.localStorage.getItem('access-token') ??
    ''
  )
}

export const setAccessTokenToLocal = (token: string): void => {
  window.localStorage.setItem('access-token', token)
}

export const getUserInfoLocalStorage = (): UserInfo => {
  return JSON.parse(window.localStorage.getItem('userInfo') ?? '{}')
}

export const clearLocalStorage = (): void => {
  window.localStorage.removeItem('login')
  window.localStorage.removeItem('userInfo')
  window.localStorage.removeItem('access-token')
  window.localStorage.removeItem('location')
}

export const checkAuthToken = (): boolean => {
  try {
    const token = getAccessToken()

    if (!token) {
      return false
    }
    const decodedToken: DecodedToken = jwtDecode(token)
    const currentTime = Math.floor(Date.now() / 1000)

    return decodedToken.exp > currentTime
  } catch (error) {
    return false
  }
}

export const checkAndClearAuth = (): boolean => {
  if (!checkAuthToken()) {
    clearLocalStorage()
  }
  return checkAuthToken()
}
