import {
  Avatar,
  Box,
  CircularProgress,
  Container,
  Typography,
  useTheme,
} from '@mui/material'
import styled from 'styled-components'
import CustomButton from '../../../../components/ui/CustomButton'

export const ProfileMuiContainer = styled(Container).attrs(() => ({
  component: 'main',
  sx: { flex: 1 },
}))(() => ({}))

export const ProfileCircularProgress = styled(CircularProgress).attrs(() => ({
  color: 'inherit',
}))(() => ({}))

export const ProfileHeaderContainer = styled('div').attrs(() => ({}))(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '40px',
  paddingBottom: '40px',
}))

export const ProfileHeaderTitleTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'h3',
    lineHeight: '44px',
    color: theme.palette.grey[800],
  }
})(() => ({}))

export const ProfileHeaderRightContainer = styled('div').attrs(() => ({}))(
  () => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
)

export const ProfileHeaderCustomButtonCancel = styled(CustomButton).attrs(
  () => {
    const theme = useTheme()
    return {
      sx: {
        backgroundColor: 'white',
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.light,
        marginRight: '12px',
        py: '8px',
      },
    }
  },
)(() => ({}))

export const ProfileHeaderCustomButtonUpdate = styled(CustomButton).attrs(
  ({ disabled }) => {
    const theme = useTheme()
    return {
      sx: {
        backgroundColor: disabled
          ? theme.palette.grey[100]
          : theme.palette.primary.light,
        borderColor: disabled
          ? theme.palette.grey[300]
          : theme.palette.primary.light,
        color: disabled ? theme.palette.primary.light : 'white',
        py: '8px',
      },
    }
  },
)(() => ({}))

export const ProfileHeaderCustomButtonEdit = styled(CustomButton).attrs(() => {
  const theme = useTheme()
  return {
    sx: {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      color: 'white',
      border: 'none',
      py: '8px',
    },
  }
})(() => ({}))
export const ProfileUserAvatarContainer = styled(Box).attrs(() => {
  return {
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      mb: {
        xs: '24px',
        md: '40px',
      },
      mt: {
        xs: '16px',
        md: '0px',
      },
    },
  }
})(() => ({}))

export const ProfileHeaderAvatar = styled(Avatar).attrs(() => ({}))(() => ({}))

export const ProfileUserInfoContainer = styled('div')({
  textAlign: 'center',
  marginTop: '16px',
})

export const ProfileUserInfoNameTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'body2',
    color: theme.palette.grey[800],
  }
})(() => ({}))

export const ProfileUserInfoEmailTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    component: 'p',
    variant: 'subtitle1',
    color: theme.palette.grey[500],
  }
})(() => ({}))
