import {
  styled as muiStyled,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material'
import CustomButton from '../../../../components/ui/CustomButton'
import RoundedButton from '../../../../components/ui/RoundedButton'

export const GroupHeaderRightContainer = muiStyled('div')({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
})

export const GroupHeaderRoundedButton = muiStyled(RoundedButton)({
  boxShadow: '0px 0px 4px 0px #33333340',
  marginRight: '12px',
  marginLeft: '3px',
})

export const GroupHeaderTitle = muiStyled(Typography)<TypographyProps>({
  textTransform: 'capitalize',
})
export const GroupSubTitle = muiStyled(Typography)<TypographyProps>({
  textTransform: 'capitalize',
})

export const GroupHeaderCustomButton = muiStyled(CustomButton)(() => {
  const theme = useTheme()
  return {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.primary.light,
    color: theme.palette.grey.A100,
    paddingTop: '8px',
    paddingBottom: '8px',
    [theme.breakpoints.down('sm')]: {
      margin: '12px 0px 0px 0px',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '12px 0px 0px 0px',
    },
    [theme.breakpoints.up('md')]: {
      margin: '0px',
    },
    width: '100%',
    sx: {
      height: '100%',
      mt: '16px',
    },
  }
})
