import { zodResolver } from '@hookform/resolvers/zod'
import {
  Autocomplete,
  Box,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { MuiTelInputInfo } from 'mui-tel-input'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { countries } from '../../../../helpers/onboard-helper'
import { RootState, useAppDispatch, useAppSelector } from '../../../../store'

import {
  selectBioPayload,
  selectEater,
  selectIsEditCancelledData,
  selectIsProfileEdit,
  selectIsUpdateData,
  selectMessage,
  selectSeverity,
  selectShouldOpen,
  setBioPayload,
  setIsEditCancelledData,
  setIsProfileEdit,
  setIsUpdateData,
  setIsValidData,
  setOpen,
  updateEater,
} from '../../../../store/profileSlice'
import { selectIsMobileSearch } from '../../../../store/searchSlice'
import { Eater } from '../../../../types'
import { errorLog } from '../../../../utils/log-helper'
import {
  OnboardRequestGridContainer,
  OnboardRequestInnerGridContainer,
  OnboardRequestInputLabel,
  OnboardRequestMuiTelInput,
  OnboardRequestTextField,
} from '../../onboardPages/styled/OnboardRequest.styled'
import { MuiAlert, MuiSnackbar } from '../../styled/global.styled'
import {
  ProfileBioContainer,
  ProfileBioScrollBox,
} from '../styled/Preferences.styled'

const formSchema = z.object({
  firstName: z.string().min(1, { message: 'First name is required' }),
  lastName: z.string().min(1, { message: 'Last name is required' }),
  phone: z.string().min(1, { message: 'Phone number is required' }),
  addressLine1: z.string().min(1, { message: 'Address Line 1 is required' }),
  addressLine2: z.string().optional(),
  country: z.string().min(1, { message: 'Country is required' }),
  state: z.string().min(1, { message: 'State is required' }),
  city: z.string().min(1, { message: 'City is required' }),
  zipcode: z.string().min(1, { message: 'Zip Code is required' }),
  occupation: z.string().min(1, { message: 'Occupation is required' }),
  incomeLevel: z
    .number()
    .positive({ message: 'Annual income must be a positive number' }),
  gender: z.string().min(1, { message: 'Gender is required' }),
  dob: z.any(),
})

interface RenderOptionProps extends React.HTMLAttributes<HTMLLIElement> {
  key: string
}

const ProfileBio = React.forwardRef(function OnboardPersonalize(): JSX.Element {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const message = useAppSelector(selectMessage)
  const userDetail = useAppSelector(selectEater)
  const severity = useAppSelector(selectSeverity)
  const openServer = useAppSelector(selectShouldOpen)
  const bioPayload = useAppSelector(selectBioPayload)
  const isProfileUpdate = useAppSelector(selectIsUpdateData)
  const isMobileSearch = useAppSelector(selectIsMobileSearch)
  const isProfileCancel = useAppSelector(selectIsEditCancelledData)
  const isEdit = useAppSelector((state: RootState) =>
    selectIsProfileEdit(state),
  )
  const [bioData, setBioData] = useState<Eater>(bioPayload)

  useEffect(() => {
    onProfileBio()
  }, [userDetail])

  const onProfileBio = (): void => {
    const personalize = {
      country: userDetail?.country ?? '',
      dob: dayjs(userDetail?.dob),
      firstName: userDetail?.firstName,
      lastName: userDetail?.lastName,
      phone: userDetail?.phone,
      addressLine1: userDetail?.addressLine1,
      addressLine2: userDetail?.addressLine2,
      city: userDetail?.city,
      state: userDetail?.state,
      zipcode: userDetail?.zipcode,
      incomeLevel: Number(userDetail?.incomeLevel),
      gender: userDetail?.gender,
      occupation: userDetail?.occupation,
    }

    setBioData(personalize)
  }

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      dob: dayjs(bioData?.dob),
      country: bioData?.country ?? '',
      addressLine1: bioData?.addressLine1,
      addressLine2: bioData?.addressLine2,
      incomeLevel: Number(bioData?.incomeLevel),
      city: bioData?.city,
      state: bioData?.state,
      firstName: bioData?.firstName,
      gender: bioData?.gender,
      lastName: bioData?.lastName,
      phone: bioData?.phone,
      occupation: bioData?.occupation,
      zipcode: bioData?.zipcode,
    },
  })

  useEffect(() => {
    reset({
      addressLine1: bioData.addressLine1,
      addressLine2: bioData.addressLine2,
      city: bioData.city,
      country: bioData.country ?? '',
      dob: dayjs(bioData.dob),
      gender: bioData.gender,
      incomeLevel: Number(bioData.incomeLevel),
      lastName: bioData.lastName,
      occupation: bioData.occupation,
      phone: bioData.phone,
      state: bioData.state,
      firstName: bioData.firstName,
      zipcode: bioData.zipcode,
    })
  }, [bioData, reset])

  useEffect(() => {
    dispatch(setIsValidData(!isValid))
  }, [isValid])

  useEffect(() => {
    if (isProfileUpdate) {
      getPayload()
        .then((eaterDTO) => {
          dispatch(updateEater({ eater: eaterDTO }))
            .catch(() => {})
            .finally(() => {
              dispatch(setIsProfileEdit(false))
            })
        })
        .catch(() => {})
        .finally(() => {
          dispatch(setIsUpdateData(false))
        })
    }
    if (isProfileCancel) {
      onProfileBio()
      dispatch(setIsEditCancelledData(false))
    }
  }, [isProfileUpdate, isProfileCancel])

  const getPayload = async (): Promise<Eater | undefined> => {
    try {
      let formData: Eater | undefined

      await handleSubmit(
        async (data: z.infer<typeof formSchema>): Promise<void> => {
          dispatch(setBioPayload(data))
          formData = data
        },
      )()

      return formData
    } catch (error) {
      errorLog('Form submission error:', error)
    }
  }
  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  return (
    <>
      <ProfileBioContainer>
        <ProfileBioScrollBox isSearchOpen={isMobileSearch} isEdit={isEdit}>
          <OnboardRequestGridContainer>
            <OnboardRequestInnerGridContainer>
              <div>
                <OnboardRequestInputLabel htmlFor="firstName">
                  First Name
                </OnboardRequestInputLabel>
                <OnboardRequestTextField
                  disabled={!isEdit}
                  id="firstName"
                  placeholder="Enter first name"
                  {...register('firstName')}
                  error={!!errors.firstName}
                  helperText={errors.firstName ? errors.firstName.message : ' '}
                />
              </div>
            </OnboardRequestInnerGridContainer>
            <OnboardRequestInnerGridContainer>
              <div>
                <OnboardRequestInputLabel htmlFor="lastName">
                  Last Name
                </OnboardRequestInputLabel>
                <OnboardRequestTextField
                  disabled={!isEdit}
                  id="lastName"
                  placeholder="Enter last name"
                  {...register('lastName')}
                  error={!!errors.lastName}
                  helperText={errors.lastName ? errors.lastName.message : ' '}
                />
              </div>
            </OnboardRequestInnerGridContainer>
            <OnboardRequestInnerGridContainer>
              <div>
                <OnboardRequestInputLabel htmlFor="dob">
                  Date of Birth
                </OnboardRequestInputLabel>

                <Box
                  component="form"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: '100%',
                  }}
                >
                  <Controller
                    name="dob"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disabled={!isEdit}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              size: 'small',
                              sx: {
                                mb: '16px',
                                '& .MuiOutlinedInput-root': {
                                  backgroundColor: `${
                                    !isEdit
                                      ? theme.palette.grey[50]
                                      : theme.palette.background.paper
                                  } !important`,
                                  '& fieldset': {
                                    borderColor: theme.palette.secondary[300],
                                    borderRadius: '8px',
                                  },
                                  '&:hover fieldset': {
                                    borderColor: theme.palette.secondary[300],
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: theme.palette.secondary[300],
                                  },
                                },
                              },
                            },
                          }}
                          {...field}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Box>
              </div>
            </OnboardRequestInnerGridContainer>
            <OnboardRequestInnerGridContainer>
              <div>
                <OnboardRequestInputLabel htmlFor="phone">
                  Phone Number
                </OnboardRequestInputLabel>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <OnboardRequestMuiTelInput
                      disabled={!isEdit}
                      {...field}
                      id="phone"
                      placeholder="Enter phone number"
                      error={!!errors.phone}
                      helperText={errors.phone ? errors.phone.message : ' '}
                      onChange={(value, info: MuiTelInputInfo) =>
                        field.onChange(value)
                      }
                    />
                  )}
                />
              </div>
            </OnboardRequestInnerGridContainer>
            <OnboardRequestInnerGridContainer>
              <div>
                <OnboardRequestInputLabel htmlFor="addressLine1">
                  Address Line 1
                </OnboardRequestInputLabel>
                <OnboardRequestTextField
                  disabled={!isEdit}
                  id="addressLine1"
                  placeholder="Enter address line 1"
                  {...register('addressLine1')}
                  error={!!errors.addressLine1}
                  helperText={
                    errors.addressLine1 ? errors.addressLine1.message : ' '
                  }
                />
              </div>
            </OnboardRequestInnerGridContainer>
            <OnboardRequestInnerGridContainer>
              <div>
                <OnboardRequestInputLabel htmlFor="addressLine2">
                  Address Line 2
                </OnboardRequestInputLabel>
                <OnboardRequestTextField
                  disabled={!isEdit}
                  id="addressLine2"
                  placeholder="Enter address line 2"
                  {...register('addressLine2')}
                  error={!!errors.addressLine2}
                  helperText={
                    errors.addressLine2 ? errors.addressLine2.message : ' '
                  }
                />
              </div>
            </OnboardRequestInnerGridContainer>
            <OnboardRequestInnerGridContainer>
              <div>
                <OnboardRequestInputLabel htmlFor="country">
                  Country
                </OnboardRequestInputLabel>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => {
                    const selectedValues = React.useMemo(
                      () => countries.find((v) => v.label === field.value),
                      [field.value],
                    )
                    return (
                      <Autocomplete
                        disabled={!isEdit}
                        {...field}
                        id="country"
                        options={countries.map((country) => country)}
                        autoHighlight
                        PaperComponent={(props) => (
                          <Paper
                            {...props}
                            sx={{
                              fontSize: '14px !important',
                              fontWeight: `400 !important`,
                            }}
                          />
                        )}
                        size="small"
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: `${
                              !isEdit
                                ? theme.palette.grey[50]
                                : theme.palette.background.paper
                            } !important`,
                            '& fieldset': {
                              borderColor: theme.palette.secondary[300],
                              borderRadius: '8px',
                            },
                            '&:hover fieldset': {
                              borderColor: theme.palette.secondary[300],
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: theme.palette.secondary[300],
                            },
                          },
                          mb: '16px',
                        }}
                        getOptionLabel={(option: any) => option?.label}
                        value={selectedValues ?? countries[0]}
                        onChange={(e, newValue: any) =>
                          field.onChange(newValue?.label)
                        }
                        renderOption={(
                          props: RenderOptionProps,
                          option: any,
                        ) => {
                          const country = countries.find(
                            (c) => c.code === (option?.code ?? ''),
                          )
                          if (!country) return null
                          const { key, ...otherProps } = props
                          return (
                            <Box
                              component="li"
                              key={key}
                              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                              {...otherProps}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                alt={country.code.toLowerCase()}
                                src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                              />
                              <Typography
                                noWrap
                                component="p"
                                variant="subtitle1"
                                sx={{ ml: 1 }}
                                color={theme.palette.grey[800]}
                              >
                                {country.label} ({country.code}) +{' '}
                                {country.phone}
                              </Typography>
                            </Box>
                          )
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Choose a country"
                            error={!!errors.country}
                            helperText={
                              errors.country ? errors.country.message : ''
                            }
                          />
                        )}
                      />
                    )
                  }}
                />
              </div>
            </OnboardRequestInnerGridContainer>
            <OnboardRequestInnerGridContainer>
              <div>
                <OnboardRequestInputLabel htmlFor="state">
                  State
                </OnboardRequestInputLabel>
                <OnboardRequestTextField
                  disabled={!isEdit}
                  id="state"
                  placeholder="Enter state"
                  {...register('state')}
                  error={!!errors.state}
                  helperText={errors.state ? errors.state.message : ' '}
                />
              </div>
            </OnboardRequestInnerGridContainer>
            <OnboardRequestInnerGridContainer>
              <div>
                <OnboardRequestInputLabel htmlFor="city">
                  City
                </OnboardRequestInputLabel>
                <OnboardRequestTextField
                  disabled={!isEdit}
                  id="city"
                  placeholder="Enter city"
                  {...register('city')}
                  error={!!errors.city}
                  helperText={errors.city ? errors.city.message : ' '}
                />
              </div>
            </OnboardRequestInnerGridContainer>
            <OnboardRequestInnerGridContainer>
              <div>
                <OnboardRequestInputLabel htmlFor="zipcode">
                  Zip Code
                </OnboardRequestInputLabel>
                <OnboardRequestTextField
                  disabled={!isEdit}
                  id="zipcode"
                  placeholder="Enter zip code"
                  {...register('zipcode')}
                  error={!!errors.zipcode}
                  helperText={errors.zipcode ? errors.zipcode.message : ' '}
                />
              </div>
            </OnboardRequestInnerGridContainer>
            <OnboardRequestInnerGridContainer>
              <div>
                <OnboardRequestInputLabel htmlFor="occupation">
                  Occupation
                </OnboardRequestInputLabel>
                <OnboardRequestTextField
                  disabled={!isEdit}
                  id="occupation"
                  placeholder="Enter occupation"
                  {...register('occupation')}
                  error={!!errors.occupation}
                  helperText={
                    errors.occupation ? errors.occupation.message : ' '
                  }
                />
              </div>
            </OnboardRequestInnerGridContainer>
            <OnboardRequestInnerGridContainer>
              <div>
                <OnboardRequestInputLabel htmlFor="incomeLevel">
                  Annual Income
                </OnboardRequestInputLabel>
                <OnboardRequestTextField
                  disabled={!isEdit}
                  id="incomeLevel"
                  placeholder="Enter annual income"
                  type="number"
                  defaultValue={0}
                  {...register('incomeLevel', { valueAsNumber: true })}
                  error={!!errors.incomeLevel}
                  helperText={
                    errors.incomeLevel ? errors.incomeLevel.message : ' '
                  }
                />
              </div>
            </OnboardRequestInnerGridContainer>
            <OnboardRequestInnerGridContainer>
              <OnboardRequestInputLabel htmlFor="gender">
                Gender
              </OnboardRequestInputLabel>
              <Controller
                name="gender"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    disabled={!isEdit}
                    size="small"
                    fullWidth
                    variant="outlined"
                    id="gender"
                    {...field}
                    {...register('gender', { required: 'Gender is required' })}
                    error={!!errors.gender}
                    sx={{
                      mb: '16px',
                      '&.MuiOutlinedInput-root': {
                        backgroundColor: `${
                          !isEdit
                            ? theme.palette.grey[50]
                            : theme.palette.background.paper
                        } !important`,
                      },
                    }}
                  >
                    <MenuItem value={'Male'}>
                      <Typography
                        noWrap
                        component="p"
                        variant="subtitle1"
                        color={theme.palette.grey[800]}
                      >
                        Male
                      </Typography>
                    </MenuItem>
                    <MenuItem value={'Female'}>
                      <Typography
                        noWrap
                        component="p"
                        variant="subtitle1"
                        color={theme.palette.grey[800]}
                      >
                        Female
                      </Typography>
                    </MenuItem>
                    <MenuItem value={'Non-Binary'}>
                      <Typography
                        noWrap
                        component="p"
                        variant="subtitle1"
                        color={theme.palette.grey[800]}
                      >
                        Non-Binary
                      </Typography>
                    </MenuItem>
                    <MenuItem value={'NA'}>
                      <Typography
                        noWrap
                        component="p"
                        variant="subtitle1"
                        color={theme.palette.grey[800]}
                      >
                        Prefer Not To Say
                      </Typography>
                    </MenuItem>
                  </Select>
                )}
              />
            </OnboardRequestInnerGridContainer>
          </OnboardRequestGridContainer>
        </ProfileBioScrollBox>
      </ProfileBioContainer>
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </>
  )
})

export default ProfileBio
