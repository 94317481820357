import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'
interface EmptyStateProps {
  containerHeight: string
  mainText?: string
  secondaryText?: string
  imageUrl?: string
  imageWidth?: string
  imageHeight?: string
  children?: React.ReactNode
}
const EmptyScreen: React.FC<EmptyStateProps> = ({
  containerHeight,
  mainText = 'Oops! No Results Found',
  secondaryText = `We couldn't find any item matching your search`,
  imageUrl = '/images/no-result-found.svg',
  imageWidth = '180px',
  imageHeight = '150px',
  children,
}) => {
  const theme = useTheme()
  return (
    <Box
      width={'100%'}
      minHeight={'100%'}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={containerHeight}
    >
      <Box sx={{ width: '100%' }} textAlign="center">
        <img
          width={imageWidth}
          height={imageHeight}
          src={imageUrl || '/images/no-result-found.svg'}
          srcSet={`${imageUrl || '/images/no-result-found.svg'} 2x`}
          alt="No result found"
          loading="lazy"
          style={{
            margin: '0px auto',
          }}
        />
        <Typography
          component="p"
          variant="h4"
          fontWeight={400}
          sx={{ marginTop: '40px' }}
        >
          {mainText}
        </Typography>
        {secondaryText.length !== 0 && (
          <Typography
            component="p"
            variant="subtitle1"
            color={theme.palette.grey[400]}
            sx={{
              paddingTop: '',
              maxWidth: '565px',
              textAlign: 'center',
              margin: '0px auto',
            }}
          >
            {secondaryText}
          </Typography>
        )}
        {children && <Box sx={{ marginTop: '16px' }}>{children}</Box>}
      </Box>
    </Box>
  )
}
export default EmptyScreen
