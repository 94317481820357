import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import {
  HeroSectionAsOnContainer,
  HeroSectionContainerSideImg,
  HeroSectionGridContainer,
  HeroSectionGridContainerTypographyAsOn,
  HeroSectionGridContainerTypographyChip,
  HeroSectionGridContainerTypographyInfo,
  HeroSectionGridContainerTypographyTitle,
  HeroSectionGridContainerTypographyTitleSecond,
  HeroSectionImg,
  HeroSectionImgAsOnContainer,
  HeroSectionImgOne,
  HeroSectionImgTwo,
} from '../styled/HeroSection.styled'
import HeroImages from './HeroImages'

const HeroSection: React.FC = () => {
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const imgList = [
    '/images/homepage/fox-logo.png',
    '/images/homepage/cnbc-logo.png',
    '/images/homepage/abs-logo.png',
    '/images/homepage/cbs-logo.png',
  ]

  const imgListInSmallScreen = [
    '/images/homepage/fox-logo-small.png',
    '/images/homepage/cnbc-logo-small.png',
    '/images/homepage/abs-logo-small.png',
    '/images/homepage/cbs-logo-small.png',
  ]

  return (
    <Box sx={{ margin: { xs: '0 0 20px 0', sm: '0 0 0 0' } }}>
      <HeroSectionImgOne />
      <HeroSectionImgTwo />
      <Grid container spacing={2}>
        <Grid item xs={12} md={7} lg={6}>
          <HeroSectionGridContainer>
            <HeroSectionGridContainerTypographyChip
              noWrap
              component="span"
              variant="subtitle2"
              textTransform={'uppercase'}
              color={theme.palette.secondary.dark}
            >
              A.I powered Recommendations
            </HeroSectionGridContainerTypographyChip>
            <HeroSectionGridContainerTypographyTitle
              noWrap
              component="p"
              variant="h1"
              color={theme.palette.primary.main}
            >
              We help you find the
            </HeroSectionGridContainerTypographyTitle>
            <HeroSectionGridContainerTypographyTitleSecond
              noWrap
              component="span"
              variant="h1"
              color={theme.palette.secondary.main}
            >
              best restaurants
            </HeroSectionGridContainerTypographyTitleSecond>
            <HeroSectionGridContainerTypographyInfo
              component="p"
              variant="body1"
              color={theme.palette.grey[700]}
            >
              Be one of the first to experience the future of dining with our
              Patent Pending AI Recommendation engine that knows your tastes
              better than you.
            </HeroSectionGridContainerTypographyInfo>

            {!isMediumScreen && (
              <div>
                <HeroSectionGridContainerTypographyAsOn
                  component="p"
                  variant="subtitle1"
                  color={theme.palette.grey[500]}
                >
                  As Seen On
                </HeroSectionGridContainerTypographyAsOn>
                <HeroSectionImgAsOnContainer>
                  {imgList.map((img) => (
                    <HeroSectionImg
                      alt="as-seen-on"
                      srcSet={img}
                      src={img}
                      loading="lazy"
                      key={img}
                    />
                  ))}
                </HeroSectionImgAsOnContainer>
              </div>
            )}
          </HeroSectionGridContainer>
        </Grid>
        <Grid item xs={12} md={5} lg={6}>
          <HeroSectionContainerSideImg>
            <HeroImages />
          </HeroSectionContainerSideImg>
        </Grid>
        {isMediumScreen && (
          <HeroSectionAsOnContainer>
            <HeroSectionGridContainerTypographyAsOn
              component="p"
              variant="subtitle1"
              color={theme.palette.grey[500]}
            >
              As Seen On
            </HeroSectionGridContainerTypographyAsOn>
            <HeroSectionImgAsOnContainer>
              {imgListInSmallScreen.map((img) => (
                <HeroSectionImg
                  alt="as-seen-on"
                  srcSet={img}
                  src={img}
                  loading="lazy"
                  key={img}
                />
              ))}
            </HeroSectionImgAsOnContainer>
          </HeroSectionAsOnContainer>
        )}
      </Grid>
    </Box>
  )
}

export default HeroSection
