import { Grid } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import EmptyState from '../../../../components/EmptyScreen'
import RestaurantDishesCard from '../../../../components/restaurant/RestaurantDishesCard'
import { ROUTES } from '../../../../helpers/routes-helper'
import { Dish } from '../../../../types'

interface SearchDishProps {
  dishList: Dish[]
}

const SearchDish: React.FC<SearchDishProps> = ({ dishList }) => {
  const navigate = useNavigate()
  return (
    <Grid
      container
      spacing={4}
      style={{
        marginTop: '20px',
        marginBottom: '20px',
        WebkitOverflowScrolling: 'touch',
      }}
    >
      {dishList.length > 0 &&
        dishList.map((dish, index) => {
          return (
            <Grid item xs={12} sm={12} md={6} key={index}>
              <div
                onClick={() =>
                  navigate(
                    `${ROUTES.RESTAURANT_DETAILS}/${dish?.restaurant}/details`,
                  )
                }
              >
                <RestaurantDishesCard
                  id={dish.dishID}
                  key={index}
                  image={'/images/restaurant-logo.jpg'}
                  title={dish?.name}
                  description={dish?.description}
                />
              </div>
            </Grid>
          )
        })}
      {dishList.length === 0 && <EmptyState containerHeight={'600px'} />}
    </Grid>
  )
}

export default SearchDish
