import {
  Box,
  Button,
  Grid,
  GridProps,
  Typography,
  useTheme,
} from '@mui/material'
import styled from 'styled-components'
import './OnboardSearch.styled.css'

export const OnboardMainContainer = styled(Grid).attrs(() => ({
  container: true,
  className: 'container-font-family',
}))(({ theme }) => ({}))

export const OnboardSidebarContainer = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  lg: 3,
  md: 4,
  padding: 1,
  position: 'relative',
}))(({ theme }) => ({}))

export const OnboardSidebarWarper = styled(Box).attrs(() => {
  const theme = useTheme()
  return {
    position: 'sticky',
    top: '8px',
    width: '100%',
    height: 'calc(100vh - 16px)',
    padding: '32px 0px 32px 32px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    sx: { background: theme.palette.primary.main },
  }
})(({ theme }) => ({}))

export const OnboardSidebarLogoImg = styled('img').attrs(() => ({
  src: '/logo.svg',
  alt: 'resactly-logo',
  width: 64,
  height: 64,
}))(({ theme }) => ({
  borderRadius: '50%',
}))

export const OnboardSidebarTitleTypography = styled(Typography).attrs(() => ({
  component: 'h2',
  color: 'white',
  variant: 'h2',
  my: 5,
}))(({ theme }) => ({}))

export const OnboardSidebarLogoutButton = styled(Button).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'contained',
    fullWidth: true,
    disableElevation: true,
    sx: {
      backgroundColor: theme.palette.primary.light,
      textTransform: 'none',
      lineHeight: 'normal',
      borderRadius: '10px',
      mt: '10px',
      mr: '32px',
    },
    disableRipple: true,
  }
})(({ theme }) => ({}))

export const OnboardSidebarButtonTitle = styled(Typography).attrs(() => ({
  component: 'p',
  letterSpacing: 0.5,
  color: 'white',
  variant: 'subtitle1',
  pr: '6px',
}))(({ theme }) => ({}))

export const OnboardSidebarButtonImg = styled('img').attrs(() => ({
  src: '/images/icons/logout.svg',
  alt: 'resactly-logout-logo',
  width: 20,
  height: 20,
}))(({ theme }) => ({}))

export const OnboardContentContainer = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  md: 8,
  lg: 9,
}))(({ theme }) => ({}))

export const OnboardHeaderWarper = styled(Box).attrs(() => ({
  display: 'flex',
  alignItems: 'center',
  py: 3,
  px: 4,
}))(() => {
  const theme = useTheme()
  return {
    [theme.breakpoints.down('sm')]: {
      bottom: 0,
      width: '100%',
      padding: '16px !important',
      background: theme.palette.background.paper,
      zIndex: 10,
    },
  }
})

export const OnboardHeaderBackButton = styled(Button).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'outlined',
    size: 'large',
    disableElevation: true,
    sx: {
      color: theme.palette.primary.light,
      border: `1px solid ${theme.palette.primary.light}`,
      paddingLeft: '10px',
      paddingRight: '10px',
      textTransform: 'none',
      lineHeight: 'normal',
      borderRadius: '10px',
    },
    disableRipple: true,
  }
})(() => {
  const theme = useTheme()
  return {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
      fontWeight: '600 !important',
    },
  }
})

export const OnboardHeaderButtonWarper = styled(Box).attrs(() => ({
  gap: 2,
  display: 'flex',
  alignItems: 'center',
}))(({ theme }) => ({}))

export const OnboardHeaderSkipButton = styled(Button).attrs(() => {
  const theme = useTheme()
  return {
    size: 'large',
    sx: {
      color: theme.palette.primary.light,
      textTransform: 'none',
      lineHeight: 'normal',
      borderRadius: '10px',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  }
})(() => {
  const theme = useTheme()
  return {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
      fontWeight: '600 !important',
    },
  }
})

export const OnboardHeaderNextButton = styled(Button).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'contained',
    size: 'large',
    disableElevation: true,
    sx: {
      backgroundColor: theme.palette.primary.light,
      textTransform: 'none',
      lineHeight: 'normal',
      borderRadius: '10px',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    disableRipple: true,
  }
})(() => {
  const theme = useTheme()
  return {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px !important',
      fontWeight: '600 !important',
    },
  }
})

export const OnboardContentWarper = styled(Box).attrs(() => ({
  mt: 6,
  maxWidth: 800,
}))(() => {
  const theme = useTheme()
  return {
    [theme.breakpoints.down('sm')]: {
      marginTop: '32px !important',
      padding: '0px 16px 0px 16px',
    },
  }
})

export const SideTitleAndStepper = styled(Box).attrs(() => ({
  sx: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    padding: '0px 32px 0px 0px',
  },
}))<GridProps>``

export const OnboardContent = styled(Box).attrs(() => ({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  sx: { overflowY: 'auto', height: 'calc(100vh - 84px)' },
}))<GridProps>`
  @media (max-width: 600px) {
    height: calc(100vh - 125px) !important;
  }
`
