import { CircularProgress } from '@mui/material'
import React from 'react'
import { MuiBackdrop } from '../../pages/eater/styled/global.styled'

interface Props {
  show: Boolean
}

const CircularLoader: React.FC<Props> = ({ show }) => {
  return (
    <>
      {show && (
        <MuiBackdrop open={true}>
          <CircularProgress color="inherit" />
        </MuiBackdrop>
      )}
    </>
  )
}

export default CircularLoader
