import { useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { IconMinus, IconPlus } from '@tabler/icons-react'
import React, { useState } from 'react'
import {
  FAQAccordion,
  FAQAccordionDetails,
  FAQAccordionSummary,
  FAQContentContainer,
  FAQDescriptionTypography,
  FAQMainContainer,
  StyledIconContainer,
} from '../styled/FAQs.styled'

const FAQs: React.FC = (): JSX.Element => {
  const theme = useTheme()
  const [expanded, setExpanded] = useState<string | null>(null)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : null)
    }

  return (
    <FAQMainContainer>
      <div>
        <Typography component="p" variant="h1" color={theme.palette.grey[800]}>
          FAQs
        </Typography>
        <FAQDescriptionTypography
          variant="body1"
          color={theme.palette.grey[800]}
        >
          Get to Know Us Better: In-Depth Answers to Frequently Asked Questions
        </FAQDescriptionTypography>
      </div>
      <FAQContentContainer>
        {faqData.map((faq) => {
          return (
            <FAQAccordion
              key={faq.id}
              expanded={expanded === faq.id}
              onChange={handleChange(faq.id)}
            >
              <FAQAccordionSummary
                sx={{
                  '&.MuiAccordionSummary-root': {
                    '& .MuiAccordionSummary-content': {
                      WebkitTransition: 'none',
                      transition: 'none',

                      [theme.breakpoints.down('sm')]: {
                        marginTop: '10px',
                        marginBottom: expanded === faq.id ? '0px' : '10px',
                      },
                      [theme.breakpoints.up('sm')]: {
                        marginTop: '20px',
                        marginBottom: expanded === faq.id ? '0px' : '15px',
                      },
                      [theme.breakpoints.up('md')]: {
                        marginTop: '20px',
                        marginBottom: expanded === faq.id ? '0px' : '20px',
                      },
                    },
                  },
                }}
                expandIcon={
                  expanded === faq.id ? (
                    <StyledIconContainer isExpand={true}>
                      <IconMinus stroke={2} />
                    </StyledIconContainer>
                  ) : (
                    <StyledIconContainer isExpand={false}>
                      <IconPlus stroke={2} />
                    </StyledIconContainer>
                  )
                }
                aria-controls={`${faq.id}-content`}
                id={`${faq.id}-header`}
              >
                <Typography
                  component="p"
                  variant="body1"
                  fontWeight={500}
                  textAlign="start"
                  maxWidth={{
                    sx: '90%',
                    sm: '100%',
                  }}
                  color={theme.palette.grey[800]}
                >
                  {faq.question}
                </Typography>
              </FAQAccordionSummary>
              <FAQAccordionDetails>
                <Typography
                  component="p"
                  variant="subtitle1"
                  fontWeight={500}
                  color={theme.palette.grey[800]}
                  textAlign={'start'}
                  maxWidth={'95%'}
                >
                  {faq.answer}
                </Typography>
              </FAQAccordionDetails>
            </FAQAccordion>
          )
        })}
      </FAQContentContainer>
    </FAQMainContainer>
  )
}

interface FAQ {
  id: string
  question: string
  answer: string
}

const faqData: FAQ[] = [
  {
    id: 'panel1',
    question: 'How does Resactly work?',
    answer:
      'Resactly uses a sophisticated AI/ML algorithm that takes into account your taste profile, food likes/dislikes, preferred cuisines, dietary preferences, and more. Based on this data, we recommend restaurants that align perfectly with your tastes.',
  },
  {
    id: 'panel2',
    question: 'How do I setup my taste profile?',
    answer:
      'Setting up your taste profile is easy! Simply log in to your Resactly account and answer a few questions about your food preferences, dietary needs, and favorite cuisines. The more details you provide, the better our recommendations will be.',
  },
  {
    id: 'panel3',
    question: 'Is my dietary information safe with Resactly?',
    answer:
      'Absolutely. We prioritize your privacy and ensure that all your personal and dietary information is stored securely. We never share your data with third parties without your explicit consent.',
  },
  {
    id: 'panel4',
    question: 'Can I update my taste profile?',
    answer:
      'Yes, you can update your taste profile anytime. We understand that tastes evolve, and we encourage you to keep your profile updated for the most accurate restaurant recommendations.',
  },
  {
    id: 'panel5',
    question: 'How often does Resactly update its restaurant database?',
    answer:
      'Our database is constantly updated to include new restaurants and menu changes. This ensures that you always get the most current and relevant recommendations.',
  },
  {
    id: 'panel6',
    question: 'Does Resactly charge for its services?',
    answer: `As of now, Resactly is completely free for all users. Our mission has always been to help food enthusiasts find their perfect dining experience, and we're thrilled to offer this service at no cost. However, as we continue to grow and refine our platform, we will introduce a premium tier with additional features and benefits. Rest assured, even with the introduction of the premium tier, there will always be a free version available, especially once we incorporate advertising to support the platform. We're committed to ensuring that everyone, regardless of their subscription, can discover eateries that resonate with their tastes.`,
  },
  {
    id: 'panel7',
    question: 'What if I disagree with a recommendation?',
    answer: `While our AI is advanced, it's not infallible. If you ever disagree with a recommendation, you can provide feedback through our platform. This helps us refine our algorithm and serve you better in the future.`,
  },
  {
    id: 'panel8',
    question: 'Can I recommend a restaurant to be added to Resactly?',
    answer: `Of course! We value community input. If there's a restaurant you love that's not on our list, let us know, and we'll look into adding it.`,
  },
]

export default FAQs
