import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Fab } from '@mui/material'
import { KeyboardArrowUp } from '@mui/icons-material'

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation()
  const [showButton, setShowButton] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const handleScroll = (): void => {
    if (window.scrollY > 200) {
      setShowButton(true)
    } else {
      setShowButton(false)
    }
  }

  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      {showButton && (
        <Fab
          color="primary"
          aria-label="scroll back to top"
          onClick={scrollToTop}
          size="small"
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
          }}
        >
          <KeyboardArrowUp />
        </Fab>
      )}
    </>
  )
}

export default ScrollToTop
