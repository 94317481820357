import { Container, Grid, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import ImgIcon from '../../../../components/ui/ImgIcon'
import {
  DownloadAppBoxContainer,
  DownloadAppContainer,
  DownloadAppImgContainer,
  DownloadAppInnerContainer,
  DownloadAppSideImg,
  DownloadAppTypographySubTitle,
  DownloadAppTypographyTitle,
} from '../styled/DownloadApp.styled'

const DownloadApp: React.FC = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <DownloadAppContainer>
      <DownloadAppBoxContainer>
        <Container component="main" sx={{ flex: 1 }}>
          <DownloadAppInnerContainer>
            <div>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <DownloadAppTypographyTitle variant="h2">
                    Download our app for the <br />
                    best dining experience
                  </DownloadAppTypographyTitle>
                  <DownloadAppTypographySubTitle variant="subtitle1">
                    Browse menus, get recommendations and directions all in one
                    convenient place.
                    <br />
                    Download our app today for an elevated <br /> dining
                    adventure!
                  </DownloadAppTypographySubTitle>
                  <DownloadAppImgContainer>
                    <ImgIcon
                      icon="/new/images/icons/downloadGoogelPlay.svg"
                      alt="download-app-from-google-store"
                      height={isSmallScreen ? '24px' : '40px'}
                      width={isSmallScreen ? '82px' : '139px'}
                      wrapperStyle={{
                        height: isSmallScreen ? '24px' : '40px',
                        width: isSmallScreen ? '82px' : '139px',
                      }}
                    />
                    <ImgIcon
                      icon="/new/images/icons/downloadAppStore.svg"
                      alt="download-app-from-apple-store"
                      height={isSmallScreen ? '24px' : '40px'}
                      width={isSmallScreen ? '82px' : '139px'}
                      wrapperStyle={{
                        height: isSmallScreen ? '24px' : '40px',
                        width: isSmallScreen ? '82px' : '139px',
                      }}
                    />
                  </DownloadAppImgContainer>
                </Grid>
                <Grid item xs={6} md={6}>
                  <DownloadAppSideImg
                    src="/new/images/downloadApp.png"
                    alt="downloadApp"
                    isMediumScreen={isMediumScreen}
                    isSmallScreen={isSmallScreen}
                  />
                </Grid>
              </Grid>
            </div>
          </DownloadAppInnerContainer>
        </Container>
      </DownloadAppBoxContainer>
    </DownloadAppContainer>
  )
}

export default DownloadApp
