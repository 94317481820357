import { Box, Grid, InputLabel, TextField, useTheme } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import styled from 'styled-components'

export const OnboardRequestContainer = styled(Box).attrs(() => ({
  sx: { width: '100%', paddingTop: '40px' },
}))(({ theme }) => ({}))
export const OnboardPersonalizeDateOfBirth = styled(Box).attrs(() => ({
  sx: {
    pb: {
      xs: '24px',
      md: '16px',
    },
  },
}))(({ theme }) => ({}))

export const OnboardRequestGridContainer = styled(Grid).attrs(() => ({
  container: true,
  rowSpacing: 2,
  columnSpacing: { xs: 0, md: 5 },
}))(({ theme }) => ({}))

export const OnboardRequestInnerGridContainer = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
  md: 6,
}))(() => {
  const theme = useTheme()
  return {
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      color: theme.palette.grey[800],
      backgroundColor: theme.palette.grey[50],
      fontWeight: 500,
      '& fieldset': {
        borderColor: theme.palette.secondary[300],
        borderRadius: '8px',
      },
    },
  }
})

export const OnboardRequestInputLabel = styled(InputLabel).attrs(() => ({
  shrink: true,
  sx: {
    fontWeight: 500,
    fontSize: '14px',
  },
}))(() => {
  const theme = useTheme()
  return {
    color: theme.palette.secondary[800],
  }
})

export const OnboardRequestTextField = styled(TextField).attrs(() => ({
  variant: 'outlined',
  size: 'small',
  fullWidth: true,
}))(({ disabled }) => {
  const theme = useTheme()
  return {
    '& .MuiOutlinedInput-root': {
      backgroundColor: disabled
        ? theme.palette.grey[50]
        : theme.palette.background.paper,
      fontSize: '14px',
      color: theme.palette.grey[800],
      fontWeight: 500,
      '& fieldset': {
        borderColor: theme.palette.secondary[300],
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary[300],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary[300],
      },
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
    },
  }
})

export const OnboardRequestMuiTelInput = styled(MuiTelInput).attrs(() => ({
  size: 'small',
  fullWidth: true,
}))(({ disabled }) => {
  const theme = useTheme()
  return {
    '& .MuiOutlinedInput-root': {
      backgroundColor: disabled
        ? theme.palette.grey[50]
        : theme.palette.background.paper,
      fontSize: '14px',
      color: theme.palette.grey[800],
      fontWeight: 500,
      '& fieldset': {
        borderColor: theme.palette.secondary[300],
        borderRadius: '8px',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.secondary[300],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.secondary[300],
      },
    },
  }
})
