import { Tabs, Tab, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ITabList } from '../types'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  align?: string
}

interface ITabProps {
  align?: 'center' | 'end' | 'start'
  list: ITabList[]
  tabComponent: React.ReactElement[]
  changeTab?: number
}

const CustomTabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const a11yProps = (index: number): { id: string; 'aria-controls': string } => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const ResactlyTab: React.FC<ITabProps> = ({
  align = 'center',
  list,
  tabComponent,
  changeTab,
}) => {
  const [value, setValue] = useState(0)

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ): void => {
    setValue(newValue)
  }

  useEffect(() => {
    if (changeTab) {
      setValue(changeTab)
    }
  }, [changeTab])

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: align ?? 'center',
          alignItems: 'center',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {list.map((tab, index) => (
            <Tab
              key={index}
              icon={tab.icon}
              iconPosition="start"
              label={tab.label}
              {...a11yProps(index)}
              sx={{
                paddingY: '0px',
                minHeight: '56px',
                textTransform: 'capitalize',
              }}
            />
          ))}
        </Tabs>
      </Box>
      {tabComponent.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab}
        </CustomTabPanel>
      ))}
    </Box>
  )
}

export default ResactlyTab
