import { IconButton, Skeleton, styled } from '@mui/material'
import { IconPencil } from '@tabler/icons-react'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { generateEater } from '../../../helpers/converters'
import { ROUTES } from '../../../helpers/routes-helper'
import { RootState, useAppDispatch, useAppSelector } from '../../../store'
import { selectUserInfo } from '../../../store/authSlice'
import {
  fetchEater,
  selectCurrentTab,
  selectEater,
  selectIsLoading,
  selectIsProfileEdit,
  selectIsValidData,
  selectMessage,
  selectSeverity,
  selectShouldOpen,
  setIsEditCancelledData,
  setIsProfileEdit,
  setIsUpdateData,
  setIsValidData,
  setOpen,
  updateEater,
  updateEaterImage,
} from '../../../store/profileSlice'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import { MuiAlert, MuiBackdrop, MuiSnackbar } from '../styled/global.styled'
import ProfileHeader from './components/ProfileHeader'
import ProfileTab from './components/ProfileTab'
import {
  ProfileCircularProgress,
  ProfileHeaderAvatar,
  ProfileMuiContainer,
  ProfileUserAvatarContainer,
  ProfileUserInfoContainer,
  ProfileUserInfoEmailTypography,
  ProfileUserInfoNameTypography,
} from './styled/ProfilePage.styled'

const AvatarContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
})

const EditIconButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: -17,
  backgroundColor: 'white',
  padding: 9,
  boxShadow: '0px 0px 4px 0px #33333340',
  '&:hover': {
    backgroundColor: 'white',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 18,
  },
})

const Profile: React.FC = () => {
  const dispatch = useAppDispatch()
  const analytics = useRudderStackAnalytics()
  const eater = useAppSelector(selectEater)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const userInfo = useAppSelector(selectUserInfo)
  const isLoading = useAppSelector(selectIsLoading)
  const openServer = useAppSelector(selectShouldOpen)
  const isValid = useAppSelector((state: RootState) => selectIsValidData(state))
  const currentTab = useAppSelector((state: RootState) =>
    selectCurrentTab(state),
  )
  const isProfileEdit = useAppSelector((state: RootState) =>
    selectIsProfileEdit(state),
  )
  const [pageTitle, setPageTitle] = useState('')
  const [isEdit, setIsEdit] = useState(isProfileEdit)
  const [staticLoading, setStaticLoading] = useState(false)
  const [loadingProfileImage, setLoadingProfileImage] = useState(true)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onEditProfile = (value: boolean): void => {
    setIsEdit(value)
    dispatch(setIsProfileEdit(value))
  }

  const onUpdate = (): void => {
    dispatch(setIsUpdateData(true))
  }
  const onCancel = (): void => {
    setIsEdit(false)
    dispatch(setIsProfileEdit(false))
    dispatch(setIsEditCancelledData(true))
  }

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(eater ?? {})
    const title = `Resactly | ${fullName} Profile`
    setPageTitle(title)

    analytics?.page({
      path: ROUTES.PROFILE,
      title,
      url: `${window.location.origin}${ROUTES.PROFILE}`,
      category: 'About',
      name: 'UserProfile Viewed',
      eaterName: fullName,
      email,
      eaterId,
    })
  }, [eater, analytics])

  useEffect(() => {
    setIsEdit(isProfileEdit)
  }, [isProfileEdit])

  useEffect(() => {
    void fetchData()
  }, [dispatch])

  const fetchData = async (): Promise<void> => {
    setStaticLoading(true)
    await dispatch(fetchEater())
    dispatch(setIsValidData(false))
    setStaticLoading(false)
  }

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (event?.target?.files) {
      const file = event?.target?.files?.[0]

      const formData = new FormData()
      formData.append('file', file)
      const result = await dispatch(updateEaterImage(formData))
      if (updateEaterImage.fulfilled.match(result) && eater) {
        const {
          firstName,
          lastName,
          addressLine1,
          addressLine2,
          phone,
          city,
          country,
          state,
          zipcode,
          dob,
          occupation,
          gender,
        } = eater
        await dispatch(
          updateEater({
            eater: {
              firstName,
              lastName,
              addressLine1,
              addressLine2,
              phone,
              city,
              country,
              state,
              zipcode,
              dob,
              occupation,
              gender,
              imageUrl: result.payload.imageUrl,
            },
          }),
        )
      }
    }
  }

  const handleClick = (): void => {
    fileInputRef.current?.click()
  }

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  useEffect(() => {
    if (eater && eater.imageUrl && eater.imageUrl.length > 0) {
      setLoadingProfileImage(true)
    }
  }, [eater?.imageUrl])

  return (
    <ProfileMuiContainer>
      <Helmet>
        <title>{pageTitle}</title>
        <meta
          name="description"
          content="Manage your Resactly profile, view your dining preferences, saved restaurants, and personalized recommendations. Enhance your restaurant discovery journey with tailored insights."
        />
      </Helmet>
      <MuiBackdrop open={isLoading}>
        <ProfileCircularProgress />
      </MuiBackdrop>
      {!staticLoading && (
        <div>
          <ProfileHeader
            currentTab={currentTab}
            isEdit={isEdit}
            isValid={isValid}
            onCancel={onCancel}
            onEditProfile={onEditProfile}
            onUpdate={onUpdate}
          />
          <ProfileUserAvatarContainer>
            <AvatarContainer>
              {loadingProfileImage && (
                <Skeleton variant="circular" width={96} height={96} />
              )}
              <ProfileHeaderAvatar
                src={
                  eater?.imageUrl ??
                  userInfo?.picture ??
                  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png'
                }
                onLoad={() => setLoadingProfileImage(false)}
                sx={{
                  width: 96,
                  height: 96,
                  display: !loadingProfileImage ? 'block' : 'none',
                }}
                alt="Profile Image"
              />
              <EditIconButton onClick={handleClick}>
                <IconPencil size={18} stroke={2} />
              </EditIconButton>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={(event) => {
                  void handleFileChange(event)
                }}
              />
            </AvatarContainer>
            <ProfileUserInfoContainer>
              <ProfileUserInfoNameTypography>
                {`${eater?.firstName as string} ${eater?.lastName as string}`}
              </ProfileUserInfoNameTypography>
              <ProfileUserInfoEmailTypography>
                {eater?.email}
              </ProfileUserInfoEmailTypography>
            </ProfileUserInfoContainer>
          </ProfileUserAvatarContainer>
          <ProfileTab />
        </div>
      )}
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </ProfileMuiContainer>
  )
}

export default Profile
