import { AlertColor } from '@mui/material/Alert'
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit'
import okaoAxios from '../helpers/axios'
import {
  AddDiningReviewRequest,
  AddEaterRequest,
  Eater,
  StoryRequest,
} from '../types'
import { RootState, store } from './'

export interface IEaterSlice {
  status: string
  eaters: Eater[] | undefined
  count: number
  pageSize: number
  shouldOpen: boolean
  message: string | undefined
  severity: AlertColor | undefined
}

const initialState: IEaterSlice = {
  status: 'idle',
  eaters: undefined,
  pageSize: 100,
  count: 0,
  shouldOpen: false,
  message: undefined,
  severity: undefined,
}

export const updateEater = createAsyncThunk(
  'api/updateEater',
  async (story: StoryRequest) => {
    const response = await okaoAxios.put(`/eater/update-eater`, story)
    return response
  },
)

export const updateEaterImage = createAsyncThunk(
  'api/updateEaterImage',
  async (formData: FormData) => {
    const response = await okaoAxios.post(`/api/upload/eater`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    const data = {
      ...response.data,
      headers: { ...response.headers }, // Convert headers to a plain object
    }
    return data
  },
)

export const fetchEaters = createAsyncThunk(
  'api/fetchEaters',
  async (page: number) => {
    const response = await okaoAxios.get(
      `/eater/${page}?size=${store.getState().eater.pageSize}`,
    )
    return response
  },
)

export const saveEater = createAsyncThunk(
  'api/saveEater',
  async (nmr: AddEaterRequest) => {
    const response = await await okaoAxios.post(`/eater`, nmr)
    return response
  },
)

export const addDiningReview = createAsyncThunk(
  'api/addDiningReview',
  async (nmr: AddDiningReviewRequest) => {
    const response = await await okaoAxios.post(
      `/eater/save-dining-review`,
      nmr,
    )
    return response
  },
)

export const eaterSlice = createSlice({
  name: 'eater',
  initialState,
  reducers: {
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEaters.fulfilled, (state, action) => {
        state.eaters = action.payload.data.result
        state.count = action.payload.data.count
        state.status = 'idle'
      })

      .addMatcher(
        isAnyOf(addDiningReview.fulfilled, saveEater.fulfilled),
        (state, action) => {
          const eater: Eater = action.payload.data as Eater
          state.eaters = state.eaters?.filter(
            (sr) => sr.eaterId !== eater.eaterId,
          )
          state.eaters?.push(eater)
          state.count = state.eaters?.length ?? 0
          state.shouldOpen = true
          state.message = 'Successfully Completed Eater function'
          state.status = 'idle'
          state.severity = 'success'
        },
      )
      .addMatcher(
        isAnyOf(
          addDiningReview.rejected,
          fetchEaters.rejected,
          saveEater.rejected,
          updateEaterImage.rejected,
        ),
        (state, action) => {
          state.shouldOpen = true
          state.message =
            'Sorry an error occurred while updating your user. Please try again'
          state.status = 'error'
          state.severity = 'error'
        },
      )
      .addMatcher(
        isAnyOf(
          addDiningReview.pending,
          fetchEaters.pending,
          saveEater.pending,
        ),
        (state, action) => {
          state.status = 'loading'
        },
      )
  },
})

export const selectEaters = (state: RootState): Eater[] =>
  state.eater.eaters ?? []
export const selectEaterByEaterId =
  (eaterId: string) =>
  (state: RootState): Eater | undefined =>
    state.eater.eaters?.find((eater) => eater.eaterId === eaterId) ?? undefined
export const selectRowCount = (state: RootState): number => state.eater.count
export const selectPageSize = (state: RootState): number => state.eater.pageSize
export const selectIsLoading = (state: RootState): boolean =>
  state.eater.status === 'loading'
export const selectShouldOpen = (state: RootState): boolean =>
  state.eater.shouldOpen
export const selectMessage = (state: RootState): string | undefined =>
  state.eater.message
export const selectSeverity = (state: RootState): AlertColor | undefined =>
  state.eater.severity

export const { setPageSize, setOpen } = eaterSlice.actions

export default eaterSlice.reducer
