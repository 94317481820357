import { IconPlus } from '@tabler/icons-react'
import React from 'react'
import {
  SocialHeaderCustomButton,
  SocialHeaderMainContainer,
  SocialHeaderTypography,
} from '../styled/SocialHeader.styled'

interface ISocialHeaderProp {
  onAddGroup: () => void
}

const SocialHeader: React.FC<ISocialHeaderProp> = ({ onAddGroup }) => {
  return (
    <SocialHeaderMainContainer>
      <SocialHeaderTypography>Social</SocialHeaderTypography>
      <SocialHeaderCustomButton
        title="New Group"
        textVariant="subtitle1"
        onClick={onAddGroup}
        startIcon={<IconPlus size={18} stroke={2} />}
      />
    </SocialHeaderMainContainer>
  )
}

export default SocialHeader
