import {
  Box,
  CircularProgress,
  Container,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { IconChecks, IconCopy, IconX } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams } from 'react-router'
import EmptyScreen from '../../../components/EmptyScreen'
import BasicModal from '../../../components/modal/BasicModal'
import RestaurantList from '../../../components/restaurant/RestaurantList'
import SocialUserEmailSearch from '../../../components/social/SocialUserEmailSearch'
import { generateEater } from '../../../helpers/converters'
import { getLocation } from '../../../helpers/location-helper'
import { ROUTES } from '../../../helpers/routes-helper'
import {
  MOBILE_NAV_HEIGHT,
  MOBILE_SEARCH_HEIGHT,
  price,
  WEB_NAV_HEIGHT,
} from '../../../helpers/static-constants'
import { useAppDispatch, useAppSelector } from '../../../store'
import { selectLocation } from '../../../store/authSlice'
import { selectEater } from '../../../store/profileSlice'
import {
  addToGroup,
  fetchGroupById,
  getGroupPredictions,
  removeFromGroup,
  selectGroup,
  selectMessage,
  selectPredictedGroupRestaurants,
  selectSeverity,
  selectShouldOpen,
  setOpen,
} from '../../../store/socialSlice'
import {
  GroupMemberType,
  IFilterResponseItem,
  PredictedRestaurantGroupRequest,
} from '../../../types'
import { AddRemoveGroupRequest, Group, Member } from '../../../types/groupTypes'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import { MuiAlert, MuiBackdrop, MuiSnackbar } from '../styled/global.styled'
import ChatDrawer from './components/ChatDrawer'
import GroupHeader from './components/GroupHeader'
import StoryBoard from './components/StoryBoard'
import {
  GroupEditMemberListAvatar,
  GroupEditMemberListChip,
  GroupEditMemberListChipContainer,
  GroupEditMemberListInfoContainer,
  GroupEditMemberListInfoInnerContainer,
  GroupEditMemberListMainContainer,
  GroupMainContainer,
} from './styled/Group.styled'

const GroupPage: React.FC = () => {
  const theme = useTheme()
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const analytics = useRudderStackAnalytics()
  const location = useAppSelector(selectLocation)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const eater = useAppSelector(selectEater)
  const group = useAppSelector(selectGroup)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const openServer = useAppSelector(selectShouldOpen)
  const groupRestaurants = useAppSelector(selectPredictedGroupRestaurants)
  const [isCopy, setIsCopy] = useState(false)
  const [pageTitle, setPageTitle] = useState('')
  const [loadingIndex, setLoadingIndex] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [emailList, setEmailList] = useState<Member[]>([])
  const [staticLoading, setStaticLoading] = useState(false)
  const [currentGroup, setCurrentGroup] = useState<Group>()
  const [pageDescription, setPageDescription] = useState('')
  const [memberList, setMemberList] = useState<Member[]>([])
  const [openMassager, setOpenMassager] = React.useState(false)
  const [filters, setFilters] = useState(groupRestaurants ?? [])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [editMemberLoading, setEditMemberLoading] = useState(false)

  useEffect(() => {
    setPageTitle(`Resactly | ${group?.name ?? ''} Group Details`)
    setPageDescription(
      `View detailed information about the ${
        group?.name ?? ''
      } Group on Resactly. Explore group members, activities, and shared dining recommendations. Connect with group members and discover new dining experiences together.`,
    )
  }, [group])

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(eater ?? {})

    analytics?.page({
      path: `${ROUTES.SOCIAL}/${id as string}`,
      title: pageTitle,
      url: `${window.location.origin}${ROUTES.SOCIAL}/${id as string}`,
      category: 'Group',
      name: `${group?.name ?? ''} Group Viewed`,
      eaterName: fullName,
      email,
      eaterId,
    })
  }, [pageTitle, analytics, eater])

  useEffect(() => {
    void fetchData()
  }, [dispatch, id])

  const fetchData = async (): Promise<void> => {
    setStaticLoading(true)
    if (id) {
      let local = location
      if (location === undefined) {
        local = await getLocation()
      }
      const request: PredictedRestaurantGroupRequest = {
        longitude: local?.longitude,
        latitude: local?.latitude,
        page: 0,
        groupId: id ?? '',
      }
      await dispatch(fetchGroupById(id))
      await dispatch(getGroupPredictions(request))
    } else {
      navigate(ROUTES.HOME)
    }
    setStaticLoading(false)
  }

  useEffect(() => {
    if (group && group.members) {
      setCurrentGroup(group)
      const groupMemberList = [...group.members].sort((a, b) => {
        if (a?.owner === b?.owner) {
          return 0
        }
        return a.owner ? -1 : 1
      })

      setMemberList(groupMemberList ?? [])
    }
  }, [group.id])

  const onCloseModal = (): void => {
    setEmailList([])
    setIsModalOpen(false)
  }

  const addMember = async (): Promise<void> => {
    setEditMemberLoading(true)
    const request: AddRemoveGroupRequest = {
      groupId: id ?? '',
      member: emailList[0],
    }

    const result = await dispatch(addToGroup(request))
    if (addToGroup.fulfilled.match(result)) {
      setEmailList([])
      setIsModalOpen(false)
    }
    setEditMemberLoading(false)
  }

  const removeMember = async (
    member: GroupMemberType,
    index: number,
  ): Promise<void> => {
    setLoadingIndex(index)
    setIsLoading(true)

    const request: AddRemoveGroupRequest = {
      groupId: id ?? '',
      member,
    }

    const result = await dispatch(removeFromGroup(request))
    if (removeFromGroup.fulfilled.match(result)) {
      setMemberList(
        memberList.filter((iterator) => iterator.eaterId !== member.eaterId),
      )
    }
    setIsLoading(false)
  }

  const copyCurrentUrl = async (): Promise<void> => {
    const currentUrl = window.location.href
    await navigator.clipboard.writeText(`${currentUrl}`)
    setIsCopy(true)
    setTimeout(() => {
      setIsCopy(false)
    }, 3000)
  }

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  const onFilterData = (obj: IFilterResponseItem): void => {
    if (groupRestaurants) {
      const data = groupRestaurants.filter((dish) => {
        if (
          obj.price !== undefined &&
          obj.price > 1 &&
          price.indexOf(dish.restaurantDTO.priceRange) > Number(obj.price) - 1
        ) {
          return false
        }
        if (
          obj.distance !== undefined &&
          obj.distance > 0 &&
          Number(dish.restaurantDTO.distance ?? 0) > Number(obj.distance)
        ) {
          return false
        }

        if (obj.dietary && obj.dietary.length > 0) {
          const dietaryValues = obj.dietary.map((item) =>
            item.name.trim().toLowerCase(),
          )
          if (
            !dietaryValues.some((dietary) =>
              dish?.restaurantDTO?.dietary
                ?.map((d) => d.trim().toLowerCase())
                .includes(dietary.trim().toLowerCase()),
            )
          ) {
            return false
          }
        }
        if (obj.cuisine && obj.cuisine.length > 0) {
          const cuisineValues = obj.cuisine.map((item) => item.name)
          if (
            !cuisineValues.some(
              (dietary) =>
                dish?.restaurantDTO?.cuisine?.toLocaleLowerCase() ===
                dietary.toLowerCase(),
            )
          ) {
            return false
          }
        }

        return true
      })
      setFilters(data)
    }
  }

  return (
    <GroupMainContainer>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Container
        component="main"
        sx={{ flex: 1 }}
        style={{ minHeight: '78vh' }}
      >
        {!staticLoading ? (
          <div>
            <GroupHeader
              open={openMassager}
              openChat={setOpenMassager}
              headerTitle={`${currentGroup?.name ?? ''} group`}
              badgeContent={currentGroup?.unreadMessageCount ?? 0}
              restaurants={groupRestaurants}
              onFilter={onFilterData}
            />
            {memberList.find((item) => item.owner) && (
              <StoryBoard
                memberList={memberList}
                onEditGroupModal={() => setIsModalOpen(true)}
              />
            )}
            {groupRestaurants.length > 0 ? (
              <RestaurantList
                cardList={filters}
                openMassager={openMassager}
                mobileSearchHight={`${
                  MOBILE_SEARCH_HEIGHT + MOBILE_NAV_HEIGHT + 211
                }px`}
                noneMobileSearchHight={`${MOBILE_NAV_HEIGHT + 210}px`}
              />
            ) : (
              <EmptyScreen
                mainText="No data available"
                secondaryText="No restaurants found"
                imageUrl="/images/empty-screen.svg"
                containerHeight={'400px'}
              />
            )}
            <BasicModal
              open={isModalOpen}
              onCancel={onCloseModal}
              onConfirm={() => {
                void addMember()
              }}
              title="Add/Edit Group Members"
              cancelButtonName="Cancel"
              buttonName="Update"
              handleClose={onCloseModal}
              cancelIcon
              modelWidth="444px"
              isLoader={editMemberLoading}
              saveBtnDisabled={emailList.length < 1}
            >
              <div>
                <Typography variant="subtitle1" mb="6px" mt="16px">
                  Invite Users by Email
                </Typography>
                <SocialUserEmailSearch
                  memberList={emailList}
                  setMemberList={setEmailList}
                  isEmailValid={false}
                  type="update"
                />

                <Box
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                  bgcolor={theme.palette.grey[100]}
                  py="8px"
                  px="10px"
                  mt="16px"
                  borderRadius="8px"
                  sx={{
                    cursor: 'pointer',
                  }}
                  border={`1px solid ${theme.palette.grey[200]}`}
                  onClick={() => {
                    void copyCurrentUrl()
                  }}
                >
                  {isCopy ? (
                    <IconChecks size={18} stroke={2} />
                  ) : (
                    <IconCopy size={18} stroke={2} />
                  )}
                  <Typography
                    variant="subtitle2"
                    pl="8px"
                    color={theme.palette.grey[600]}
                  >
                    Copy Group Link
                  </Typography>
                </Box>
                <Box
                  my="16px"
                  maxHeight="260px"
                  sx={{
                    overflowY: 'auto',
                  }}
                  display="flex"
                  flexDirection="column"
                  gap="16pX"
                >
                  {memberList.map((member, index) => {
                    return (
                      <GroupEditMemberListMainContainer key={index}>
                        <GroupEditMemberListInfoContainer>
                          <GroupEditMemberListAvatar
                            src={
                              member.imageUrl ??
                              'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmJUeQCIV5gK-gudX5l3OIhRcmgnbtGDhExw&s'
                            }
                          />
                          <GroupEditMemberListInfoInnerContainer>
                            <Typography
                              noWrap
                              variant="subtitle1"
                              color={theme.palette.grey[800]}
                            >
                              {member.me ? 'You' : member.name}
                            </Typography>
                            <Typography
                              noWrap
                              variant="subtitle2"
                              color={theme.palette.grey[500]}
                            >
                              {member.email}
                            </Typography>
                          </GroupEditMemberListInfoInnerContainer>
                        </GroupEditMemberListInfoContainer>
                        <GroupEditMemberListChipContainer>
                          {member.owner ? (
                            <GroupEditMemberListChip label="Admin" />
                          ) : isLoading && index === loadingIndex ? (
                            <CircularProgress color="inherit" size={18} />
                          ) : (
                            <IconX
                              stroke={2}
                              size={18}
                              color={theme.palette.grey[500]}
                              onClick={() => {
                                void removeMember(member, index)
                              }}
                              style={{
                                cursor: 'pointer',
                              }}
                            />
                          )}
                        </GroupEditMemberListChipContainer>
                      </GroupEditMemberListMainContainer>
                    )
                  })}
                </Box>
              </div>
            </BasicModal>
          </div>
        ) : (
          <div>
            <MuiBackdrop open={staticLoading}>
              <CircularProgress color="inherit" />
            </MuiBackdrop>
          </div>
        )}
      </Container>
      {openMassager && currentGroup && (
        <Drawer
          variant={isSmallScreen ? 'temporary' : 'persistent'}
          anchor="right"
          open={openMassager}
          translate="yes"
          transitionDuration={600}
          sx={{
            flexShrink: 0,
            width: { xs: '100vw', md: 392 },
            '& .MuiDrawer-paper': {
              width: { xs: '100vw', md: 392 },
              marginTop: {
                xs: `0px`,
                md: `${WEB_NAV_HEIGHT}px`,
              },
            },
          }}
        >
          <ChatDrawer toggleDrawer={setOpenMassager} group={currentGroup} />
        </Drawer>
      )}
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </GroupMainContainer>
  )
}

export default GroupPage
