import {
  Eater,
  IGenerateCustomEater,
  Restaurant,
  RestaurantAndPredictedRestaurant,
  RestaurantResponse,
} from '../types'
import { Item } from '../types/restaurantTypes'
import { DEFAULT_CARD_IMAGE } from './static-constants'

export const mapToItems = (restaurant: RestaurantResponse): Item => {
  // eslint-disable-next-line
  const description =
    restaurant?.restaurantDTO?.description?.length &&
    restaurant.restaurantDTO.description?.length > 150
      ? `${restaurant?.restaurantDTO?.description?.slice(0, 120)}...`
      : restaurant.restaurantDTO.description ?? ''
  return {
    name:
      restaurant.restaurantDTO.name.length > 30
        ? `${restaurant.restaurantDTO.name.slice(0, 15)}...`
        : restaurant.restaurantDTO.name,
    alias: restaurant.restaurantDTO.alias,
    label: restaurant.label ?? 'Yes',
    starCount: restaurant.starCount,
    probability: `${restaurant.value ?? 0}`,
    // eslint-disable-next-line
    description: description,
    price: restaurant.restaurantDTO.priceRange,
    distance: restaurant.restaurantDTO.distance ?? 0,
    image: restaurant.restaurantDTO.imageurl,
    navigate:
      'https://www.google.com/maps/dir/?api=1&destination=' +
      generateAddress(restaurant.restaurantDTO),
    saved: restaurant.restaurantDTO.saved,
    group: restaurant.group,
    groupprobability: restaurant.groupValue,
    grouprecommendations: restaurant.groupRecommendations,
    verified: restaurant?.restaurantDTO?.verified,
    open: restaurant.restaurantDTO.open,
    weekdayText: restaurant.restaurantDTO.weekdayText,
  }
}

export const generateAddress = (restaurant: Restaurant): string => {
  const address = `${restaurant.address1} ${restaurant.city}, ${
    restaurant.state
  } ${restaurant.zip_code ?? ''}`
  return encodeURIComponent(address.trim())
}

export const generateEater = (eater: Eater): IGenerateCustomEater => {
  const {
    firstName,
    lastName,
    eaterId,
    email,
    phone,
    addressLine1,
    addressLine2,
    city,
    state,
    zipcode,
    country,
    instagram,
    facebook,
    twitter,
    x,
    tiktok,
    occupation,
    imageUrl,
  } = eater
  const userName = `${firstName ?? ''} ${lastName ?? ''}`.trim()

  return {
    eaterId: eaterId ?? '',
    firstName: firstName ?? '',
    lastName: lastName ?? '',
    fullName: userName,
    email: email ?? '',
    phone: phone ?? '',
    addressLine1: addressLine1 ?? '',
    addressLine2: addressLine2 ?? '',
    city: city ?? '',
    state: state ?? '',
    zipcode: zipcode ?? '',
    country: country ?? '',
    instagram: instagram ?? '',
    facebook: facebook ?? '',
    twitter: twitter ?? '',
    x: x ?? '',
    tiktok: tiktok ?? '',
    occupation: occupation ?? '',
    imageUrl: imageUrl ?? DEFAULT_CARD_IMAGE,
  }
}

export const convertToPredictedAndPublicRestaurantList = (
  restaurants: Restaurant[],
): RestaurantAndPredictedRestaurant[] => {
  return restaurants.map((restaurant) => ({
    restaurantDTO: restaurant,
    label: restaurant.name,
    value: undefined,
    groupValue: undefined,
    starCount: undefined,
    recommendation: undefined,
    groupRecommendations: [],
    group: false,
  }))
}
export const convertToPredictedAndPublicRestaurant = (
  restaurant: Restaurant,
): RestaurantAndPredictedRestaurant => {
  return {
    restaurantDTO: restaurant,
    label: restaurant.name,
    value: undefined,
    groupValue: undefined,
    starCount: undefined,
    recommendation: undefined,
    groupRecommendations: [],
    group: false,
  }
}
