import {
  Avatar,
  AvatarGroup,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'
import CustomButton from '../../../../components/ui/CustomButton'
import { useAuth0 } from '@auth0/auth0-react'
import {
  ResactlyCommunityInvitationContainer,
  ResactlyCommunityInvitationGrid,
  ResactlyCommunityInvitationGridContainer,
  ResactlyCommunityInvitationGridContainerSecond,
  ResactlyCommunityInvitationGridInnerContainer,
  ResactlyCommunityInvitationInnerContainer,
} from '../styled/ResactlyCommunityInvitation.styled'

const ResactlyCommunityInvitation: React.FC = (): JSX.Element => {
  const theme = useTheme()
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const [loadingImage, setLoadingImage] = useState(true)
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  const login = async (): Promise<void> => {
    await loginWithRedirect()
  }

  return (
    <div>
      <Grid container columnSpacing={['16px', '20px', '24px']}>
        <ResactlyCommunityInvitationGrid item sm={12} md={4.2}>
          <ResactlyCommunityInvitationGridContainer>
            <ResactlyCommunityInvitationGridInnerContainer>
              <Typography
                component="p"
                variant="h3"
                fontWeight={700}
                color={theme.palette.primary.main}
              >
                Join the Resactly Community.
              </Typography>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  mt: {
                    xs: '8px',
                    md: '16px',
                  },
                }}
                color={theme.palette.primary.main}
              >
                {`By joining Resactly, you become part of a vibrant ecosystem
                where food lovers and restaurants come together to celebrate the
                art of dining. Whether you're seeking your next culinary
                adventure or aiming to bring your restaurant's vision to life,
                Resactly is your partner in crafting experiences that delight
                and satisfy.`}
              </Typography>
            </ResactlyCommunityInvitationGridInnerContainer>
            <AvatarGroup sx={{ position: 'absolute', bottom: '-6px' }} max={9}>
              {images.map((src, index) => (
                <Avatar
                  key={index}
                  sx={{
                    width: ['46px', '50px', '54px'],
                    height: ['46px', '50px', '54px'],
                  }}
                  alt={`Avatar ${index + 1}`}
                  src={src}
                />
              ))}
            </AvatarGroup>
          </ResactlyCommunityInvitationGridContainer>

          <ResactlyCommunityInvitationGridContainerSecond>
            <div>
              <Typography
                component="p"
                variant="h3"
                fontWeight={700}
                color={theme.palette.primary.main}
              >
                For Food Lovers: Your Culinary Journey, Reimagined.
              </Typography>
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  mt: {
                    xs: '8px',
                    md: '16px',
                  },
                  mb: {
                    xs: '26px',
                    sm: '20px',
                    md: '0px',
                  },
                }}
                color={theme.palette.primary.main}
              >
                {`Gone are the days of hit-or-miss dining experiences. Resactly harnesses the power of AI and ML to understand your unique taste profile, dietary preferences, and culinary cravings, connecting you with restaurants eager to serve up your next favorite meal. Whether you're a vegan, a meat-lover, or somewhere in between, our platform ensures that every recommendation is tailored just for you. Ready to transform your dining experiences from mundane to extraordinary? Let Resactly be your guide to discovering restaurants that resonate with your soul.`}
              </Typography>
            </div>
            <div>
              <img
                alt="resactly-match"
                width="100%"
                height="100%"
                srcSet={`${'/images/about/match.svg'}?w=20&h=20&fit=crop&auto=format&dpr=2 2x`}
                src={`${'/images/about/match.svg'}?w=30&h=30&fit=crop&auto=format`}
                loading="lazy"
              />
            </div>
          </ResactlyCommunityInvitationGridContainerSecond>
        </ResactlyCommunityInvitationGrid>
        <Grid
          item
          sm={12}
          md={7.8}
          sx={{
            mt: {
              xs: '16px',
              sm: '20px',
              md: '0px',
            },
          }}
        >
          <div
            style={{
              backgroundColor: theme.palette.secondary[100],
              position: 'relative',
              overflow: 'hidden',
              borderRadius: '24px',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ maxWidth: '725px', padding: '24px' }}>
              {!isMediumScreen ? (
                <>
                  <Typography
                    component="p"
                    variant="h3"
                    fontWeight={700}
                    color={theme.palette.primary.main}
                  >
                    For Restaurants
                  </Typography>
                  <Typography
                    component="p"
                    variant="h3"
                    fontWeight={700}
                    color={theme.palette.primary.main}
                  >
                    Data-Driven Success on Your Terms.
                  </Typography>
                </>
              ) : (
                <Typography
                  component="p"
                  variant="h3"
                  fontWeight={700}
                  color={theme.palette.primary.main}
                >
                  For Restaurants Data-Driven Success on Your Terms.
                </Typography>
              )}
              <Typography
                component="p"
                variant="subtitle1"
                sx={{
                  mt: {
                    xs: '8px',
                    md: '16px',
                  },
                }}
                color={theme.palette.primary.main}
              >
                {`But there's another side to Resactly - a suite of cutting-edge
                tools designed for restaurants to thrive in today's competitive
                market. We provide real-time analytical data that empowers you
                to make informed decisions about your menu, understand your
                customer base better, and tailor your offerings to meet the
                evolving tastes of your diners. In partnering with us, you gain
                insights that not only enhance your operational efficiency but
                also elevate the dining experience for your guests.`}
              </Typography>
            </div>
            <div
              style={{
                paddingLeft: '24px',
                height: ' -webkit-fill-available',
                width: '-webkit-fill-available ',
              }}
            >
              {loadingImage && (
                <Skeleton
                  variant="rounded"
                  height={'100%'}
                  sx={{
                    borderTopLeftRadius: '20px',
                  }}
                  width="100%"
                />
              )}
              <img
                width="100%"
                height="100%"
                alt="dashboard"
                srcSet={`${'/images/about/dashboard.png'}?w=20&h=20&fit=crop&auto=format&dpr=2 2x`}
                src={`${'/images/about/dashboard.png'}?w=30&h=30&fit=crop&auto=format`}
                style={{
                  display: !loadingImage ? 'block' : 'none',
                }}
                onLoad={() => setLoadingImage(false)}
                onError={() => setLoadingImage(false)}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <ResactlyCommunityInvitationContainer>
        <ResactlyCommunityInvitationInnerContainer>
          <Typography
            component="p"
            variant="h3"
            color={theme.palette.primary.main}
          >
            {`Don't Just Eat. Experience. With Resactly.`}
          </Typography>
          <Typography
            component="p"
            variant="subtitle1"
            sx={{ mt: 1, mb: 3 }}
            color={theme.palette.primary.main}
          >
            {`Ready to embark on a gastronomic journey that caters exclusively to you? Or eager to align your restaurant with the tastes of tomorrow? Create your account today and see how Resactly is changing the way the world eats, one plate at a time.`}
          </Typography>
          {!isAuthenticated && (
            <CustomButton
              title="Sign up now"
              sx={{ px: 3, py: 1.5, fontSize: '14px !important' }}
              onClick={() => {
                void login()
              }}
            />
          )}
        </ResactlyCommunityInvitationInnerContainer>
      </ResactlyCommunityInvitationContainer>
    </div>
  )
}

const images = [
  'https://img.freepik.com/free-photo/portrait-white-man-isolated_53876-40306.jpg',
  'https://t4.ftcdn.net/jpg/04/25/65/97/360_F_425659755_5BaLdNbbCtQbbiz1JNFBikshfiCWI5NE.jpg',
  'https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-55958-614810.jpg&fm=jpg',
  'https://images.rawpixel.com/image_800/czNmcy1wcml2YXRlL3Jhd3BpeGVsX2ltYWdlcy93ZWJzaXRlX2NvbnRlbnQvbHIvcm0zMjgtMzY2LXRvbmctMDhfMS5qcGc.jpg',
  'https://i.pinimg.com/736x/dc/c4/59/dcc459cef6213a9d5bf9fc2f17765ae4.jpg',
  'https://qodebrisbane.com/wp-content/uploads/2019/07/This-is-not-a-person-10-1.jpeg',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTGmt7mgLLJbU_An415Sur0-Iq8kRKQzzAwCw&s',
  'https://t4.ftcdn.net/jpg/02/45/56/35/360_F_245563558_XH9Pe5LJI2kr7VQuzQKAjAbz9PAyejG1.jpg',
  'https://img.freepik.com/free-photo/close-up-portrait-handsome-smiling-young-man-white-t-shirt-looking-away-blurry-outdoor-nature_176420-6304.jpg',
]

export default ResactlyCommunityInvitation
