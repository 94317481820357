import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Mousewheel, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import ImgIcon from '../../../../components/ui/ImgIcon'
import { ROUTES } from '../../../../helpers/routes-helper'
import { ILandingCuisinesResponse } from '../../../../types'
import {
  CuisineToTryBoxContainer,
  CuisineToTryInnerContainer,
  CuisineToTryMainContainer,
  CuisineToTryNoDataTypographyTitle,
  CuisineToTrySwiperImgContainer,
  CuisineToTrySwiperSlideContainer,
  CuisineToTryTypographyTitle,
} from '../styled/CuisineToTry.styled'

interface RestaurantCuisineProps {
  cuisineList: ILandingCuisinesResponse[]
}

const CuisineToTry: React.FC<RestaurantCuisineProps> = ({ cuisineList }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('lg'))
  const [isEnd, setIsEnd] = useState(false)
  const [isBeginning, setIsBeginning] = useState(true)
  const swiperRef = useRef<SwiperRef>(null)

  const handlePrev = (): void => {
    if (swiperRef.current?.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  const handleNext = (): void => {
    if (swiperRef.current?.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const handleSlideChange = (swiper: any): void => {
    setIsBeginning(swiper.isBeginning)
    setIsEnd(swiper.isEnd)
  }

  const onViewCuisine = (dish: ILandingCuisinesResponse): void => {
    navigate(
      `${ROUTES.RESTAURANTS}?type=cuisine&name=${dish.key}&value=${dish.value}`,
    )
  }

  return (
    <CuisineToTryMainContainer className="no-select">
      <CuisineToTryBoxContainer>
        <Container component="main">
          <CuisineToTryTypographyTitle variant="h2">
            What cuisine do you want to try today?
          </CuisineToTryTypographyTitle>

          <CuisineToTryInnerContainer className="dish-to-try-swipe">
            {isSmallScreen && cuisineList.length !== 0 && (
              <>
                <ImgIcon
                  iconElement={<IconChevronLeft stroke={2} />}
                  alt="left-swipe-cuisine"
                  imgSize={18}
                  wrapperStyle={{
                    backgroundColor: theme.palette.secondary.contrastText,
                    height: '36px',
                    width: '36px',
                    outline: `2px solid ${theme.palette.grey[200]}`,
                    marginRight: '10px',
                    cursor: isBeginning ? 'not-allowed' : 'pointer',
                    display: isBeginning ? 'none' : 'flex',
                    opacity: isBeginning ? 0.5 : 1,
                    position: 'absolute',
                    left: '-49px',
                    top: '86px',
                  }}
                  onClick={isBeginning ? undefined : handlePrev}
                />
                <ImgIcon
                  iconElement={<IconChevronRight stroke={2} />}
                  alt="right-swipe-cuisine"
                  imgSize={18}
                  wrapperStyle={{
                    backgroundColor: theme.palette.secondary.contrastText,
                    height: '36px',
                    width: '36px',
                    outline: `2px solid ${theme.palette.grey[200]}`,
                    marginRight: '10px',
                    cursor: isEnd ? 'not-allowed' : 'pointer',
                    display: isEnd ? 'none' : 'flex',
                    opacity: isEnd ? 0.5 : 1,
                    position: 'absolute',
                    right: '-60px',
                    top: '87px',
                  }}
                  onClick={isEnd ? undefined : handleNext}
                />
              </>
            )}
            {cuisineList.length !== 0 ? (
              <Swiper
                ref={swiperRef}
                slidesPerView={5.4}
                spaceBetween={10}
                navigation={false}
                mousewheel={true}
                pagination={{
                  dynamicBullets: true,
                }}
                grabCursor={true}
                modules={[Mousewheel, Navigation, Pagination]}
                onSlideChange={handleSlideChange}
                className="mySwiper"
                breakpoints={breakpoints}
              >
                {cuisineList.map((cuisine, index: number) => {
                  if (cuisine.key.trimStart().length < 1) return null
                  return (
                    <SwiperSlide
                      key={index}
                      virtualIndex={index}
                      style={{
                        background: 'transparent',
                      }}
                    >
                      <CuisineToTrySwiperSlideContainer>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <CuisineToTrySwiperImgContainer
                            onClick={() => onViewCuisine(cuisine)}
                          >
                            <img
                              src={'/new/images/home/pizza.png'}
                              alt={'resactly-cuisine'}
                              style={{
                                objectFit: 'contain',
                                cursor: 'pointer',
                              }}
                            />
                          </CuisineToTrySwiperImgContainer>

                          <Typography
                            variant="h4"
                            maxWidth="100%"
                            textOverflow={'ellipsis'}
                          >
                            {cuisine.value}
                          </Typography>
                        </Box>
                      </CuisineToTrySwiperSlideContainer>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            ) : (
              <CuisineToTryNoDataTypographyTitle
                variant="body2"
                component="p"
                color={theme.palette.grey[400]}
              >
                We cannot find any cuisine with your filters
              </CuisineToTryNoDataTypographyTitle>
            )}
          </CuisineToTryInnerContainer>
        </Container>
      </CuisineToTryBoxContainer>
    </CuisineToTryMainContainer>
  )
}

export default CuisineToTry

const breakpoints = {
  '0': {
    slidesPerView: 2,
    spaceBetween: 10,
  },
  '375': {
    slidesPerView: 3,
    spaceBetween: 10,
  },
  '400': {
    slidesPerView: 3,
    spaceBetween: 10,
  },
  '640': {
    slidesPerView: 3,
    spaceBetween: 10,
  },
  '768': {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  '900': {
    slidesPerView: 4,
    spaceBetween: 10,
  },
  '1024': {
    slidesPerView: 5.4,
    spaceBetween: 10,
  },
}
