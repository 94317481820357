import { zodResolver } from '@hookform/resolvers/zod'
import { Typography, useMediaQuery, useTheme } from '@mui/material'
import { IconPlus } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import EmptyScreen from '../../../components/EmptyScreen'
import BasicModal from '../../../components/modal/BasicModal'
import SocialUserEmailSearch from '../../../components/social/SocialUserEmailSearch'
import ImgIcon from '../../../components/ui/ImgIcon'
import { generateEater } from '../../../helpers/converters'
import { ROUTES } from '../../../helpers/routes-helper'
import { useAppDispatch, useAppSelector } from '../../../store'
import { selectEater } from '../../../store/profileSlice'
import {
  createGroup,
  fetchGroups,
  selectGroupRequests,
  selectGroups,
  selectIsLoading,
  selectMessage,
  selectPage,
  selectSeverity,
  selectShouldOpen,
  selectTotalPages,
  setOpen,
} from '../../../store/socialSlice'
import { Group, Member } from '../../../types/groupTypes'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import {
  OnboardRequestInnerGridContainer,
  OnboardRequestTextField,
} from '../onboardPages/styled/OnboardRequest.styled'
import { MuiAlert, MuiSnackbar } from '../styled/global.styled'
import SocialGroup from './components/SocialGroup'
import SocialHeader from './components/SocialHeader'
import {
  SocialBasicModalContainer,
  SocialEmptyScreenButton,
  SocialEmptyScreenContainer,
  SocialEmptyScreenIcon,
  SocialInputLabel,
  SocialMainContainer,
  SocialSkeleton,
  SocialUserEmailSearchContainer,
} from './styled/Social.styled'

const DocumentTitle = 'Resactly | Social Connect & Share Dining Recommendations'

const Social: React.FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const groups = useAppSelector(selectGroups)
  const groupRequests = useAppSelector(selectGroupRequests)
  const isLoading = useAppSelector(selectIsLoading)
  const openServer = useAppSelector(selectShouldOpen)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const totalPages = useAppSelector(selectTotalPages)
  const page = useAppSelector(selectPage)
  const eater = useAppSelector(selectEater)
  const analytics = useRudderStackAnalytics()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [emailList, setEmailList] = useState<Member[]>([])
  const [createGroupLoading, setCreateGroupLoading] = useState(false)
  const [groupList, setGroupList] = useState<Group[]>(groups ?? [])
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const { fullName, eaterId, email } = generateEater(eater ?? {})

    analytics?.page({
      path: ROUTES.SOCIAL,
      title: DocumentTitle,
      url: `${window.location.origin}${ROUTES.SOCIAL}`,
      category: 'Social',
      name: 'Social Viewed',
      eaterName: fullName,
      email,
      eaterId,
    })
  }, [analytics, eater])

  useEffect(() => {
    if (groupList.length === 0 && groupRequests.length === 0) {
      fetchItems(0).catch(() => {})
    }
  }, [])

  useEffect(() => {
    setGroupList(groups)
  }, [groups])

  useEffect(() => {
    if (emailList.length && isEmailValid) {
      setIsEmailValid(false)
    }
  }, [emailList])

  const onCloseModal = (): void => {
    reset({})
    setIsEmailValid(false)
    setIsModalOpen(false)
  }

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  const fetchItems = async (pageNumber: number): Promise<void> => {
    if (loading) return
    setLoading(true)
    await dispatch(fetchGroups(pageNumber))
    setLoading(false)
  }

  const onHandleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const target = e.currentTarget as HTMLDivElement
    const bottom =
      Math.round(target.scrollHeight - target.scrollTop) === target.clientHeight

    if (bottom && totalPages - 1 > page && !loading) {
      fetchItems(page + 1).catch(() => {})
    }
  }

  const formSchema = z.object({
    groupName: z.string().min(1, { message: ' ' }),
    location: z.string().min(1, { message: ' ' }),
    searchTerm: z.string().min(1, { message: ' ' }),
  })

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {},
  })

  const getPayload = async (): Promise<void> => {
    if (!emailList.length) {
      setIsEmailValid(true)
    }
    await handleSubmit(
      async (data: z.infer<typeof formSchema>): Promise<void> => {
        if (!isEmailValid && emailList.length) {
          setCreateGroupLoading(true)

          const payload = {
            name: data.groupName,
            members: emailList,
            location: data.location,
            searchTerm: data.searchTerm,
          }
          const result = await dispatch(createGroup(payload))

          if (createGroup.fulfilled.match(result)) {
            setIsModalOpen(false)
            reset({})
            setEmailList([])
          }

          setCreateGroupLoading(false)
        }
      },
    )()
  }

  return (
    <SocialMainContainer>
      <Helmet>
        <title>{DocumentTitle}</title>
        <meta
          name="description"
          content="Engage with the Resactly community on the Social page. Share your dining experiences, join group discussions, and connect with fellow food enthusiasts for collective dining recommendations."
        />
      </Helmet>
      <div>
        {!isSmallScreen && (
          <SocialHeader onAddGroup={() => setIsModalOpen(true)} />
        )}
        {isSmallScreen && (
          <ImgIcon
            alt="create-resactly-group"
            iconElement={
              <IconPlus
                size={18}
                stroke={2}
                color={theme.palette.background.paper}
              />
            }
            wrapperStyle={{
              backgroundColor: theme.palette.primary.light,
              position: 'absolute',
              bottom: '20px',
              right: '20px',
              boxShadow: '0px 0px 12px 0px #00000059',
              zIndex: 10,
            }}
            onClick={() => setIsModalOpen(true)}
          />
        )}
        {(groupList.length !== 0 || groupRequests.length !== 0) && (
          <SocialGroup
            isLoader={isLoading}
            groups={groupList}
            groupRequests={groupRequests}
            handleScroll={onHandleScroll}
            page={page}
          />
        )}

        {isLoading && groupList.length < 1 && (
          <SocialSkeleton variant="rounded" height={98} />
        )}

        {!isLoading && groupList.length === 0 && groupRequests.length === 0 && (
          <EmptyScreen
            containerHeight="calc(100vh - 296px)"
            imageUrl="/images/empty-screen.svg"
            mainText="No Groups Yet"
            secondaryText={`Looks like you haven't created or joined any group Groups allow you to connect with others to discover collective dining recommendations`}
          >
            <SocialEmptyScreenContainer>
              <SocialEmptyScreenButton
                variant="text"
                onClick={() => setIsModalOpen(true)}
              >
                <SocialEmptyScreenIcon stroke={2} size={18} />

                <Typography
                  component="p"
                  variant="subtitle1"
                  color={theme.palette.primary.main}
                  fontWeight={600}
                >
                  New Group
                </Typography>
              </SocialEmptyScreenButton>
            </SocialEmptyScreenContainer>
          </EmptyScreen>
        )}
      </div>

      <BasicModal
        open={isModalOpen}
        onCancel={onCloseModal}
        onConfirm={() => {
          void getPayload()
        }}
        title="Create New Group"
        cancelButtonName="Cancel"
        buttonName="Create"
        handleClose={onCloseModal}
        cancelIcon
        modelWidth="444px"
        isLoader={createGroupLoading}
      >
        <SocialBasicModalContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <SocialInputLabel htmlFor="lastName">Group Name</SocialInputLabel>
              <OnboardRequestTextField
                id="groupName"
                {...register('groupName')}
                error={!!errors.groupName}
                helperText={errors.groupName ? errors.groupName.message : ' '}
              />
            </div>
          </OnboardRequestInnerGridContainer>

          <SocialInputLabel>Invite Users by Email</SocialInputLabel>
          <SocialUserEmailSearchContainer>
            <SocialUserEmailSearch
              memberList={emailList}
              setMemberList={setEmailList}
              isEmailValid={isEmailValid}
            />
          </SocialUserEmailSearchContainer>

          <OnboardRequestInnerGridContainer>
            <div>
              <SocialInputLabel htmlFor="location">
                Location Name
              </SocialInputLabel>
              <OnboardRequestTextField
                id="location"
                {...register('location')}
                error={!!errors.location}
                helperText={errors.location ? errors.location.message : ' '}
              />
            </div>
          </OnboardRequestInnerGridContainer>
          <OnboardRequestInnerGridContainer>
            <div>
              <SocialInputLabel htmlFor="searchTerm">
                Search Term
              </SocialInputLabel>
              <OnboardRequestTextField
                id="searchTerm"
                {...register('searchTerm')}
                error={!!errors.searchTerm}
                helperText={errors.searchTerm ? errors.searchTerm.message : ' '}
              />
            </div>
          </OnboardRequestInnerGridContainer>
        </SocialBasicModalContainer>
      </BasicModal>
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </SocialMainContainer>
  )
}

export default Social
