import '@elastic/react-search-ui-views/lib/styles/styles.css'
import {
  Autocomplete,
  Box,
  CircularProgress,
  InputAdornment,
  ListItem,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { IconSearch } from '@tabler/icons-react'
import React, { useState } from 'react'
import useElasticGlobalSearch from '../../hooks/useElasticGlobalSearch'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../helpers/routes-helper'
import {
  setElasticSearchDishes,
  setElasticSearchRestaurants,
} from '../../store/searchSlice'
import { useAppDispatch } from '../../store'
import {
  MOBILE_SEARCH_HEIGHT,
  WEB_NAV_HEIGHT,
} from '../../helpers/static-constants'

interface RenderOptionProps extends React.HTMLAttributes<HTMLLIElement> {
  key: string
}

const GlobalSearch: React.FC = (): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchInputValue, setSearchInputValue] = useState('')
  const { results, loading } = useElasticGlobalSearch(searchInputValue)

  const handleSubmit = (result: any): void => {
    if (!result) return

    if (typeof result === 'string' && results.length > 0) {
      const restaurantArray = results
        .filter((obj) => obj?.restaurantID)
        .map((obj) => obj?._meta?.rawHit?._source)

      const dishArray = results
        .filter((obj) => obj?.dishID)
        .map((obj) => obj?._meta?.rawHit?._source)

      dispatch(setElasticSearchRestaurants(restaurantArray))
      dispatch(setElasticSearchDishes(dishArray))
      navigate(`${ROUTES.SEARCH}?type=restaurant&q=${result}`)
    }

    const data = result?._meta?.rawHit?._source

    if (data?.restaurantID && data?.alias) {
      navigate(`${ROUTES.RESTAURANT_DETAILS}/${data?.alias as string}/details`)
    }
    if (data?.dishID && data?.restaurant) {
      navigate(
        `${ROUTES.RESTAURANT_DETAILS}/${data?.restaurant as string}/details`,
      )
    }
    if (data?.value && data?.key) {
      navigate(`${ROUTES.SEARCH}?type=cuisine&q=${data?.key as string}`)
    }
  }

  return (
    <Box
      sx={{
        borderLeft: { xs: 'none', md: `1px solid ${theme.palette.grey[300]}` },
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        width: { xs: '100%', md: 'inherit' },
      }}
    >
      <Autocomplete
        freeSolo
        loading={loading}
        onChange={(event, value) => handleSubmit(value)}
        options={results.map((result: any) => result)}
        inputValue={searchInputValue}
        onInputChange={(event, newInputValue) => {
          setSearchInputValue(newInputValue)
        }}
        getOptionLabel={(option: any) =>
          option?.name?.raw ?? option?.key?.raw ?? ''
        }
        PaperComponent={(props) => (
          <Paper
            {...props}
            sx={{
              backgroundColor: 'white',
              mt: { xs: '0px', md: '4px' },
              boxShadow: {
                xs: '0px 12px 20px 0px rgba(112, 112, 112, 0.25)',
                md: '0px 0px 20px 0px rgba(112, 112, 112, 0.25)',
              },
              border: `1px solid ${theme.palette.grey[300]}`,
              borderRadius: { xs: '0px', md: '8px' },
              width: { xs: '100vw', md: '415px', lg: '100%' },
              padding: '8px',
            }}
          />
        )}
        renderOption={(props: RenderOptionProps, option) => {
          const { key, ...otherProps } = props
          return (
            <ListItem
              {...otherProps}
              key={key}
              sx={{
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                },
                padding: '8px !important',
                borderRadius: '8px',
              }}
            >
              <Box display="flex" alignItems="center">
                <Box display="flex" justifyContent="center">
                  <img
                    srcSet={`${'/logoLetter.svg'}?w=20&h=20&fit=crop&auto=format&dpr=2 2x`}
                    src={`${'/logoLetter.svg'}?w=30&h=30&fit=crop&auto=format`}
                    alt={option?.name?.raw}
                    style={{
                      height: '48px',
                      borderRadius: '12px',
                      marginRight: '8px',
                      cursor: 'pointer',
                    }}
                    loading="lazy"
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-evenly"
                >
                  <Typography
                    color={theme.palette.grey[800]}
                    variant="body2"
                    fontWeight={500}
                  >
                    {option?.name?.raw ?? option?.key?.raw}
                  </Typography>
                  <Typography
                    color={theme.palette.grey[500]}
                    variant="subtitle2"
                    fontWeight={500}
                  >
                    {option?.restaurantID
                      ? 'Restaurant'
                      : option?.dishID
                      ? 'Dish'
                      : 'Cuisine'}
                  </Typography>
                </Box>
              </Box>
            </ListItem>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search for location, restaurant, cuisine or dish"
            variant="outlined"
            autoFocus
            sx={{
              width: {
                xs: '100%',
                md: '100%',
                lg: '415px',
                xl: '415px',
              },
              minWidth: { md: '220px' },
              '& .MuiOutlinedInput-root': {
                fontSize: '14px',
                fontWeight: 500,
                height: {
                  xs: `${MOBILE_SEARCH_HEIGHT}px`,
                  md: `${WEB_NAV_HEIGHT}px`,
                },
                '& fieldset': {
                  border: 'none',
                },
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <IconSearch
                    stroke={2}
                    size={16}
                    style={{
                      color: theme.palette.grey[400],
                      marginLeft: '7px',
                    }}
                  />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  )
}

export default GlobalSearch
