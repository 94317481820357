import { AlertColor } from '@mui/material'
import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit'
import { RootState } from '.'
import okaoAxios from '../helpers/axios'
import { DiningReview, Eater, IRestaurantReview, StoryRequest } from '../types'
import {
  loginAudit,
  recoverUserAccount,
  restaurantOnboardRequest,
  updateRestaurantOnboard,
} from './authSlice'
import { updateEaterProfile } from './onboardSlice'
import { favoriteRestaurant } from './restaurantSlice'

export interface IProfileSlice {
  isProfileEdit: boolean
  isValidData: boolean
  isUpdate: boolean
  isEditCancelled: boolean
  currentTab: string
  status: string
  shouldOpen: boolean
  message: string | undefined
  severity: AlertColor | undefined
  eater: Eater | undefined
  savedRestaurantIds: string[]
  bioPayload: Eater
  eaterDishReviews: DiningReview[]
}

const initialState: IProfileSlice = {
  isProfileEdit: false,
  isValidData: false,
  isUpdate: false,
  isEditCancelled: false,
  currentTab: 'Bio',
  status: 'idle',
  shouldOpen: false,
  message: undefined,
  severity: undefined,
  eater: undefined,
  bioPayload: {},
  savedRestaurantIds: [],
  eaterDishReviews: [],
}

export const updateEater = createAsyncThunk(
  'api/updateEater',
  async (story: StoryRequest) => {
    const response = await okaoAxios.put(`/eater/update-eater`, story)
    return response?.data
  },
)
export const fetchEater = createAsyncThunk('api/fetchEater', async () => {
  const response = await okaoAxios.get(
    `${process.env.REACT_APP_SERVICE_URL ?? window.location.origin}/eater`,
  )
  return response?.data
})

export const updateEaterImage = createAsyncThunk(
  'api/updateEaterImage',
  async (formData: FormData) => {
    const response = await okaoAxios.post(`/api/upload/eater`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    const data = {
      ...response.data,
      headers: { ...response.headers },
    }
    return data
  },
)

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setIsProfileEdit: (state, action: PayloadAction<boolean>) => {
      state.isProfileEdit = action.payload
    },
    setIsValidData: (state, action: PayloadAction<boolean>) => {
      state.isValidData = action.payload
    },
    setIsUpdateData: (state, action: PayloadAction<boolean>) => {
      state.isUpdate = action.payload
    },
    setIsEditCancelledData: (state, action: PayloadAction<boolean>) => {
      state.isEditCancelled = action.payload
    },
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    },
    setBioPayload: (state, action: PayloadAction<Eater>) => {
      state.bioPayload = action.payload
    },
    setEater: (state, action: PayloadAction<Eater>) => {
      state.eater = action.payload
    },
    setRestaurantReviews: (
      state,
      action: PayloadAction<IRestaurantReview[]>,
    ) => {
      state.eater = { ...state.eater, restaurantReviews: action.payload }
    },
    setEaterDishReviews: (state, action: PayloadAction<DiningReview[]>) => {
      state.eaterDishReviews = action.payload
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    },
    setSeverity: (state, action: PayloadAction<AlertColor>) => {
      state.severity = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateEater.fulfilled, (state, action) => {
        state.eater = action.payload.eaterDTO
        state.status = 'idle'
      })
      .addCase(fetchEater.fulfilled, (state, action) => {
        state.eater = action.payload
        state.savedRestaurantIds =
          action.payload?.predictedSavedRestaurants?.result.map(
            (restaurant) => restaurant?.restaurantDTO?.restaurantID,
          )

        state.status = 'idle'
      })
      .addCase(favoriteRestaurant.fulfilled, (state, action) => {
        const { restaurantID } = action.payload
        const index = state.savedRestaurantIds.indexOf(restaurantID)

        if (index !== -1) {
          state.savedRestaurantIds.splice(index, 1)
          if (
            state.eater &&
            state.eater.predictedSavedRestaurants &&
            state.eater.predictedSavedRestaurants.result
          ) {
            const saveRestaurantList =
              state.eater.predictedSavedRestaurants.result.filter(
                (restaurant) =>
                  restaurant.restaurantDTO?.restaurantID !== restaurantID,
              )
            state.eater.predictedSavedRestaurants.result = saveRestaurantList
          }
        } else {
          state.savedRestaurantIds.push(restaurantID)
        }
        state.status = 'idle'
      })
      .addCase(recoverUserAccount.fulfilled, (state, action) => {
        state.eater = action.payload.data.eaterDTO
        state.savedRestaurantIds =
          action.payload.data?.eaterDTO?.predictedSavedRestaurants?.result.map(
            (restaurant) => restaurant?.restaurantDTO?.restaurantID,
          )
        state.status = 'idle'
      })
      .addCase(restaurantOnboardRequest.fulfilled, (state, action) => {
        state.eater = action.payload.eaterDTO
        state.savedRestaurantIds =
          action.payload?.eaterDTO?.predictedSavedRestaurants?.result.map(
            (restaurant) => restaurant?.restaurantDTO?.restaurantID,
          )
        state.status = 'idle'
      })
      .addCase(updateRestaurantOnboard.fulfilled, (state, action) => {
        state.eater = action.payload.eaterDTO
        state.savedRestaurantIds =
          action.payload.eaterDTO?.predictedSavedRestaurants?.result.map(
            (restaurant) => restaurant?.restaurantDTO?.restaurantID,
          )
        state.status = 'idle'
      })

      .addCase(loginAudit.fulfilled, (state, action) => {
        if (action.payload.eaterDTO !== null) {
          state.eater = action.payload.eaterDTO
          state.savedRestaurantIds =
            action.payload.eaterDTO?.predictedSavedRestaurants?.result.map(
              (restaurant) => restaurant?.restaurantDTO?.restaurantID,
            )
          state.eaterDishReviews = action.payload?.eaterDTO?.reviews
        }
        state.status = 'idle'
      })
      .addCase(updateEaterProfile.fulfilled, (state, action) => {
        if (action.payload.eaterDTO !== null && state.eater) {
          const {
            ingredientLikes,
            ingredientDislikes,
            cuisineLikes,
            cuisineDislikes,
            foodPreparationLiked,
            foodPreparationDisliked,
            ambienceLiked,
            ambienceDisliked,
            diningFrequency,
            dietaryPreferences,
            allergies,
            favoriteRestaurants,
            favoriteReviewers,
            x,
            facebook,
            instagram,
            tiktok,
          } = action.payload.eaterDTO
          state.eater.ingredientLikes = ingredientLikes
          state.eater.ingredientDislikes = ingredientDislikes
          state.eater.cuisineLikes = cuisineLikes
          state.eater.cuisineDislikes = cuisineDislikes
          state.eater.foodPreparationLiked = foodPreparationLiked
          state.eater.foodPreparationDisliked = foodPreparationDisliked
          state.eater.ambienceLiked = ambienceLiked
          state.eater.ambienceDisliked = ambienceDisliked
          state.eater.diningFrequency = diningFrequency
          state.eater.dietaryPreferences = dietaryPreferences
          state.eater.allergies = allergies
          state.eater.favoriteRestaurants = favoriteRestaurants
          state.eater.favoriteReviewers = favoriteReviewers
          state.eater.x = x
          state.eater.facebook = facebook
          state.eater.instagram = instagram
          state.eater.tiktok = tiktok
        }
        state.status = 'idle'
      })
      .addCase(updateEaterImage.fulfilled, (state, action) => {
        state.status = 'idle'
      })
      .addMatcher(
        isAnyOf(
          updateEater.rejected,
          updateEaterImage.rejected,
          fetchEater.rejected,
        ),
        (state, action) => {
          state.shouldOpen = true
          state.message =
            'Sorry an error occurred while updating your user. Please try again'
          state.status = 'error'
          state.severity = 'error'
        },
      )
      .addMatcher(
        isAnyOf(
          updateEater.pending,
          fetchEater.pending,
          updateEaterImage.pending,
        ),
        (state, action) => {
          state.status = 'loading'
        },
      )
  },
})

export const selectIsProfileEdit = (state: RootState): boolean =>
  state.profile?.isProfileEdit
export const selectIsValidData = (state: RootState): boolean =>
  state.profile?.isValidData
export const selectIsUpdateData = (state: RootState): boolean =>
  state.profile?.isUpdate
export const selectIsEditCancelledData = (state: RootState): boolean =>
  state.profile?.isEditCancelled
export const selectCurrentTab = (state: RootState): string =>
  state.profile?.currentTab
export const selectIsLoading = (state: RootState): boolean =>
  state.profile.status === 'loading'
export const selectShouldOpen = (state: RootState): boolean =>
  state.profile.shouldOpen
export const selectMessage = (state: RootState): string | undefined =>
  state.profile.message
export const selectSeverity = (state: RootState): AlertColor | undefined =>
  state.profile.severity
export const selectEater = (state: RootState): Eater | undefined =>
  state.profile.eater
export const selectBioPayload = (state: RootState): Eater =>
  state.profile?.bioPayload
export const selectSavedRestaurantIds = (state: RootState): string[] =>
  state.profile.savedRestaurantIds
export const selectEaterDishReviews = (state: RootState): DiningReview[] =>
  state.profile.eaterDishReviews

export const {
  setBioPayload,
  setIsProfileEdit,
  setIsValidData,
  setIsUpdateData,
  setIsEditCancelledData,
  setCurrentTab,
  setOpen,
  setEater,
  setRestaurantReviews,
  setEaterDishReviews,
  setMessage,
  setSeverity,
} = profileSlice.actions

export default profileSlice.reducer
