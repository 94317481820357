import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import {
  IconAdjustments,
  IconChevronLeft,
  IconMessageCircle,
  IconPointFilled,
} from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Filter from '../../../../components/filter/Filter'
import RoundedButton from '../../../../components/ui/RoundedButton'
import { ROUTES } from '../../../../helpers/routes-helper'
import { useAppSelector } from '../../../../store'
import { selectGroup } from '../../../../store/socialSlice'
import {
  IFilterResponseItem,
  IFilterTabList,
  RestaurantResponse,
} from '../../../../types'
import {
  GroupHeaderCustomButton,
  GroupHeaderRightContainer,
  GroupHeaderRoundedButton,
  GroupHeaderTitle,
  GroupSubTitle,
} from '../styled/GroupHeader.styled'
import { price } from '../../../../helpers/static-constants'

interface GroupHeaderProps {
  open: boolean
  openChat: React.Dispatch<React.SetStateAction<boolean>>
  headerTitle: string
  badgeContent: number
  onFilter: (item: IFilterResponseItem) => void
  restaurants: RestaurantResponse[]
}

const GroupHeader: React.FC<GroupHeaderProps> = ({
  open,
  openChat,
  headerTitle,
  badgeContent,
  onFilter,
  restaurants,
}) => {
  const navigate = useNavigate()
  const group = useAppSelector(selectGroup)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [filterTabList, setFilterTabList] = useState<IFilterTabList[]>([])
  const [filterModalOpen, setFilterModalOpen] = React.useState(false)

  const onFilterItems = (): IFilterTabList[] => {
    const filterItems = [
      {
        key: 1,
        name: 'Distance',
        items: [
          { key: 1, name: '0 mi', value: '0' },
          { key: 2, name: '5 mi', value: '5' },
          { key: 3, name: '10 mi', value: '10' },
          { key: 4, name: '15 mi', value: '15' },
          { key: 5, name: '20 mi', value: '20' },
          { key: 6, name: '25 mi', value: '25' },
          { key: 7, name: '30 mi', value: '30' },
        ],
        rangeStep: 5,
        filterType: 'range',
      },
    ].filter(Boolean) as IFilterTabList[]

    if (restaurants && restaurants.length !== 0) {
      const allDietaryPreferences = restaurants.flatMap(
        (item) => item.restaurantDTO.dietary,
      )
      const uniqueDietaryPreferences = Array.from(
        new Set(
          allDietaryPreferences.filter(
            (preference): preference is string => preference !== undefined,
          ),
        ),
      )

      const cuisineList: string[] = restaurants
        .map((item) => item.restaurantDTO.cuisine)
        .filter((cuisine): cuisine is string => cuisine !== undefined)

      const priceRanges = restaurants.map(
        (dish) => dish.restaurantDTO.priceRange,
      )
      const uniquePriceRanges = Array.from(new Set(priceRanges))

      if (uniquePriceRanges.length > 1) {
        filterItems.push({
          key: 2,
          name: 'Price',

          items: Array.from({ length: 4 }).map((_, index) => {
            return {
              key: index + 1,
              name: price[index],
              value: `${index + 1}`,
            }
          }),
          rangeStep: 1,
          filterType: 'range',
          priceType: 'dollar',
        })
      }
      if (uniqueDietaryPreferences.length !== 0) {
        filterItems.push({
          key: 3,
          name: 'Dietary',
          items: uniqueDietaryPreferences.map((item, index) => {
            return { key: index + 1, name: item, value: item }
          }),
          filterType: 'checkbox',
        })
      }
      if (cuisineList.length !== 0) {
        filterItems.push({
          key: 4,
          name: 'Cuisine',
          items: cuisineList.map((item, index) => {
            return { key: index + 1, name: item, value: item }
          }),
          filterType: 'checkbox',
        })
      }
    }

    return filterItems
  }

  useEffect(() => {
    if (restaurants && restaurants.length) {
      const filter = onFilterItems()
      setFilterTabList(filter)
    }
  }, [restaurants])

  const SelectedItemCount = (): JSX.Element => {
    const theme = useTheme()
    return (
      <Typography
        color="white"
        component="p"
        sx={{
          width: badgeContent > 99 ? '25px' : '17px',
          height: badgeContent > 99 ? '18px' : '17px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        variant="caption"
        bgcolor={theme.palette.secondary.main}
      >
        {badgeContent}
      </Typography>
    )
  }
  return (
    <>
      <Box
        pt={{ xs: '16px', md: '46px' }}
        overflow={'auto'}
        className="hide-scrollbar"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '12px',
          borderBottom: { md: `1px solid ${theme.palette.grey[200]}` },
          pb: { md: '16px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <GroupHeaderRightContainer>
            <GroupHeaderRoundedButton
              diameter={32}
              borderColor="transparent"
              onClick={() => navigate(ROUTES.SOCIAL)}
            >
              <IconChevronLeft
                color={theme.palette.grey[800]}
                size={18}
                stroke={2}
              />
            </GroupHeaderRoundedButton>
            <Box>
              <GroupHeaderTitle
                noWrap
                maxWidth={{ xs: '160px', md: '350px' }}
                component="h3"
                variant="h3"
              >
                {headerTitle}
              </GroupHeaderTitle>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <GroupSubTitle
                  noWrap
                  component="p"
                  color={theme.palette.grey[600]}
                  variant="subtitle1"
                >
                  {group.searchTerm}
                </GroupSubTitle>
                <IconPointFilled
                  color={theme.palette.grey[300]}
                  size={12}
                  style={{
                    margin: '0px 6px',
                  }}
                />
                <GroupSubTitle
                  noWrap
                  component="p"
                  color={theme.palette.grey[600]}
                  variant="subtitle1"
                >
                  {group.location}
                </GroupSubTitle>
              </Box>
            </Box>
          </GroupHeaderRightContainer>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'stretch',
            gap: '12px',
          }}
        >
          <>
            {isSmallScreen ? (
              <RoundedButton
                component="p"
                borderColor={theme.palette.grey[800]}
                diameter={37}
                onClick={() => setFilterModalOpen(true)}
              >
                <IconAdjustments
                  size={18}
                  color={theme.palette.grey[800]}
                  stroke={2}
                />
              </RoundedButton>
            ) : (
              <>
                <Box>
                  <Filter
                    onFilterData={onFilter}
                    tabList={filterTabList}
                    rowData={restaurants}
                    isFilterModalOpen={filterModalOpen}
                    setFilterModalOpen={setFilterModalOpen}
                  />
                </Box>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  sx={{
                    borderRadius: '10px',
                    textTransform: 'none',
                    px: '24px',
                    minWidth: '100px',
                  }}
                  startIcon={<IconAdjustments size={18} stroke={2} />}
                  onClick={() => setFilterModalOpen(true)}
                >
                  Filter
                </Button>
                {!open && (
                  <GroupHeaderCustomButton
                    title="Messages"
                    textVariant="subtitle1"
                    onClick={() => openChat(true)}
                    icon={
                      <>
                        {badgeContent > 0 && <SelectedItemCount />}
                        <IconMessageCircle size={18} stroke={2} />
                      </>
                    }
                  />
                )}
              </>
            )}
          </>
        </Box>
      </Box>
      {isSmallScreen && (
        <Box mt="12px">
          <Filter
            onFilterData={onFilter}
            tabList={filterTabList}
            rowData={restaurants}
            isFilterModalOpen={filterModalOpen}
            setFilterModalOpen={setFilterModalOpen}
          />
        </Box>
      )}
      {isSmallScreen && !open && (
        <GroupHeaderCustomButton
          title="Messages"
          textVariant="subtitle1"
          onClick={() => openChat(true)}
          icon={
            <>
              {badgeContent > 0 && <SelectedItemCount />}
              <IconMessageCircle size={18} stroke={2} />
            </>
          }
        />
      )}
    </>
  )
}

export default GroupHeader
