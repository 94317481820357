import { useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ImgIcon from '../../../../components/ui/ImgIcon'
import { Member } from '../../../../types/groupTypes'
import {
  StoryBoardAvatar,
  StoryBoardAvatarMainContainer,
  StoryBoardContainer,
  StoryBoardIcon,
  StoryBoardIconContainer,
  StoryBoardInnerContainer,
  StoryBoardTooltip,
  StoryBoardTooltipTypographyName,
  StoryBoardTooltipTypographyTitle,
} from '../styled/StoryBoard.styled'
import { selectEater } from '../../../../store/profileSlice'
import { useAppSelector } from '../../../../store'
import { useNavigate } from 'react-router'
import { ROUTES } from '../../../../helpers/routes-helper'

interface StoryBoardProps {
  memberList: Member[]
  onEditGroupModal: () => void
}

const StoryBoard: React.FC<StoryBoardProps> = ({
  memberList,
  onEditGroupModal,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [isAdmin, setIsAdmin] = useState(false)
  const userDetail = useAppSelector(selectEater)

  useEffect(() => {
    const admin = memberList.find((item) => item.owner)
    setIsAdmin(admin?.eaterId === userDetail?.eaterId)

    if (memberList.length > 0) {
      const member = memberList.find(
        (user) => user?.eaterId === userDetail?.eaterId,
      )
      if (!member) {
        navigate(ROUTES.HOME)
      }
    }
  }, [memberList])

  return (
    <StoryBoardContainer className="hide-scrollbar">
      <StoryBoardInnerContainer>
        <StoryBoardInnerContainer className="order-1 tab-order-2">
          {memberList.map((member, index) => {
            const borderColor: string = member.owner
              ? theme.palette.warning[400].toString()
              : theme.palette.grey[300].toString()

            const avatarContent = (
              <StoryBoardAvatarMainContainer
                borderColor={borderColor}
                key={index}
              >
                <StoryBoardAvatar
                  key={index}
                  alt={member.name}
                  src={
                    member.imageUrl ??
                    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmJUeQCIV5gK-gudX5l3OIhRcmgnbtGDhExw&s'
                  }
                />
                {member.owner && (
                  <ImgIcon
                    icon="/new/images/icons/crown.svg"
                    alt="group-admin"
                    imgSize={14}
                    wrapperStyle={{
                      position: 'absolute',
                      backgroundColor: `rgba(0, 0, 0, 0.5)`,
                      height: '20px',
                      width: '20px',
                      top: '7px',
                      right: '-13px',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                )}
              </StoryBoardAvatarMainContainer>
            )

            return (
              <StoryBoardTooltip
                key={index}
                title={
                  <div>
                    <StoryBoardTooltipTypographyTitle variant="subtitle2">
                      {member.owner ? 'Admin' : 'Member'}
                    </StoryBoardTooltipTypographyTitle>
                    <StoryBoardTooltipTypographyName variant="subtitle2">
                      {member.name}
                    </StoryBoardTooltipTypographyName>
                  </div>
                }
                arrow
              >
                {avatarContent}
              </StoryBoardTooltip>
            )
          })}
        </StoryBoardInnerContainer>
        {isAdmin && (
          <StoryBoardIconContainer
            onClick={onEditGroupModal}
            className="order-2 tab-order-1"
          >
            <StoryBoardIcon stroke={1.25} />
          </StoryBoardIconContainer>
        )}
      </StoryBoardInnerContainer>
    </StoryBoardContainer>
  )
}

export default StoryBoard
