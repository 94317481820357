import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  useTheme,
} from '@mui/material'
import { IconHourglassHigh, IconLogout } from '@tabler/icons-react'
import React from 'react'
import { useAuthHelper } from '../../hooks/useAuthHelper'
import useRudderStackAnalytics from '../../useRudderAnalytics'

const RestaurantPending: React.FC = () => {
  const { logoutAuthUser } = useAuthHelper()
  const analytics = useRudderStackAnalytics()
  const theme = useTheme()
  const signOut = (): void => {
    analytics?.identify('', { isLoggedIn: false })
    analytics?.reset()
    logoutAuthUser()
  }

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiPaper-root': {
          width: '500px',
          borderRadius: '20px',
        },
      }}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '50px',
        }}
      >
        <div
          style={{
            backgroundColor: theme.palette.grey[300],
            height: '90px',
            width: '90px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <IconHourglassHigh
            stroke={2}
            width={'50px'}
            height={'50px'}
            color={theme.palette.grey[600]}
          />
        </div>
        <Typography variant="body1">Onboarding Request in Progress</Typography>
        <Typography
          variant="body2"
          sx={{
            textAlign: 'center',
            fontWeight: 500,
            marginTop: '10px',
            marginBottom: '40px',
          }}
        >
          {` Your request for onboarding is still pending. We'll notify you when it
          is done`}
        </Typography>
        <Button
          onClick={signOut}
          style={{
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: '10px',
            color: theme.palette.primary.main,
          }}
          sx={{
            px: '10px',
            py: '5px',
            fontSize: '14px',
            fontWeight: 500,
            textTransform: 'none',
          }}
        >
          <IconLogout
            stroke={2}
            style={{ marginRight: '10px', color: theme.palette.primary.main }}
          />
          Sign Out
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default RestaurantPending
