import { styled } from '@mui/material'

export const ErrorMainContainer = styled('div')(() => {
  return {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 214px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  }
})
