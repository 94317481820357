import {
  Autocomplete,
  Box,
  Paper,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { IconChevronDown } from '@tabler/icons-react'
import * as React from 'react'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  selectLocateRestaurant,
  selectWebsite,
  setLocateRestaurant,
  setRestaurant,
  setWebsiteUrl,
} from '../../../store/onboardSlice'
import {
  fetchOnboardRestaurants,
  selectIsLoading,
  selectOnboardRestaurants,
} from '../../../store/restaurantSlice'
import { OnboardRestaurantMethods, Restaurant } from '../../../types'
import useRudderStackAnalytics from '../../../useRudderAnalytics'
import { debugLog } from '../../../utils/log-helper'
import OnboardTitleTypography from './OnboardTitleTypography'
import {
  OnboardLocateYourRestaurantContainer,
  OnboardLocateYourRestaurantInputLabel,
} from './styled/OnboardLocateYourRestaurant.styled'
import { OnboardRequestTextField } from './styled/OnboardRequest.styled'

interface OnboardRestaurantProps {
  title?: string
  subTitle?: string
  setNextButtonDisable: (boolean) => void
}

const OnboardLocateYourRestaurant = React.forwardRef<
  OnboardRestaurantMethods,
  OnboardRestaurantProps
>(function OnboardRestaurant(
  { title, subTitle, setNextButtonDisable },
  ref,
): JSX.Element {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const analytics = useRudderStackAnalytics()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const restaurants = useAppSelector(selectOnboardRestaurants)
  const locateRestaurant = useAppSelector(selectLocateRestaurant)
  const selectedWebsite = useAppSelector(selectWebsite)
  const isLoadingRestaurant = useAppSelector(selectIsLoading)

  const [website, setWebsite] = React.useState(selectedWebsite ?? '')
  const [restaurantAC, setRestaurantAC] = React.useState<
    Restaurant | undefined
  >(locateRestaurant)

  React.useEffect(() => {
    if (restaurantAC !== undefined && website.length > 0)
      setNextButtonDisable(false)
    else setNextButtonDisable(true)
  }, [restaurantAC, website])

  React.useEffect(() => {
    analytics?.page(
      'FindRestaurant',
      'FindRestaurant Viewed',
      {
        path: '/find-restaurant',
        referrer: 'https://www.resactly.com/choose-your-role',
        search: '',
        title: 'Resactly Onboarding - Find Restaurant',
        url: 'https://www.resactly.com/find-restaurant',
      },
      () => {
        debugLog('page call')
      },
    )
  }, [analytics])

  React.useEffect(() => {
    dispatch(fetchOnboardRestaurants()).catch(() => {
      debugLog('error in fetching restaurants')
    })
  }, [dispatch])

  const getPayload = (): void => {
    if (website === '' || restaurantAC === undefined) {
      dispatch(setRestaurant(undefined))
    } else {
      let cleansed = website.replace('https://', '')
      cleansed = cleansed.replace('http://', '')
      if (cleansed.indexOf('/') > 0) {
        cleansed = cleansed.substring(0, cleansed.indexOf('/'))
      }

      dispatch(setWebsiteUrl(website))
      dispatch(setLocateRestaurant(restaurantAC))
      dispatch(
        setRestaurant(
          restaurants.find(
            (restaurant) =>
              restaurant.website?.includes(cleansed) &&
              restaurant.name === restaurantAC.name,
          ),
        ),
      )
    }
  }

  React.useImperativeHandle(ref, () => ({ getPayload }))

  return (
    <div>
      <OnboardTitleTypography title={title ?? ''} subTitle={subTitle ?? ''} />

      <OnboardLocateYourRestaurantContainer>
        <div>
          <OnboardLocateYourRestaurantInputLabel htmlFor="restaurant">
            Select your Restaurant
          </OnboardLocateYourRestaurantInputLabel>
          <Autocomplete
            size="small"
            fullWidth
            autoHighlight
            PaperComponent={(props) => (
              <Paper
                {...props}
                sx={{
                  fontSize: '14px !important',
                  fontWeight: `400 !important`,
                }}
              />
            )}
            id="restaurant"
            loading={isLoadingRestaurant}
            sx={{
              width: isSmallScreen ? 'calc(100vw - 32px)' : '400px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: theme.palette.secondary[300],
                  borderRadius: '8px',
                },
                '&:hover fieldset': {
                  borderColor: theme.palette.secondary[300],
                },
                '&.Mui-focused fieldset': {
                  borderColor: theme.palette.secondary[300],
                },
              },
              '& .MuiPopper-root': {
                borderColor: 'red',
              },
            }}
            value={restaurantAC}
            onChange={(event: any, newRestaurant: Restaurant | null) => {
              setRestaurantAC(
                newRestaurant !== null ? newRestaurant : undefined,
              )
            }}
            options={restaurants}
            popupIcon={
              <IconChevronDown
                stroke={2}
                size={22}
                style={{ color: theme.palette.grey[300] }}
              />
            }
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
                key={`${option.restaurantID}-${option.name ?? ''}-${
                  option.website ?? ''
                }`}
              >
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select option"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: theme.palette.background.paper,
                    fontSize: '14px',
                    fontWeight: 500,
                  },
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />
            )}
          />
        </div>
        <OnboardLocateYourRestaurantContainer>
          <OnboardLocateYourRestaurantInputLabel>
            Website Link
          </OnboardLocateYourRestaurantInputLabel>
          <OnboardRequestTextField
            id="website"
            placeholder="Website"
            value={website}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setWebsite(event.target.value)
            }}
          />
        </OnboardLocateYourRestaurantContainer>
      </OnboardLocateYourRestaurantContainer>
    </div>
  )
})

export default OnboardLocateYourRestaurant
