import { Menu, MenuItem, useTheme } from '@mui/material'
import { IconShare } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'
import RoundedButton from '../../../../components/ui/RoundedButton'
import { useLocation } from 'react-router'

interface RestaurantShareProps {
  imgUrl: string
  description: string
  title: string
  cuisine: string
  city: string
}

const RestaurantShare: React.FC<RestaurantShareProps> = ({
  imgUrl,
  description,
  title,
  cuisine,
  city,
}) => {
  const theme = useTheme()
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [pageTitle, setPageTitle] = useState('')
  const open = Boolean(anchorEl)
  const shareUrl = `https://www.resactly.com${location.pathname}`
  const image = imgUrl

  useEffect(() => {
    setPageTitle(`${title} - Authentic ${cuisine} Dining Experience in ${city}`)
  }, [title, cuisine, city])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={shareUrl} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
      <RoundedButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        borderColor={theme.palette.grey[300]}
        diameter={37}
      >
        <IconShare size={18} color={theme.palette.grey[800]} stroke={2} />
      </RoundedButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          '& .MuiPaper-root': {
            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px;',
            borderRadius: '10px',
          },
          p: 1,
        }}
      >
        <MenuItem className="flex-center">
          <FacebookShareButton
            url={shareUrl}
            title={title}
            className="flex-center"
          >
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
        </MenuItem>
        <MenuItem>
          <TwitterShareButton
            className="flex-center"
            url={shareUrl}
            title={title}
          >
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
        </MenuItem>
      </Menu>
    </>
  )
}

export default RestaurantShare
