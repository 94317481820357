export const PRIVACY_POLICY_URL =
  'https://www.iubenda.com/privacy-policy/90550491'
export const COOKIE_POLICY_URL =
  'https://www.iubenda.com/privacy-policy/90550491/cookie-policy'
export const TERMS_AND_CONDITION_URL =
  'https://www.iubenda.com/terms-and-conditions/90550491'

export const FACEBOOK_PAGE_URL =
  'https://www.facebook.com/people/Resactly/100087837874001/'
export const INSTAGRAM_PAGE_URL = 'https://www.instagram.com/resactly/'

export const COPY_RIGHT_TEXT = `&copy; ${new Date().getFullYear()} OKAO Inc. All right reserved.`

export const DEFAULT_CARD_IMAGE = '/images/restaurant-logo.jpg'

export const WEB_NAV_HEIGHT = 91
export const MOBILE_NAV_HEIGHT = 72
export const MOBILE_SEARCH_HEIGHT = 48

export const price = ['$', '$$', '$$$', '$$$$']

export const headingName = [
  {
    title: 'Wallet Friendly Restaurants',
    key: 'WALLET',
  },
  {
    title: 'Local Flavors To Explore',
    key: 'LOCAL',
  },
  {
    title: 'Top Rated Restaurants',
    key: 'TOPRATED',
  },
  {
    title: 'Must Try Cuisine Hotspots',
    key: 'MUSTTRY',
  },
]
