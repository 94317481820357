import { useAuth0 } from '@auth0/auth0-react'
import {
  AppBar,
  Box,
  IconButton,
  LinearProgress,
  Link as MuiLink,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { IconMenu2, IconSearch } from '@tabler/icons-react'
import * as React from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { navBarRoutes, ROUTES } from '../../helpers/routes-helper'
import { useAppDispatch } from '../../store'
import { setMobileSearchOpen } from '../../store/searchSlice'
import LoginButton from './LoginButton'
import CustomDrawer from './MobileDrawer'
import Range from './Range'
import GlobalSearch from './GlobalSearch'
import {
  MOBILE_NAV_HEIGHT,
  MOBILE_SEARCH_HEIGHT,
  WEB_NAV_HEIGHT,
} from '../../helpers/static-constants'

const NavBar: React.FC = () => {
  const { isAuthenticated } = useAuth0()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const mappedRoutes = navBarRoutes.filter((item) => item.isPublic)
  const [routes, setRoutes] = React.useState(mappedRoutes)
  const [isSearchOpen, setIsSearchOpen] = React.useState(false)

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const toggleDrawer = (newOpen: boolean) => (): void => {
    setOpen(newOpen)
  }

  React.useEffect(() => {
    const mappedRoutes = navBarRoutes.filter((item) => {
      return item.isPublic || (!item.isPublic && isAuthenticated)
    })
    setRoutes(mappedRoutes)
  }, [isAuthenticated])
  React.useEffect(() => {
    dispatch(setMobileSearchOpen(isSearchOpen))
  }, [isSearchOpen])

  const onSearchToggle = (): void => {
    setIsSearchOpen((prevState) => !prevState)
  }

  const navList = [
    {
      name: 'Home',
      path: '/',
    },
    {
      name: 'About',
      path: '/about',
    },
    ...(isAuthenticated
      ? [
          {
            name: 'Social',
            path: '/social',
          },
          {
            name: 'Profile',
            path: '/profile',
          },
        ]
      : []),
  ]

  return (
    <>
      <Toolbar
        sx={{
          minHeight: {
            xs: isSearchOpen
              ? `${MOBILE_SEARCH_HEIGHT + MOBILE_NAV_HEIGHT}px`
              : `${MOBILE_NAV_HEIGHT}px`,
            md: `${WEB_NAV_HEIGHT}px`,
          },
        }}
      >
        <AppBar
          position="fixed"
          sx={{
            padding: {
              xs: isSearchOpen ? '16px 0px 0px 0px' : '16px',
              md: '0px',
            },
            minHeight: {
              xs: isSearchOpen
                ? `${MOBILE_SEARCH_HEIGHT + MOBILE_NAV_HEIGHT}px`
                : `${MOBILE_NAV_HEIGHT}px`,
              md: `${WEB_NAV_HEIGHT}px`,
            },
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            boxShadow: 'none',
          }}
        >
          {!isSmallScreen ? (
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <MuiLink component={NavLink} to={ROUTES.HOME}>
                  <img
                    srcSet={`${'/logo.svg'}`}
                    src={`${'/logo.svg'}`}
                    style={{
                      height: '100%',
                      width: '120px',
                      marginRight: '48px',
                      cursor: 'pointer',
                      padding: '10px 0px',
                      marginLeft: '40px',
                    }}
                    loading="lazy"
                    alt="Resactly-Logo"
                  />
                </MuiLink>
                {routes.map((item, index) => (
                  <MuiLink
                    key={index}
                    component={NavLink}
                    to={item?.path}
                    variant="subtitle1"
                    underline="none"
                    sx={{
                      '&:hover': {
                        color: theme.palette.secondary.main,
                      },
                      paddingX: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      '&.active': {
                        color: theme.palette.secondary.main,
                        fontWeight: 600,
                        backgroundColor: theme.palette.secondary[50],
                      },
                    }}
                  >
                    {item.name}
                  </MuiLink>
                ))}
              </Box>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <GlobalSearch />
                <Range />
                <LoginButton />
              </Box>
            </Box>
          ) : (
            <Box>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                px={isSearchOpen ? '16px' : '0px'}
                zIndex={open ? 6000 : 1000}
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <img
                    srcSet={`${'/logo.svg'}?w=20&h=20&fit=crop&auto=format&dpr=2 2x`}
                    src={`${'/logo.svg'}?w=30&h=30&fit=crop&auto=format`}
                    style={{
                      height: '40px',
                      marginRight: '16px',
                      cursor: 'pointer',
                    }}
                    loading="lazy"
                    onClick={() => navigate(ROUTES.HOME)}
                    alt="Resactly-Logo"
                  />
                  <Typography
                    color={theme.palette.grey[800]}
                    fontWeight={700}
                    fontSize="20px"
                    lineHeight="28px"
                  >
                    {navList.find((route) => route.path === pathname)?.name}
                  </Typography>
                </Box>

                <Box display={'flex'}>
                  <Box
                    sx={{
                      border: '1px solid',
                      borderRadius: '50%',
                      borderColor: theme.palette.grey[300],
                      marginX: '2px',
                    }}
                  >
                    <IconButton
                      size="medium"
                      sx={{
                        color: theme.palette.primary.light,
                      }}
                      onClick={onSearchToggle}
                    >
                      <IconSearch size={16} />
                    </IconButton>
                  </Box>
                  <Box
                    sx={{
                      border: '1px solid',
                      borderColor: theme.palette.grey[300],
                      borderRadius: '50%',
                      marginX: '2px',
                    }}
                  >
                    <IconButton
                      size="medium"
                      sx={{ color: theme.palette.primary.light }}
                      onClick={toggleDrawer(true)}
                    >
                      <IconMenu2 size={16} />
                    </IconButton>
                  </Box>
                </Box>
              </Box>

              {isSearchOpen && (
                <Box
                  display={'flex'}
                  justifyContent={'flex-end'}
                  alignItems={'stretch'}
                  borderTop={`1px solid ${theme.palette.grey[300]}`}
                  height={`${MOBILE_SEARCH_HEIGHT}px`}
                  mt="15px"
                  zIndex={10}
                >
                  <GlobalSearch />
                  <Range />
                </Box>
              )}
            </Box>
          )}
          <LinearProgress sx={{ position: 'absolute', bottom: '0px' }} />
        </AppBar>
      </Toolbar>
      <CustomDrawer open={open} toggleDrawer={setOpen} routeList={navList} />
    </>
  )
}
export default NavBar
