import { Box, Button, Container, Typography } from '@mui/material'
import styled from 'styled-components'
import RoundedButton from '../../../../components/ui/RoundedButton'
import React from 'react'

interface RestaurantsContainerBoxProps {
  isMobileSearch: boolean
  mobileSearchHeight?: number
  mobileNavHeight?: number
  webNavHeight?: number
}

export const RestaurantsMainContainer = styled(Container).attrs(() => ({
  component: 'main',
  sx: {
    flex: 1,
  },
}))(() => ({}))

export const RestaurantsContainer = styled(
  ({ isMobileSearch, ...rest }: RestaurantsContainerBoxProps & any) => (
    <Box {...rest} />
  ),
).attrs<RestaurantsContainerBoxProps>(
  ({ isMobileSearch, mobileSearchHeight, mobileNavHeight, webNavHeight }) => {
    return {
      sx: {
        display: 'flex',
        flexDirection: 'column',
        height: {
          xs: `calc(100vh - ${
            isMobileSearch
              ? `${
                  (mobileSearchHeight as number) +
                  (mobileNavHeight as number) +
                  2
                }px`
              : `${(mobileNavHeight as number) + 2}px`
          })`,
          md: `calc(100vh - ${webNavHeight as number}px)`,
        },
      },
    }
  },
)<RestaurantsContainerBoxProps>(() => ({}))

export const RestaurantsHeaderContainer = styled(Box).attrs(() => ({
  sx: {
    mb: { xs: '8px', sm: '24px', md: '30px' },
  },
}))(() => ({}))

export const RestaurantsHeaderInnerContainer = styled(Box).attrs(() => ({
  pt: { xs: '16px', md: '46px' },
  pb: '10px',
  overflow: 'auto',
  className: 'hide-scrollbar',
  sx: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '12px',
  },
}))(() => ({}))

export const RestaurantsHeaderTitleContainer = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
}))(() => ({}))

export const RestaurantsHeaderBackRoundedButton = styled(RoundedButton).attrs(
  () => ({
    sx: {
      boxShadow: '0px 0px 4px 0px #33333340',
      marginRight: '12px',
      marginLeft: '3px',
    },
    diameter: 32,
    borderColor: 'transparent',
  }),
)(() => ({}))

export const RestaurantsHeaderTitleTypography = styled(Typography).attrs(
  () => ({
    component: 'h3',
    variant: 'h3',
  }),
)(() => ({}))

export const RestaurantsHeaderFilterButtonContainer = styled(Box).attrs(() => ({
  sx: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'stretch',
    gap: '12px',
  },
}))(() => ({}))

export const RestaurantsHeaderFilterButton = styled(Button).attrs(() => ({
  variant: 'outlined',
  color: 'primary',
  size: 'large',
  sx: {
    borderRadius: '10px',
    textTransform: 'none',
  },
}))(() => ({}))

export const RestaurantsContentContainer = styled(Box).attrs(() => ({
  sx: {
    flexGrow: 1,
    overflowY: 'auto',
  },
}))(() => ({}))
