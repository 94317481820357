import React, { FC } from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import {
  InputBaseProps,
  InputLabel,
  SxProps,
  Theme,
  styled,
} from '@mui/material'

interface InputComponentProps extends Omit<TextFieldProps, ''> {
  id: string
  label?: string
  placeholder?: string
  value: string
  setValue: (value: string) => void
  sx?: SxProps<Theme>
  disable?: boolean
  type?: 'outline' | 'fill'
  inputProps?: InputBaseProps['inputProps']
}

const InputComponent: FC<InputComponentProps> = ({
  id,
  label,
  placeholder,
  value,
  setValue,
  sx,
  disable = false,
  type = 'outline',
  ...rest
}) => {
  return (
    <>
      {label && <ResactlyInputLabel htmlFor={id}>{label}</ResactlyInputLabel>}
      <ResactlyTextField
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        sx={sx}
        type={type}
        {...rest}
      />
    </>
  )
}

export default InputComponent

interface StyledProps {
  sx?: SxProps<Theme>
  type?: 'outline' | 'fill'
}

export const ResactlyTextField = styled(
  ({ sx, type, ...rest }: StyledProps & TextFieldProps) => (
    <TextField
      variant="outlined"
      size="small"
      fullWidth
      {...rest}
      sx={{ ...sx }}
    />
  ),
)<StyledProps>(({ theme, type }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    fontSize: '14px',
    fontWeight: 400,
    color:
      type === 'outline' ? theme.palette.grey[800] : theme.palette.grey[400],
    backgroundColor: type === 'outline' ? 'none' : 'rgba(255, 255, 255, 0.15)',
    '& fieldset': {
      borderRadius: '8px',
      borderColor: theme.palette.secondary[300],
      borderWidth: type === 'outline' ? '1px' : '0px',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.secondary[300],
      borderWidth: type === 'outline' ? '1px' : '0px',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.secondary[300],
      borderWidth: type === 'outline' ? '1px' : '0px',
    },
  },
}))

export const ResactlyInputLabel = styled(
  ({ ...rest }: StyledProps & React.ComponentProps<typeof InputLabel>) => (
    <InputLabel shrink sx={{ fontWeight: 500, fontSize: '14px' }} {...rest} />
  ),
)<StyledProps>(({ theme }) => ({
  color: theme.palette.background.paper,
}))
