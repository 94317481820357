import { Typography, useTheme } from '@mui/material'
import Divider from '@mui/material/Divider'
import { IconX } from '@tabler/icons-react'
import React, { useEffect, useRef } from 'react'
import Talk from 'talkjs'
import ImgIcon from '../../../../components/ui/ImgIcon'
import { Group } from '../../../../types/groupTypes'
import { ChatBoxContainer, DrawerHeader } from '../styled/ChatDrawer.styled'

interface ChatDrawerProps {
  toggleDrawer: React.Dispatch<React.SetStateAction<boolean>>
  group: Group
}

const ChatDrawer: React.FC<ChatDrawerProps> = ({ toggleDrawer, group }) => {
  const theme = useTheme()
  const chatBoxEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const currentMember = group.members?.find((member) => member.me)

    if (currentMember !== undefined) {
      const currentUser = new Talk.User({
        id: currentMember.eaterId ?? '1',
        name: currentMember.name,
        email: currentMember.email,
        welcomeMessage: 'Hello!',
        photoUrl: '/images/group1.jpg',
        role: 'default',
      })

      const session = new Talk.Session({
        appId: process.env.REACT_APP_TALK_JS ?? 'UyKCenXj',
        me: currentUser,
      })

      const conversation = session.getOrCreateConversation(
        group?.chatGroupId ?? '',
      )
      conversation.setParticipant(currentUser)
      conversation.setAttributes({
        subject: `${group?.name as string} Dining Chat`,
      })

      const otherMembers = group?.members?.filter(
        (member) => member.me === false,
      )
      otherMembers?.forEach((other, i) => {
        const otherUser = new Talk.User({
          id: other.eaterId ?? i,
          name: other.name,
          email: other.email,
          photoUrl: `/images/group${(i + 1) % 4}.jpg`,
          welcomeMessage: 'Hello!',
          role: 'default',
        })

        conversation.setParticipant(otherUser)
      })

      const chatBox = session.createChatbox()
      chatBox.select(conversation)
      if (chatBoxEl.current) {
        void chatBox.mount(chatBoxEl.current)
      }

      return () => {
        if (session) {
          session.destroy()
        }
      }
    }
  }, [])

  return (
    <>
      <DrawerHeader>
        <Typography variant="body2">Group Chat</Typography>
        <ImgIcon
          iconElement={<IconX stroke={2} height={18} width={18} />}
          alt="close-chat-drawer"
          wrapperStyle={{
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.grey[300]}`,
            height: '32px',
            width: '32px',
          }}
          onClick={() => toggleDrawer(false)}
        />
      </DrawerHeader>
      <Divider />
      <div>
        <ChatBoxContainer ref={chatBoxEl} />
      </div>
    </>
  )
}

export default ChatDrawer
