import { debugLog } from '../utils/log-helper'

export interface Location {
  longitude: number
  latitude: number
}

// New york city coordinates
export const defaultLocationCoords: Location = {
  latitude: 40.73061,
  longitude: -73.935242,
}

export const getLocation = async (): Promise<Location | undefined> => {
  const newYorkCityCoords = defaultLocationCoords

  if (navigator.geolocation !== null && navigator.geolocation !== undefined) {
    try {
      const position = await new Promise<GeolocationPosition>(
        (resolve, reject) => {
          const timeoutId = setTimeout(() => {
            reject(new Error('Timeout'))
          }, 1000)

          navigator.geolocation.getCurrentPosition(
            (pos) => {
              clearTimeout(timeoutId)
              resolve(pos)
            },
            (err) => {
              clearTimeout(timeoutId)
              reject(err)
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            },
          )
        },
      )

      const latitude = position.coords.latitude
      const longitude = position.coords.longitude

      return { latitude, longitude }
    } catch (error) {
      debugLog("Error while getting user's location:", error)
      return (await getGoogleMapsLocation()) ?? newYorkCityCoords
    }
  } else {
    debugLog('Geolocation is not supported by this browser.')
    return (await getGoogleMapsLocation()) ?? newYorkCityCoords
  }
}

export const getGoogleMapsLocation = async (): Promise<
  Location | undefined
> => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY ?? ''
  const response = await fetch(
    `https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`,
    {
      method: 'POST',
    },
  )

  if (response.ok) {
    const data = await response.json()
    const location = data.location
    return {
      latitude: location.lat,
      longitude: location.lng,
    }
  } else {
    debugLog('Error while getting location from Google Maps API')
    return undefined
  }
}

export const getLocationLocalStorage = (): Location => {
  const location = window.localStorage.getItem('location')
  return location ? JSON.parse(location) : defaultLocationCoords
}

export const getDistanceFromLatLonInKm = (
  location1: Location,
  location2: Location,
): any => {
  const lat1 = location1.latitude
  const lon1 = location1.longitude
  const lat2 = location2.latitude
  const lon2 = location2.longitude

  const R = 6371
  const dLat = deg2rad(lat2 - lat1)
  const dLon = deg2rad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c
  return distance
}

const deg2rad = (deg: number): any => {
  return deg * (Math.PI / 180)
}
