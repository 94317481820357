import { Skeleton } from '@mui/material'
import React from 'react'

const RestaurantCardSkeleton: React.FC = () => {
  return (
    <div style={{ width: '100%', minWidth: '276px' }}>
      <Skeleton
        variant="rounded"
        height={200}
        sx={{
          borderRadius: '20px',
        }}
      />
      <div
        style={{
          marginTop: '10px',
        }}
      >
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'60%'} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={'80%'} />
      </div>
    </div>
  )
}

export default RestaurantCardSkeleton
