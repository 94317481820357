import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux'
import authReducer from './authSlice'
import onboardingReducer from './onboardSlice'
import restaurantReducer from './restaurantSlice'
import profileReducer from './profileSlice'
import eaterReducer from './eaterSlice'
import landingReducer from './landingSlice'
import searchReducer from './searchSlice'
import socialReducer from './socialSlice'

const rootReducer = combineReducers({
  auth: authReducer,
  eater: eaterReducer,
  search: searchReducer,
  //   onboard: onboardReducer,
  //   social: socialReducer,
  restaurant: restaurantReducer,
  social: socialReducer,
  onboarding: onboardingReducer,
  profile: profileReducer,
  landing: landingReducer,
  //   menu: menuReducer,
  //   dashboard: dashboardReducer,
  //   reviews: reviewReducer,
  //   search: searchReducer,
  //   onboard: onboardReducer,
})
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const configureAppStore = (initialState = {}) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
  })
  return store
}
export const store = configureAppStore()
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof configureAppStore>

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
