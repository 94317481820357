import { Grid } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import EmptyState from '../../../../components/EmptyScreen'
import RestaurantCard from '../../../../components/restaurant/RestaurantCard'
import { ROUTES } from '../../../../helpers/routes-helper'
import { useAppDispatch, useAppSelector } from '../../../../store'
import {
  selectMessage,
  selectSeverity,
  selectShouldOpen,
  setOpen,
} from '../../../../store/restaurantSlice'
import { RestaurantAndPredictedRestaurant } from '../../../../types'
import { MuiAlert, MuiSnackbar } from '../../styled/global.styled'
import { DEFAULT_CARD_IMAGE } from '../../../../helpers/static-constants'

interface SearchResultRestaurantsProps {
  restaurantList: RestaurantAndPredictedRestaurant[]
}

const SearchResultRestaurants: React.FC<SearchResultRestaurantsProps> = ({
  restaurantList,
}) => {
  const navigate = useNavigate()
  const openServer = useAppSelector(selectShouldOpen)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const dispatch = useAppDispatch()

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }
  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, sm: 3, md: 4 }}
        sx={{
          my: { xs: '8px', sm: '16px', md: '20px' },
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {restaurantList.length > 0 &&
          restaurantList.map((card, index) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <div
                  key={index}
                  style={{
                    height: '280px',
                    width: '100%',
                  }}
                  className="restaurant-card-swiper-slide"
                  onClick={() =>
                    navigate(
                      `${ROUTES.RESTAURANT_DETAILS}/${card?.restaurantDTO?.alias}/details`,
                    )
                  }
                >
                  <RestaurantCard
                    id={card.restaurantDTO.restaurantID}
                    alias={card?.restaurantDTO?.alias ?? ''}
                    imgList={[
                      card?.restaurantDTO?.imageurl ??
                      card?.restaurantDTO?.imageurl !== ''
                        ? card?.restaurantDTO?.imageurl ?? DEFAULT_CARD_IMAGE
                        : DEFAULT_CARD_IMAGE,
                    ]}
                    title={card?.restaurantDTO?.name}
                    subTitle={[
                      card?.restaurantDTO?.address1,
                      card?.restaurantDTO?.city,
                      card?.restaurantDTO?.state,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                    distance={`${card?.restaurantDTO?.distance ?? ''}`}
                    tagChipData={[
                      {
                        percentage: `${card.value?.toLocaleString() ?? ''}`,
                        title: 'Match',
                      },
                    ]}
                  />
                </div>
              </Grid>
            )
          })}
        {restaurantList.length === 0 && (
          <EmptyState containerHeight={'600px'} />
        )}
      </Grid>
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </>
  )
}
export default SearchResultRestaurants
