import ElasticsearchAPIConnector from '@elastic/search-ui-elasticsearch-connector'
import React, { useRef, useState } from 'react'

import { SearchBox, SearchProvider, withSearch } from '@elastic/react-search-ui'
import '@elastic/react-search-ui-views/lib/styles/styles.css'
import { SearchResult } from '@elastic/search-ui'
import {
  OnboardSearchAutocompleteViewContainer,
  OnboardSearchAutocompleteViewInnerContainer,
  OnboardSearchContainer,
  OnboardSearchInputView,
  OnboardSearchInputViewIconSearch,
  OnboardSearchInputViewInputAdornment,
  OnboardSearchInputViewTextField,
  OnboardSearchResultSearchTermContainer,
  OnboardSearchResultSearchTermIconCornerDownLeft,
  OnboardSearchResultSearchTermTypographyNoItem,
  OnboardSearchResultSearchTermTypographyResult,
} from './styled/OnboardSearch.styled'

interface Props {
  indexKey: string
  setWord: (word: string) => void
  searchFieldName?: string
  searchWidth?: string | undefined
}
interface AutocompleteProps {
  autocompletedResults?: SearchResult[]
  searchTerm: string
  reset: () => void
}

const OnboardSearchBox: React.FC<Props> = ({
  indexKey,
  setWord,
  searchFieldName = 'value',
  searchWidth = undefined,
}: Props): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const connector = new ElasticsearchAPIConnector({
    cloud: {
      id: process.env.REACT_APP_ELASTIC_CLOUD_ID ?? '',
    },
    apiKey: process.env.REACT_APP_ELASTIC_API_KEY ?? '',
    index: process.env[indexKey] ?? '',
  })

  const config = {
    searchQuery: {
      search_fields: {
        [`${searchFieldName}.enum`]: {
          weight: 6,
        },
      },
      result_fields: {
        [searchFieldName]: {},
      },
    },
    autocompleteQuery: {
      results: {
        search_fields: {},
        result_fields: {
          [searchFieldName]: {},
        },
      },
    },
    apiConnector: connector,
  }

  const addExistingValue = (selection: any): void => {
    setWord(selection[searchFieldName].raw as string)
  }

  const onAddValue = (val: string, reset: () => void): void => {
    setWord(val)
    reset()
  }

  const AutoCompleteResults = ({
    autocompletedResults,
    searchTerm,
    reset,
  }: AutocompleteProps): JSX.Element => {
    return (
      <OnboardSearchContainer customWidth={searchWidth}>
        <SearchBox
          className="main-autocomplete-search-container"
          autocompleteResults={true}
          autocompleteSuggestions={true}
          debounceLength={1000}
          autocompleteMinimumCharacters={1}
          onSelectAutocomplete={(selection, options) => {
            addExistingValue(selection)
          }}
          autocompleteView={({
            autocompletedResults,
            getItemProps,
            getMenuProps,
          }) => {
            return (
              <OnboardSearchAutocompleteViewContainer
                {...getMenuProps({ className: 'autocomplete-menu' })}
              >
                {autocompletedResults.map((result, i) => {
                  const itemProps = getItemProps({
                    item: result,
                    index: i,
                    key: result.id.raw,
                  })
                  const { key, ...restItemProps } = itemProps
                  return (
                    <OnboardSearchAutocompleteViewInnerContainer
                      key={result.id.raw}
                      id="MuiInputBase-input-MuiOutlinedInput-input-check"
                      {...restItemProps}
                    >
                      {result?.[searchFieldName]?.raw}
                    </OnboardSearchAutocompleteViewInnerContainer>
                  )
                })}
              </OnboardSearchAutocompleteViewContainer>
            )
          }}
          inputView={({ getAutocomplete, getInputProps, getButtonProps }) => {
            const inputProps = getInputProps({
              placeholder: 'Search or add new',
              onFocus: () => setIsFocused(true),
              onBlur: () => setIsFocused(false),
            })

            return (
              <>
                <OnboardSearchInputView searchWidth={searchWidth}>
                  <OnboardSearchInputViewTextField
                    {...inputProps}
                    autoFocus={isFocused}
                    inputRef={inputRef}
                    InputProps={{
                      startAdornment: (
                        <OnboardSearchInputViewInputAdornment>
                          <OnboardSearchInputViewIconSearch />
                        </OnboardSearchInputViewInputAdornment>
                      ),
                      sx: {
                        padding: '0 10px',
                        fontSize: '14px',
                      },
                      style: { borderRadius: '10px' },
                    }}
                  />

                  {getAutocomplete()}
                </OnboardSearchInputView>
                <input
                  {...getButtonProps({
                    className: 'member-search',
                  })}
                  style={{
                    display: 'none',
                  }}
                />
              </>
            )
          }}
        />
        {searchTerm &&
          searchTerm.length > 0 &&
          autocompletedResults &&
          autocompletedResults.length === 0 && (
            <OnboardSearchResultSearchTermContainer
              customWidth={searchWidth}
              onClick={() => onAddValue(searchTerm, reset)}
            >
              <OnboardSearchResultSearchTermIconCornerDownLeft />
              <OnboardSearchResultSearchTermTypographyNoItem>
                No Item Found. Click Enter to Add &nbsp;
              </OnboardSearchResultSearchTermTypographyNoItem>
              <OnboardSearchResultSearchTermTypographyResult>
                {searchTerm}
              </OnboardSearchResultSearchTermTypographyResult>
            </OnboardSearchResultSearchTermContainer>
          )}
      </OnboardSearchContainer>
    )
  }

  const EnhancedAutoCompleteResults = withSearch(
    ({ autocompletedResults, searchTerm, reset }) => ({
      autocompletedResults,
      searchTerm,
      reset,
    }),
  )(AutoCompleteResults)

  return (
    <SearchProvider config={config}>
      <EnhancedAutoCompleteResults />
    </SearchProvider>
  )
}

export default React.memo(OnboardSearchBox)
