import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Grid, useMediaQuery, useTheme } from '@mui/material'
import { IconAdjustments } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import NoResultsFound from '../../../../components/EmptyScreen'
import Filter from '../../../../components/filter/Filter'
import RestaurantDishesCard from '../../../../components/restaurant/RestaurantDishesCard'
import RestaurantDrawer from '../../../../components/restaurant/RestaurantDrawer'
import RoundedButton from '../../../../components/ui/RoundedButton'
import { dietaryPreferences } from '../../../../helpers/onboard-helper'
import { useAppSelector } from '../../../../store'
import {
  selectMenuNameList,
  selectRestaurantDetail,
  selectRestaurantDishList,
} from '../../../../store/restaurantSlice'
import { IFilterResponseItem, IFilterTabList } from '../../../../types'
import { PredictedDish } from '../../../../types/menuTypes'
import {
  DishesTabInputComponent,
  DishesTabMainContainer,
  DishesTabMainGrid,
  DishesTabTypography,
} from '../styled/DishesTab.styled'

const DishesTab: React.FC = () => {
  const { isAuthenticated } = useAuth0()
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const restaurant = useAppSelector(selectRestaurantDetail)
  const menuNameList = useAppSelector(selectMenuNameList)
  const restaurantDishList = useAppSelector(selectRestaurantDishList)
  const [dishSearch, setDishSearch] = useState('')
  const [searchDish, setSearchDish] = useState<PredictedDish[]>([])
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [dishesData, setDishesData] = useState<Partial<PredictedDish>>({})
  const [filterTabList, setFilterTabList] = useState<IFilterTabList[]>([])
  const [filterModalOpen, setFilterModalOpen] = React.useState(false)
  const [loginFilterDish, setLoginFilterDish] = useState<PredictedDish[]>(
    restaurantDishList ?? [],
  )

  const menuItemList = menuNameList.map((item, index) => {
    return { key: index + 1, name: item, value: item }
  })

  const onFilterItems = (): IFilterTabList[] => {
    const filterItems = [
      menuItemList.length > 1
        ? {
            key: 1,
            name: 'Menu',
            items: menuItemList,
            filterType: 'checkbox',
          }
        : null,
      {
        key: 3,
        name: 'Dietary',
        items: dietaryPreferences.map((item, index) => {
          return { key: index + 1, name: item, value: item }
        }),
        filterType: 'checkbox',
      },
    ].filter(Boolean) as IFilterTabList[]

    if (restaurantDishList && restaurantDishList.length !== 0) {
      const prices = restaurantDishList
        .map((item) => item?.dish?.price)
        .filter((price) => typeof price === 'number')

      if (prices.length !== 0) {
        const highestPrice = Math.max(...prices)

        const priceStep = Math.ceil(highestPrice / 7)

        const data = Array.from({ length: 7 }, (v, i) => {
          const upperBound = (i + 1) * priceStep

          return {
            key: i + 1,
            name: `$${upperBound - 1}`,
            value: `${upperBound - 1}`,
          }
        })

        filterItems.push({
          key: 5,
          name: 'Price',
          items: data,
          rangeStep: priceStep,
          filterType: 'range',
          priceDetail: true,
        })
      }
    }

    return filterItems
  }

  const showDetails = (dishes): void => {
    setDishesData(dishes)
    setOpenDrawer(true)
  }

  useEffect(() => {
    const searchDishList = dishSearch.length
      ? loginFilterDish?.filter((dish) =>
          dish.dish.name.toLowerCase().includes(dishSearch.toLowerCase()),
        )
      : loginFilterDish
    setSearchDish(searchDishList ?? [])
  }, [dishSearch, isAuthenticated, loginFilterDish])

  useEffect(() => {
    onFilter({})
    setFilterTabList(onFilterItems())
  }, [restaurant?.restaurantDTO?.menus])

  const onFilter = (obj: IFilterResponseItem): void => {
    if (restaurantDishList) {
      const priceStep = filterTabList.find((item) => item.name === 'Price')
        ?.items[0].value

      const data = restaurantDishList.filter((dish) => {
        if (
          obj.price !== undefined &&
          Number(priceStep) &&
          obj.price > Number(priceStep) &&
          Number(dish.dish.price ?? 0) > Number(obj.price)
        ) {
          return false
        }

        if (obj.dietary && obj.dietary.length > 0) {
          const dietaryValues = obj.dietary.map((item) => item.name)
          if (
            !dietaryValues.some((dietary) =>
              dish?.dish?.dietary
                ?.toLocaleLowerCase()
                .split(', ')
                .includes(dietary.toLowerCase()),
            )
          ) {
            return false
          }
        }
        if (obj.menu && obj.menu.length > 0) {
          const dietaryValues = obj.menu.map((item) => item.name)
          if (
            !dietaryValues.some(
              (menu) =>
                dish.menuName?.toLocaleLowerCase() === menu.toLowerCase(),
            )
          ) {
            return false
          }
        }

        return true
      })
      setLoginFilterDish(data)
    }
  }

  return (
    <>
      {restaurantDishList?.length ? (
        <DishesTabMainContainer>
          <Box
            pt={{ xs: '16px', md: '46px' }}
            pb="10px"
            overflow={'auto'}
            className="hide-scrollbar"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              <DishesTabInputComponent
                id="dishSearch"
                value={dishSearch}
                setValue={setDishSearch}
                placeholder="Search dish name"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'stretch',
                gap: '12px',
              }}
            >
              {isMediumScreen ? (
                <RoundedButton
                  component="p"
                  borderColor={theme.palette.grey[800]}
                  diameter={37}
                  onClick={() => setFilterModalOpen(true)}
                >
                  <IconAdjustments
                    size={18}
                    color={theme.palette.grey[800]}
                    stroke={2}
                  />
                </RoundedButton>
              ) : (
                <>
                  <Box>
                    <Filter
                      onFilterData={onFilter}
                      tabList={filterTabList}
                      isFilterModalOpen={filterModalOpen}
                      setFilterModalOpen={setFilterModalOpen}
                    />
                  </Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    sx={{
                      borderRadius: '10px',
                      textTransform: 'none',
                    }}
                    startIcon={<IconAdjustments size={18} stroke={2} />}
                    onClick={() => setFilterModalOpen(true)}
                  >
                    Filter
                  </Button>
                </>
              )}
            </Box>
          </Box>
          {isMediumScreen && (
            <Box mt="12px">
              <Filter
                onFilterData={onFilter}
                tabList={filterTabList}
                isFilterModalOpen={filterModalOpen}
                setFilterModalOpen={setFilterModalOpen}
              />
            </Box>
          )}
          <DishesTabMainGrid rowSpacing={isMediumScreen ? 0 : 5}>
            {searchDish.map((item, index) => (
              <Grid item xs={12} md={6} key={index}>
                <div onClick={() => showDetails(item)}>
                  <RestaurantDishesCard
                    id={item.dish.dishID}
                    key={index}
                    image={
                      item?.dish?.imageurl && item.dish.imageurl.length !== 0
                        ? item.dish.imageurl
                        : '/images/restaurant-logo.jpg'
                    }
                    title={item?.dish?.name}
                    description={item?.dish?.description}
                    price={item?.dish?.price}
                    tagChipData={[
                      {
                        percentage: `${item?.probability?.yes.toFixed(2)}`,
                        title: 'Match',
                      },
                    ]}
                  />
                </div>
              </Grid>
            ))}
            {!searchDish.length && (
              <Grid item xs={12}>
                <DishesTabTypography>
                  <NoResultsFound containerHeight="400px" />
                </DishesTabTypography>
              </Grid>
            )}
          </DishesTabMainGrid>
          {dishesData && (
            <RestaurantDrawer
              open={openDrawer}
              toggleDrawer={setOpenDrawer}
              data={dishesData}
            />
          )}
        </DishesTabMainContainer>
      ) : (
        <NoResultsFound
          containerHeight="400px"
          mainText="No data available"
          secondaryText="Requested information is not found"
        />
      )}
    </>
  )
}

export default DishesTab
