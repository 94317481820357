import styled from 'styled-components'
import InputComponent from '../../../../components/ui/InputComponent'
import { Grid, Typography, useTheme } from '@mui/material'

export const DishesTabMainContainer = styled('div').attrs(() => ({}))(() => ({
  paddingBottom: '44px',
}))

export const DishesTabInputComponent = styled(InputComponent).attrs(() => ({
  type: 'outline',
  sx: {
    width: '280px',
    '& .MuiInputBase-root': {
      py: {
        xs: '0px',
        sm: '3px',
      },
    },
  },
}))(() => ({}))

export const DishesTabMainGrid = styled(Grid).attrs(() => ({
  container: true,
  columnSpacing: '20px',
  sx: {
    mt: '14px',
  },
}))(() => ({}))

export const DishesTabTypography = styled(Typography).attrs(() => {
  const theme = useTheme()
  return {
    variant: 'subtitle1',
    color: theme.palette.grey[800],
  }
})(() => ({}))
