import {
  Box,
  CircularProgress,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React from 'react'
import {
  COOKIE_POLICY_URL,
  COPY_RIGHT_TEXT,
  FACEBOOK_PAGE_URL,
  INSTAGRAM_PAGE_URL,
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITION_URL,
} from '../helpers/static-constants'
import {
  MuiAlert,
  MuiBackdrop,
  MuiSnackbar,
} from '../pages/eater/styled/global.styled'
import { useAppDispatch, useAppSelector } from '../store'
import {
  selectIsLoading,
  selectMessage,
  selectSeverity,
  selectShouldOpen,
  setOpen,
} from '../store/authSlice'
import SubscribeForm from './SubscribeForm'
import ImgIcon from './ui/ImgIcon'

const linkStyle = {
  color: (theme: any) => theme.palette.common.white,
  textDecoration: 'underline',
}

const Footer: React.FC = () => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useAppDispatch()
  const openServer = useAppSelector(selectShouldOpen)
  const message = useAppSelector(selectMessage)
  const severity = useAppSelector(selectSeverity)
  const isLoading = useAppSelector(selectIsLoading)

  const setOpenServer = (): void => {
    dispatch(setOpen(false))
  }

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        zIndex: 100,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: {
            xs: 'start',
            sm: 'start',
            md: 'end',
            lg: 'end',
            xl: 'end',
          },
          flexDirection: {
            xs: 'column',
            sm: 'column',
            md: 'row',
          },
          marginX: {
            xs: 2,
            sm: 10,
          },
          marginY: {
            xs: 4,
            md: 5,
          },
        }}
      >
        <Box
          style={{
            flexGrow: 5,
          }}
          className="order-1 tab-order-3"
        >
          <Box
            sx={{
              display: 'flex',
              mb: 2,
              gap: 2,
            }}
          >
            <Link
              href={PRIVACY_POLICY_URL}
              variant="subtitle1"
              sx={linkStyle}
              target="_blank"
            >
              Cookie Policy
            </Link>
            <Link
              href={COOKIE_POLICY_URL}
              variant="subtitle1"
              sx={linkStyle}
              target="_blank"
            >
              Privacy Policy
            </Link>
            <Link
              href={TERMS_AND_CONDITION_URL}
              variant="subtitle1"
              sx={linkStyle}
              target="_blank"
            >
              Terms and Conditions
            </Link>
          </Box>
          <Typography
            variant="subtitle1"
            color={theme.palette.grey[400]}
            dangerouslySetInnerHTML={{ __html: COPY_RIGHT_TEXT }}
          />
        </Box>
        <SubscribeForm />
        <div
          style={{
            minHeight: isSmallScreen ? '2px' : '40px',
            minWidth: isSmallScreen ? '100%' : '2px',
            backgroundColor: theme.palette.primary[900],
            color: theme.palette.primary[900],
            flex: 0,
            margin: isSmallScreen ? '32px 0' : '0 24px',
          }}
          className="order-3 tab-order-2"
        />
        <Box
          sx={{
            mt: {
              xs: 4,
              md: 0,
            },
          }}
          className="order-4 tab-order-4"
        >
          <div style={{ display: 'flex' }}>
            <ImgIcon
              icon="/new/images/icons/facebook.svg"
              alt="facebook"
              wrapperStyle={{
                backgroundColor: theme.palette.primary.light,
                marginRight: isSmallScreen ? '16px' : '8px',
              }}
              onClick={() => window.open(FACEBOOK_PAGE_URL, '_target')}
            />
            <ImgIcon
              icon="/new/images/icons/instagram.svg"
              alt="instagram"
              wrapperStyle={{ backgroundColor: theme.palette.primary.light }}
              onClick={() => window.open(INSTAGRAM_PAGE_URL, '_target')}
            />
          </div>
        </Box>
        <MuiBackdrop open={isLoading}>
          <CircularProgress color="inherit" />
        </MuiBackdrop>
      </Box>
      <MuiSnackbar open={openServer} onClose={setOpenServer}>
        <MuiAlert onClose={setOpenServer} severity={severity ?? 'success'}>
          {message}
        </MuiAlert>
      </MuiSnackbar>
    </Box>
  )
}

export default Footer
