import React from 'react'
import RestaurantCardSkeleton from './RestaurantCardSkeleton'
import { Box } from '@mui/material'

const RestaurantCardContainerSkeleton: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        marginTop: '20px',
        width: '100%',
        gap: '20px',
        overflow: 'auto',
      }}
      className="hide-scrollbar"
    >
      {[1, 3, 4].map((_, index) => (
        <RestaurantCardSkeleton key={index} />
      ))}
    </Box>
  )
}

export default RestaurantCardContainerSkeleton
