import { Box, Typography, useTheme } from '@mui/material'
import { IconX } from '@tabler/icons-react'
import React from 'react'
import { IFilterResponseItem } from '../../types'

interface FilterButtonProps {
  filterResponse: IFilterResponseItem
  priceStep: string | undefined
  priceType: string | undefined
  onButtonClick: (parentName: string) => void
}

const FilterButton: React.FC<FilterButtonProps> = ({
  filterResponse,
  onButtonClick,
  priceType,
  priceStep,
}) => {
  const theme = useTheme()
  const renderButtons = (data: IFilterResponseItem): JSX.Element[] => {
    const buttons: JSX.Element[] = []

    const addButton = (
      key: string,
      label: string,
      parentName: string,
    ): void => {
      buttons.push(
        <Box
          sx={{
            borderRadius: '10px',
            border: `1px solid ${theme.palette.secondary.main}`,
            backgroundColor: theme.palette.secondary[50],
            textTransform: 'none',
            fontSize: '14px',
            height: '100%',
            minWidth: 'min-content',
            color: theme.palette.secondary.main,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
          }}
          key={key}
        >
          <Typography
            noWrap
            variant="subtitle1"
            color={theme.palette.secondary.dark}
            component="p"
            maxWidth="60px"
          >
            {label}
          </Typography>
          <IconX
            size={16}
            stroke={2}
            onClick={() => onButtonClick(parentName)}
            style={{
              marginLeft: '2px',
            }}
          />
        </Box>,
      )
    }

    if (data.distance && data.distance !== 0) {
      addButton('distance', `${data.distance} mi`, 'distance')
    }

    if (data.rating) {
      addButton('rating', data.rating.name, 'rating')
    }

    if (data.price && Number(priceStep) && data.price !== Number(priceStep)) {
      addButton(
        'price',
        `${priceType ? price[data.price - 1] : `$${data.price}`}`,
        'price',
      )
    }

    if (
      data.dietary &&
      Array.isArray(data.dietary) &&
      data.dietary.length > 0
    ) {
      const dietaryValues = data.dietary.map((item) => item.name).join(', ')
      addButton('dietary', dietaryValues, 'dietary')
    }

    if (
      data.cuisine &&
      Array.isArray(data.cuisine) &&
      data.cuisine.length > 0
    ) {
      const cuisineValues = data.cuisine.map((item) => item.name).join(', ')
      addButton('cuisine', cuisineValues, 'cuisine')
    }

    if (
      data.ambience &&
      Array.isArray(data.ambience) &&
      data.ambience.length > 0
    ) {
      const ambienceValues = data.ambience.map((item) => item.name).join(', ')
      addButton('ambience', ambienceValues, 'ambience')
    }
    if (data.menu && Array.isArray(data.menu) && data.menu.length > 0) {
      const menuValues = data.menu.map((item) => item.name).join(', ')
      addButton('menu', menuValues, 'menu')
    }

    return buttons
  }

  return (
    <Box
      display="flex"
      gap="12px"
      height={'100%'}
      overflow={'auto'}
      className="hide-scrollbar"
    >
      {renderButtons(filterResponse)}
    </Box>
  )
}

export default FilterButton

const price = ['$', '$$', '$$$', '$$$$']
