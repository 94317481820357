import React, { useState } from 'react'
import {
  Card,
  CardMedia,
  Box,
  useMediaQuery,
  useTheme,
  Skeleton,
} from '@mui/material'
import { selectRestaurantDetail } from '../../../../store/restaurantSlice'
import { useAppSelector } from '../../../../store'
import { DEFAULT_CARD_IMAGE } from '../../../../helpers/static-constants'

const RestaurantImages: React.FC = () => {
  const theme = useTheme()
  const restaurant = useAppSelector(selectRestaurantDetail)?.restaurantDTO
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [loaded, setLoaded] = useState(new Array(5).fill(false))

  const imageUrl =
    restaurant?.imageurl && restaurant?.imageurl.length !== 0
      ? restaurant?.imageurl
      : DEFAULT_CARD_IMAGE

  const images = Array(5).fill({
    src: imageUrl,
    alt: 'resactly-restaurant-image',
  })

  const handleImageLoad = (index: number): void => {
    setLoaded((prevLoaded) => {
      const newLoaded = [...prevLoaded]
      newLoaded[index] = true
      return newLoaded
    })
  }

  return (
    <>
      {!isSmallScreen ? (
        <Box display="flex" flexDirection="row" gap="10px" height="400px">
          <Box
            flex={{ xs: '0 0 auto', md: '0 0 58.33%' }}
            height={{ xs: 'calc(50% + 5px)', md: '100%' }}
          >
            <Card
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                borderRadius: '6px',
              }}
            >
              {!loaded[0] && (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  sx={{ borderRadius: '6px' }}
                />
              )}
              <CardMedia
                sx={{
                  height: '100%',
                  width: '100%',
                  display: loaded[0] ? 'block' : 'none',
                }}
                component="img"
                image={images[0].src}
                alt={images[0].alt}
                onLoad={() => handleImageLoad(0)}
              />
            </Card>
          </Box>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            flexWrap="wrap"
            flex={{ xs: '0 0 auto', md: '0 0 41.67%' }}
            height={{ xs: 'calc(50% + 5px)', md: '100%' }}
            gap="10px"
          >
            {images.slice(1).map((image, index) => (
              <Box
                key={index}
                flex={{ xs: '0 0 calc(50% - 5px)', md: '0 0 calc(50% - 5px)' }}
                height={{ xs: 'calc(50% - 5px)', md: 'calc(50% - 5px)' }}
              >
                <Card
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    borderRadius: '6px',
                  }}
                >
                  {!loaded[index + 1] && (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100%"
                      sx={{ borderRadius: '6px' }}
                    />
                  )}
                  <CardMedia
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: loaded[index + 1] ? 'block' : 'none',
                    }}
                    component="img"
                    image={image.src}
                    alt={image.alt}
                    onLoad={() => handleImageLoad(index + 1)}
                  />
                </Card>
              </Box>
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            overflowX: 'scroll',
            whiteSpace: 'nowrap',
            display: 'flex',
            gap: '10px',
            height: '225px',
          }}
          className="hide-scrollbar"
        >
          {images.map((image, index) => (
            <Card
              key={index}
              sx={{
                display: 'inline-block',
                minWidth: '350px',
                height: '100%',
                borderRadius: '6px',
              }}
            >
              {!loaded[index] && (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  sx={{ borderRadius: '6px' }}
                />
              )}
              <CardMedia
                sx={{
                  height: '100%',
                  width: '100%',
                  display: loaded[index] ? 'block' : 'none',
                }}
                component="img"
                image={image.src}
                alt={image.alt}
                onLoad={() => handleImageLoad(index)}
              />
            </Card>
          ))}
        </Box>
      )}
    </>
  )
}

export default RestaurantImages
