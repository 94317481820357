import { useAuth0 } from '@auth0/auth0-react'
import {
  alpha,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import {
  IconArrowBearRight,
  IconBookmark,
  IconBookmarkFilled,
  IconCalendarCheck,
  IconChevronDown,
  IconClockHour7,
  IconCurrencyDollar,
  IconMapPin,
  IconPhone,
  IconPointFilled,
} from '@tabler/icons-react'
import moment from 'moment'
import React, { MouseEvent, useEffect, useState } from 'react'
import RoundedButton from '../../../../components/ui/RoundedButton'
import TagChip from '../../../../components/ui/TagChip'
import { generateAddress } from '../../../../helpers/converters'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { selectLogin } from '../../../../store/authSlice'
import { selectSavedRestaurantIds } from '../../../../store/profileSlice'
import {
  favoriteRestaurant,
  selectRestaurantDetail,
} from '../../../../store/restaurantSlice'
import useRudderStackAnalytics from '../../../../useRudderAnalytics'
import { MuiBackdrop } from '../../styled/global.styled'
import RestaurantShare from './RestaurantShare'

const RestaurantInfo: React.FC = () => {
  const today = moment().format('dddd')
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const dispatch = useAppDispatch()
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const restaurant = useAppSelector(selectRestaurantDetail)?.restaurantDTO
  const recommendation = useAppSelector(selectRestaurantDetail)?.recommendation
  const saveRestaurants = useAppSelector(selectSavedRestaurantIds)
  const [staticLoader, setStaticLoader] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [mapUrl, setMapUrl] = useState<string>('')
  const openPop = Boolean(anchorEl)
  const id = openPop ? 'simple-popover' : undefined

  useEffect(() => {
    const isSaved = saveRestaurants.includes(restaurant?.restaurantID ?? '')
    setIsSaved(isSaved)
  }, [saveRestaurants])

  useEffect(() => {
    const fetchMapUrl = async (): Promise<void> => {
      const url = await onMapDirections()
      setMapUrl(url ?? '')
    }
    void fetchMapUrl()
  }, [restaurant?.restaurantID])

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const restaurantTimezone = restaurant?.weekdayText?.map((entry) => {
    const [day, hours] = entry.split(': ')
    const timeRanges = hours.split(' – ')
    return {
      day,
      time:
        hours === 'Closed' ? [hours] : [`${timeRanges[0]} - ${timeRanges[1]}`],
    }
  })

  const login: any = async (): Promise<void> => {
    await loginWithRedirect()
  }

  const tagsArray =
    restaurant?.cuisine
      ?.trim()
      ?.split(',')
      ?.map((tag) => tag.trim())
      .filter((tag) => tag.length > 0) ?? []

  const addressParts = [
    restaurant?.address1,
    restaurant?.city,
    restaurant?.state,
  ]
  const address = addressParts.filter(Boolean).join(', ')

  const onActiveTime = (): string[] => {
    const data = restaurantTimezone?.find((day) => day.day === today)
    return data?.time ?? []
  }

  const onClickBookmark = async (): Promise<void> => {
    setStaticLoader(true)
    await dispatch(favoriteRestaurant(restaurant?.alias ?? ''))
    setStaticLoader(false)
  }

  const onMapDirections = async (): Promise<string> => {
    if (!restaurant) {
      return 'https://www.google.com/maps/dir/?api=1&destination='
    }

    if (restaurant.place_id) {
      return `https://www.google.com/maps/place/?q=place_id:${restaurant.place_id}`
    }

    return `https://www.google.com/maps/place/${generateAddress(restaurant)}`
  }

  const analytics = useRudderStackAnalytics()
  const userDetail = useAppSelector(selectLogin)

  const trackClick = (): void => {
    analytics?.track('RESERVATION_MADE', {
      category: 'RESTAURANT',
      eaterId: `${userDetail?.eaterDTO?.eaterId ?? ''}`,
      eaterName: `${userDetail?.eaterDTO?.firstName ?? ''} ${
        userDetail?.eaterDTO?.lastName ?? ''
      }`,
      eaterEmail: `${userDetail?.eaterDTO?.email ?? ''}`,
      restaurantAlias: `${restaurant?.alias ?? ''}`,
    })

    const reservationUrl = restaurant?.reservationUrl ?? restaurant?.website
    window.open(`${reservationUrl ?? ''}`, '_blank')
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection={isMediumScreen ? 'column' : 'row'}
        marginTop="40px"
        marginBottom="32px"
        width="100%"
        justifyContent="space-between"
      >
        <Box flex="2" marginRight={isMediumScreen ? '0' : '16px'}>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Typography
                maxWidth={isMediumScreen ? '500px' : '400px'}
                noWrap
                variant="h3"
                fontWeight={600}
                sx={{ textTransform: 'capitalize', paddingRight: '12px' }}
                color={theme.palette.grey[800]}
              >
                {restaurant?.name?.toLocaleLowerCase()}
              </Typography>
              {!isAuthenticated || recommendation?.yes ? (
                <TagChip
                  data={[
                    {
                      percentage: recommendation?.yes
                        ? recommendation.yes.toFixed(2)
                        : '15',
                      title: 'Match',
                    },
                  ]}
                  textBlur={!isAuthenticated}
                />
              ) : null}
              {!isAuthenticated && !isMediumScreen && (
                <>
                  <Typography
                    variant="subtitle1"
                    fontWeight={500}
                    color={theme.palette.grey[400]}
                    sx={{
                      borderBottom: `1px solid ${theme.palette.grey[400]}`,
                      marginRight: '5px',
                      marginLeft: '12px',
                      cursor: 'pointer',
                    }}
                    onClick={login}
                  >
                    Sign up
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    fontWeight={500}
                    color={theme.palette.grey[400]}
                  >
                    to view AI match
                  </Typography>
                </>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: isMediumScreen ? 'flex-start' : 'center',
                flexDirection: isMediumScreen ? 'column' : 'row',
                flexWrap: 'wrap',
                marginTop: '16px',
                marginBottom: '16px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <IconMapPin size={18} stroke={2} />
                <Typography
                  variant="body2"
                  component="p"
                  fontWeight={500}
                  sx={{ textTransform: 'capitalize' }}
                  color={theme.palette.grey[600]}
                  paddingLeft={'5px'}
                >
                  {address}
                </Typography>
                {restaurant && restaurant.distance !== 0 && (
                  <>
                    <IconPointFilled
                      color={theme.palette.grey[300]}
                      size={12}
                    />
                    <Typography
                      variant="body2"
                      component="p"
                      fontWeight={500}
                      sx={{ textTransform: 'lowercase' }}
                      color={theme.palette.grey[600]}
                    >
                      {restaurant.distance} mi
                    </Typography>
                  </>
                )}
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  marginTop: isMediumScreen ? '14px' : '0px',
                }}
              >
                {restaurantTimezone?.length && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    {!isMediumScreen && (
                      <Divider
                        color={theme.palette.grey[300]}
                        orientation="vertical"
                        flexItem
                        sx={{
                          marginX: '16px',
                        }}
                      />
                    )}
                    <IconClockHour7 size={18} stroke={2} />
                    <Typography
                      variant="body2"
                      component="p"
                      fontWeight={500}
                      sx={{
                        textTransform: 'lowercase',
                        paddingLeft: '6px',
                        color:
                          onActiveTime()[0] === 'Closed'
                            ? theme.palette.secondary.main
                            : theme.palette.grey[600],
                      }}
                    >
                      {onActiveTime()}
                    </Typography>

                    <Button
                      variant="text"
                      onClick={handleClick}
                      aria-describedby={id}
                      sx={{
                        textTransform: 'capitalize',
                        marginLeft: '6px',
                      }}
                      endIcon={
                        <IconChevronDown
                          color={theme.palette.grey[400]}
                          stroke={2}
                          size={18}
                        />
                      }
                    >
                      <Typography
                        variant="subtitle1"
                        component="p"
                        fontWeight={500}
                        sx={{
                          textTransform: 'capitalize',

                          borderBottom: `1px solid ${theme.palette.grey[400]}`,
                        }}
                        color={theme.palette.grey[400]}
                      >
                        Today
                      </Typography>
                    </Button>
                  </div>
                )}

                {restaurant?.priceRange && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginTop: '2px',
                    }}
                  >
                    {!isMediumScreen && (
                      <Divider
                        color={theme.palette.grey[300]}
                        orientation="vertical"
                        flexItem
                        sx={{
                          marginX: '16px',
                        }}
                      />
                    )}
                    <IconCurrencyDollar
                      size={18}
                      color={theme.palette.grey[600]}
                      stroke={2}
                    />
                    <Typography
                      variant="body2"
                      component="p"
                      fontWeight={500}
                      sx={{ textTransform: 'capitalize', marginX: '4px' }}
                      color={theme.palette.grey[600]}
                    >
                      {restaurant?.priceRange}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      component="p"
                      fontWeight={500}
                      color={theme.palette.grey[400]}
                    >
                      /per person
                    </Typography>
                  </div>
                )}
              </div>

              <Popover
                id={id}
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    borderRadius: '8px',
                    width: '230px',
                  },
                }}
              >
                <div
                  style={{
                    padding: '8px',
                  }}
                >
                  {restaurantTimezone?.map((time, index) => {
                    const isActiveDay = time.day === today

                    return (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                          padding: '8px',
                          color: isActiveDay
                            ? theme.palette.grey[800]
                            : theme.palette.grey[500],
                        }}
                      >
                        <Typography
                          noWrap
                          component="p"
                          variant="subtitle2"
                          fontWeight={isActiveDay ? 600 : 500}
                        >
                          {time.day}
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: '5px',
                          }}
                        >
                          {time.time.map((item, idx) => {
                            return (
                              <Typography
                                color={
                                  item === 'Closed'
                                    ? theme.palette.secondary.main
                                    : isActiveDay
                                    ? theme.palette.grey[800]
                                    : theme.palette.grey[500]
                                }
                                fontWeight={isActiveDay ? 600 : 500}
                                key={idx}
                                noWrap
                                component="p"
                                variant="subtitle2"
                              >
                                {item}
                              </Typography>
                            )
                          })}
                        </div>
                      </Box>
                    )
                  })}
                </div>
              </Popover>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              {tagsArray.map((item, index) => {
                return (
                  <Chip
                    key={index}
                    sx={{
                      height: '24px',
                      borderRadius: '6px',
                      color: theme.palette.grey[500],
                      backgroundColor: theme.palette.grey[50],
                      border: `1px solid ${theme.palette.grey[200]}`,
                      fontSize: '12px',
                    }}
                    label={item}
                  />
                )
              })}
            </div>
          </div>
        </Box>
        <Box
          display="flex"
          justifyContent={isMediumScreen ? 'start' : 'end'}
          alignItems="center"
          gap="8px"
          marginTop={isMediumScreen ? '24px' : '0'}
        >
          {(restaurant?.reservationUrl ?? restaurant?.website) && (
            <Button
              component="a"
              variant="contained"
              color="primary"
              size="small"
              disableElevation
              href={restaurant?.reservationUrl ?? restaurant?.website}
              onClick={trackClick}
              target="_blank"
              sx={{
                fontSize: '12px',
                borderRadius: '10px',
                fontWeight: 500,
                textTransform: 'capitalize',
                alignItems: 'center',
                justifyContent: 'center',
                paddingY: '8px',
                paddingX: '16px',
                '&.MuiButton-root': {
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.8),
                    color: 'white',
                  },
                },
              }}
              rel="noopener noreferrer"
              startIcon={<IconCalendarCheck size={14} stroke={2} />}
            >
              <span>Reserve Table</span>
            </Button>
          )}

          {!isSmallScreen ? (
            <Button
              component="a"
              variant="outlined"
              color="primary"
              size="small"
              disableElevation
              href={mapUrl}
              target="_blank"
              sx={{
                fontSize: '12px',
                borderRadius: '10px',
                fontWeight: 500,
                display: 'flex',
                textTransform: 'capitalize',
                alignItems: 'center',
                justifyContent: 'center',
                paddingY: '8px',
                paddingX: '16px',
                '&.MuiButton-root': {
                  borderColor: theme.palette.grey[300],
                },
              }}
              rel="noopener noreferrer"
              startIcon={<IconArrowBearRight size={14} stroke={2} />}
            >
              {<span>Directions</span>}
            </Button>
          ) : (
            <RoundedButton
              target="_blank"
              component="a"
              href={mapUrl}
              rel="noopener noreferrer"
              borderColor={theme.palette.grey[300]}
              diameter={37}
            >
              <IconArrowBearRight
                size={18}
                color={theme.palette.grey[800]}
                stroke={2}
              />
            </RoundedButton>
          )}

          {restaurant?.phone && (
            <RoundedButton
              component="a"
              href={`tel:${restaurant?.phone}`}
              borderColor={theme.palette.grey[300]}
              diameter={37}
            >
              <IconPhone size={18} color={theme.palette.grey[800]} stroke={2} />
            </RoundedButton>
          )}
          {isAuthenticated && (
            <RoundedButton
              onClick={() => {
                void onClickBookmark()
              }}
              borderColor={theme.palette.grey[300]}
              diameter={37}
            >
              {isSaved ? (
                <IconBookmarkFilled size={18} color={theme.palette.grey[800]} />
              ) : (
                <IconBookmark
                  size={18}
                  color={theme.palette.grey[800]}
                  stroke={2}
                />
              )}
            </RoundedButton>
          )}
          <RestaurantShare
            imgUrl={restaurant?.imageurl ?? ''}
            description={restaurant?.description ?? ''}
            title={restaurant?.name ?? ''}
            cuisine={restaurant?.cuisine?.replace(/,/g, ' ') ?? ''}
            city={restaurant?.city ?? ''}
          />
        </Box>
      </Box>
      <MuiBackdrop open={staticLoader}>
        <CircularProgress color="inherit" />
      </MuiBackdrop>
    </>
  )
}

export default RestaurantInfo
