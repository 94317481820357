import { Box, Grid } from '@mui/material'
import React from 'react'
import EmptyScreen from '../../../../components/EmptyScreen'
import { IRestaurantReview } from '../../../../types'
import ProfileReview from '../../../../components/profile/ProfileReview'
import { useAppSelector } from '../../../../store'
import { selectIsMobileSearch } from '../../../../store/searchSlice'
import {
  MOBILE_NAV_HEIGHT,
  MOBILE_SEARCH_HEIGHT,
} from '../../../../helpers/static-constants'

interface ProfileReviewTabProps {
  restaurantReviews: IRestaurantReview[]
}
const ProfileReviewTab: React.FC<ProfileReviewTabProps> = ({
  restaurantReviews,
}) => {
  const isMobileSearch = useAppSelector(selectIsMobileSearch)

  return (
    <>
      {restaurantReviews.length ? (
        <Box
          sx={{
            height: {
              xs: `calc(100vh - ${
                isMobileSearch
                  ? `${MOBILE_SEARCH_HEIGHT + MOBILE_NAV_HEIGHT + 322}px`
                  : `${MOBILE_NAV_HEIGHT + 286}px`
              })`,
              md: '100%',
            },
            overflow: 'auto',
            my: { xs: '16px', md: '40px' },
          }}
        >
          <Grid container spacing={2}>
            {restaurantReviews.map((item, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <ProfileReview review={item} />
                </Grid>
              )
            })}
          </Grid>
        </Box>
      ) : (
        <EmptyScreen
          mainText="No data available"
          secondaryText="No restaurants found"
          imageUrl="/images/empty-screen.svg"
          containerHeight={'400px'}
        />
      )}
    </>
  )
}

export default ProfileReviewTab
