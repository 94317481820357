import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AuthGuard from './components/AuthGuard'
import { CHILD_ROUTES, ROUTES } from './helpers/routes-helper'
import BaseLayout from './layouts/BaseLayout'
import OnboardLayout from './layouts/OnboardLayout'
import WebLayout from './layouts/WebLayout'
import RestaurantLayout from './layouts/RestaurantLayout'
import About from './pages/eater/about/About'
import Error from './pages/eater/error/Error'
import GroupPage from './pages/eater/group/GroupPage'
import Home from './pages/eater/home/Home'
import Onboard from './pages/eater/onboardPages/Onboard'
import Profile from './pages/eater/profile/ProfilePage'
import RecoverAccount from './pages/eater/recoverAccount/RecoverAccount'
import RecoverBlockedAccount from './pages/eater/recoverAccount/RecoverBlockedAccount'
import RestaurantDetail from './pages/eater/restaurant/RestaurantDetail'
import Restaurants from './pages/eater/restaurant/Restaurants'
import SearchResults from './pages/eater/restaurant/SearchResults'
import Social from './pages/eater/social/Social'
import Dashboard from './pages/restaurant/dashboard/Dashboard'
import RestaurantPending from './pages/restaurant/RestaurantPending'
import Sitemap from './sitemap-generator'

// Make separate routes before render, we have following categories of routes
// 1. Onboard routes (Public)
// 2. Web routes (Public)
// 3. Eater routes (After Auth)
// 4. Restaurant routes (After Auth)
// Update route logic and define all this routes by category and should be render based on auth user type

const RouterApp: React.FC = () => {
  return (
    <Routes>
      <Route element={<BaseLayout />}>
        <Route element={<WebLayout />}>
          <Route path="*" element={<Error type="404" />} />
          <Route
            path={ROUTES.UNAUTHORIZED}
            element={<Error type="unauthorized" />}
          />
          <Route path={ROUTES.HOME} element={<Home />} />
          <Route path={ROUTES.ABOUT} element={<About />} />
          <Route element={<AuthGuard />}>
            <Route path={ROUTES.SOCIAL} element={<Social />} />
            <Route path={`${ROUTES?.SOCIAL}/:id`} element={<GroupPage />} />
            <Route path={ROUTES.PROFILE} element={<Profile />}>
              <Route path={CHILD_ROUTES.BIO} element={<Profile />} />
              <Route path={CHILD_ROUTES.PREFERENCES} element={<Profile />} />
              <Route path={CHILD_ROUTES.REVIEWS} element={<Profile />} />
              <Route
                path={CHILD_ROUTES.SAVED_RESTAURANTS}
                element={<Profile />}
              />
            </Route>
          </Route>
          <Route path={ROUTES.RESTAURANTS} element={<Restaurants />} />
          <Route path={ROUTES.SEARCH} element={<SearchResults />} />
          <Route
            path={`${ROUTES?.RESTAURANT_DETAILS}/:alias/details`}
            element={<RestaurantDetail />}
          />
        </Route>
        <Route element={<AuthGuard />}>
          <Route element={<OnboardLayout />}>
            <Route path={`${ROUTES.ONBOARD}/:stepName`} element={<Onboard />} />
          </Route>
        </Route>
        <Route element={<AuthGuard />}>
          <Route path={ROUTES.RECOVER_ACCOUNT} element={<RecoverAccount />} />
          <Route
            path={ROUTES.RECOVER_BLOCKED_ACCOUNT}
            element={<RecoverBlockedAccount />}
          />
        </Route>
        <Route element={<RestaurantLayout />}>
          <Route
            path={ROUTES.RESTAURANT_ADMIN_DASHBOARD}
            element={<Dashboard />}
          />
          <Route
            path={ROUTES.RESTAURANT_ADMIN_PENDING}
            element={<RestaurantPending />}
          />
        </Route>
      </Route>
      <Route path={ROUTES.SITEMAP} Component={Sitemap} />
    </Routes>
  )
}

export default RouterApp
