import { Grid, styled, useTheme } from '@mui/material'

export const ResactlyCommunityInvitationGrid = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const ResactlyCommunityInvitationGridContainer = styled('div')(() => {
  const theme = useTheme()
  return {
    borderRadius: '24px',
    backgroundColor: theme.palette.secondary[100],
    overflow: 'hidden',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      minHeight: '225px',
      marginBottom: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '230px',
      marginBottom: '20px',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '314px',
      marginBottom: '24px',
    },
  }
})

export const ResactlyCommunityInvitationGridInnerContainer = styled('div')(
  () => {
    return {
      padding: '24px',
    }
  },
)

export const ResactlyCommunityInvitationGridContainerSecond = styled('div')(
  () => {
    const theme = useTheme()
    return {
      backgroundColor: theme.palette.secondary[100],
      padding: '24px',
      borderRadius: '24px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
    }
  },
)

export const ResactlyCommunityInvitationContainer = styled('div')(() => {
  const theme = useTheme()
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary[100],
    borderRadius: '24px',
    marginTop: '24px',
    marginBottom: '48px',
    backgroundImage: `url(${'/images/about/Just_eat-1.png'}),url('/images/about/Just_eat-2.png')`,
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
      marginBottom: '40px',
      padding: '24px 16px 100px 16px',
      backgroundSize: '25%,25%',
      backgroundPosition: 'bottom left, bottom right',
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '20px',
      marginBottom: '44px',
      padding: '24px 16px 100px 16px',
      backgroundSize: '20%,20%',
      backgroundPosition: 'bottom left, bottom right',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '24px',
      marginBottom: '48px',
      paddingBottom: '48px',
      paddingTop: '48px',
      backgroundSize: '23%,23%',
      backgroundPosition: 'center left -20px, center right -20px',
    },
  }
})

export const ResactlyCommunityInvitationInnerContainer = styled('div')(() => {
  return {
    maxWidth: '610px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
})
