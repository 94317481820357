import { Avatar, styled, Tooltip, Typography, useTheme } from '@mui/material'
import { IconPencil } from '@tabler/icons-react'

export const StoryBoardContainer = styled('div')({
  marginTop: '16px',
  overflowX: 'scroll',
  padding: '2px',
})

export const StoryBoardInnerContainer = styled('div')({
  display: 'flex',
  gap: '12px',
})

interface StoryBoardAvatarMainContainerProps {
  borderColor: string
}

export const StoryBoardAvatarMainContainer = styled(
  'div',
)<StoryBoardAvatarMainContainerProps>(({ borderColor }) => {
  return {
    padding: '2px',
    border: `1px solid ${borderColor}`,
    borderRadius: '50%',
    cursor: 'pointer',
    position: 'relative',
  }
})

export const StoryBoardAvatar = styled(Avatar)(() => {
  return {
    height: '52px',
    width: '52px',
  }
})

export const StoryBoardTooltip = styled(Tooltip)(() => {
  const theme = useTheme()

  return {
    backgroundColor: theme.palette.background.paper,
    '& .MuiTooltip-tooltip': {
      backgroundColor: `${theme.palette.background.paper} !important`,
      padding: '8px 12px !important',
      boxShadow: '0px 0px 16px -4px #10182814 !important',
      borderRadius: '10px !important',
    },
  }
})

export const StoryBoardTooltipTypographyTitle = styled(Typography)(() => {
  const theme = useTheme()

  return {
    color: theme.palette.grey[400],
  }
})

export const StoryBoardTooltipTypographyName = styled(Typography)(() => {
  const theme = useTheme()

  return {
    color: theme.palette.grey[800],
    fontWeight: '600',
    whiteSpace: 'nowrap',
  }
})

export const StoryBoardIconContainer = styled('div')(() => {
  const theme = useTheme()

  return {
    padding: '2px',
    border: `1px dashed ${theme.palette.grey[300]}`,
    borderRadius: '50%',
    height: '57px',
    width: '57px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
})

export const StoryBoardIcon = styled(IconPencil)(() => {
  const theme = useTheme()

  return {
    height: '40px',
    width: '40px',
    margin: '5px',
    color: theme.palette.grey[300],
  }
})
