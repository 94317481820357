import { Avatar, Chip, styled, useTheme } from '@mui/material'

export const GroupMainContainer = styled('div')({
  display: 'flex',
})

export const GroupEditMemberListMainContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

export const GroupEditMemberListInfoContainer = styled('div')({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  cursor: 'pointer',
})

export const GroupEditMemberListInfoInnerContainer = styled('div')({
  paddingLeft: '10px',
})

export const GroupEditMemberListChipContainer = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  paddingRight: '10px',
})

export const GroupEditMemberListAvatar = styled(Avatar)(() => {
  return {
    height: '37px',
    width: '37px',
  }
})

export const GroupEditMemberListChip = styled(Chip)(() => {
  const theme = useTheme()

  return {
    height: '24px',
    borderRadius: '6px',
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[50],
    border: `1px solid ${theme.palette.grey[200]}`,
    fontSize: '12px',
  }
})
