import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled as muiStyled,
  Typography,
  useTheme,
} from '@mui/material'
import styled from 'styled-components'

export const FAQMainContainer = muiStyled('div')(() => {
  const theme = useTheme()
  return {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: '20px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '112px',
      marginBottom: '112px',
    },
  }
})

export const FAQDescriptionTypography = muiStyled(Typography)(() => {
  const theme = useTheme()
  return {
    component: 'p',
    marginTop: '16px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '24px',
      fontWeight: 500,
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: '30px',
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: '32px',
    },
  }
})

export const FAQContentContainer = muiStyled('div')({
  maxWidth: '960px',
})

export const FAQAccordion = muiStyled(Accordion)(() => {
  const theme = useTheme()

  return {
    marginTop: '8px',
    '&.MuiAccordion-root': {
      '&:before': {
        height: '0px',
      },
      '&.Mui-expanded': {
        margin: '8px 0px',
      },
      boxShadow: 'none',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: '12px',
      backgroundColor: theme.palette.grey[50],
    },
  }
})

export const FAQAccordionSummary = styled(AccordionSummary)(() => {
  const theme = useTheme()
  return {
    '&.MuiAccordionSummary-root': {
      '& .MuiAccordionSummary-expandIconWrapper': {
        WebkitTransition: 'none',
        transition: 'none',
      },
      '&.Mui-expanded': {
        minHeight: '20px',
      },
      paddingRight: '24px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '16px',
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '24px',
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: '24px',
      },
    },
  }
})

export const FAQAccordionDetails = muiStyled(AccordionDetails)(() => ({
  paddingX: '24px',
}))

interface StyledIconContainerProps {
  isExpand?: boolean
}

export const StyledIconContainer = styled('div')<StyledIconContainerProps>`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 0px #33333340;
  background-color: white;
  position: ${({ isExpand }) => (isExpand ? 'absolute' : 'relative')};
  bottom: ${({ isExpand }) => (isExpand ? '-6px' : 'initial')};
`
