import { Grid, Typography, useTheme } from '@mui/material'

import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import RestaurantDishesCard from '../../../../components/restaurant/RestaurantDishesCard'
import RestaurantReview from '../../../../components/restaurant/RestaurantReview'
import InputComponent from '../../../../components/ui/InputComponent'
import { useAppDispatch, useAppSelector } from '../../../../store'
import {
  selectEater,
  selectEaterDishReviews,
  setEaterDishReviews,
  setRestaurantReviews,
} from '../../../../store/profileSlice'
import {
  deleteRestaurantReview,
  saveRestaurantDish,
  saveRestaurantReviewsByAlias,
  selectRestaurantDetail,
  selectRestaurantDishList,
  setMessage,
  setOpen,
  setSeverity,
  updateRestaurantReviewsByAlias,
} from '../../../../store/restaurantSlice'
import {
  IRestaurantReview,
  IRestaurantSentPayloadReview,
} from '../../../../types'
import { PredictedDish } from '../../../../types/menuTypes'

export interface IRestaurantDishRatingReview {
  id?: string
  starCount?: number
}

const ReviewsTab: React.FC = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { alias } = useParams()
  const restaurant = useAppSelector(selectRestaurantDetail)?.restaurantDTO
  const saveRestaurantDishList = useAppSelector(selectEaterDishReviews)
  const userDetail = useAppSelector(selectEater)
  const restaurantDishList = useAppSelector(selectRestaurantDishList)

  const [reviewMode, setReviewMode] = useState<'readonly' | 'edit' | 'new'>(
    'new',
  )
  const [dishSearch, setDishSearch] = useState('')
  const [dish, setDish] = useState<PredictedDish[]>(restaurantDishList ?? [])
  const [dishLoader, setDishLoader] = useState<boolean>(false)
  const [restaurantReviewLoader, setRestaurantReviewLoader] =
    useState<boolean>(false)
  const [selectedDishRating, setSelectedDishRating] =
    useState<IRestaurantDishRatingReview>({})
  const [restaurantReview, setRestaurantReview] = useState<
    IRestaurantReview | undefined
  >(undefined)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const tabMode = queryParams.get('mode_type')

  const onDishSearchChange = (search): void => {
    setDishSearch(search)
    const searchDishList = search.length
      ? restaurantDishList?.filter((dish) =>
          dish.dish.name.toLowerCase().includes(dishSearch.toLowerCase()),
        )
      : restaurantDishList
    setDish(searchDishList ?? [])
  }

  useEffect(() => {
    if (restaurantDishList?.length) {
      const result = restaurantDishList.map((dish) => {
        const review = saveRestaurantDishList.find(
          (review) => review.dishId === dish.dish.dishID,
        )
        if (review) {
          return {
            ...dish,
            dish: {
              ...dish.dish,
              name: dish.dish.name,
              description:
                review.sentimentSummary && review.sentimentSummary.length > 0
                  ? review.sentimentSummary
                  : dish.dish.description,
              starCount: review.starCount ?? 0,
            },
          }
        }
        return dish
      })

      setDish(result)
    }
  }, [restaurantDishList, saveRestaurantDishList, alias])

  useEffect(() => {
    const review = userDetail?.restaurantReviews?.find(
      (review) => review.restaurantAlias === alias,
    )
    if (review) {
      setReviewMode('readonly')
    }
    setRestaurantReview(review)
  }, [userDetail?.restaurantReviews])

  useEffect(() => {
    if (tabMode === 'edit') {
      setReviewMode('edit')
    }
  }, [])

  const onReviewUpdate = async (
    data: IRestaurantSentPayloadReview,
  ): Promise<void> => {
    setRestaurantReviewLoader(true)

    const payload: IRestaurantSentPayloadReview = {
      starCount: data.starCount ?? 0,
      value: data.value ?? '',
      alias: restaurant?.alias ?? '',
      images: data.images ?? [],
      deleted: data.deleted ?? [],
    }
    if (restaurantReview && restaurantReview.restaurantReviewId?.length) {
      const result = await dispatch(updateRestaurantReviewsByAlias(payload))
      if (updateRestaurantReviewsByAlias.fulfilled.match(result)) {
        dispatch(setRestaurantReviews(result.payload.restaurantReviews))
        dispatch(setMessage(`Restaurant review has been update successfully.`))
        dispatch(setSeverity('success'))
        dispatch(setOpen(true))
        setReviewMode('readonly')
      }
    } else {
      const result = await dispatch(saveRestaurantReviewsByAlias(payload))
      if (saveRestaurantReviewsByAlias.fulfilled.match(result)) {
        dispatch(setRestaurantReviews(result.payload.restaurantReviews))
        dispatch(setMessage(`Restaurant review has been saved successfully.`))
        dispatch(setSeverity('success'))
        dispatch(setOpen(true))
        setReviewMode('readonly')
      }
    }
    setRestaurantReviewLoader(false)
  }
  const onReviewAction = async (action: 'edit' | 'delete'): Promise<void> => {
    if (action === 'edit') {
      setReviewMode('edit')
    }
    if (
      action === 'delete' &&
      restaurantReview &&
      restaurantReview.restaurantReviewId
    ) {
      setRestaurantReviewLoader(true)
      const result = await dispatch(
        deleteRestaurantReview(restaurantReview.restaurantReviewId),
      )
      if (deleteRestaurantReview.fulfilled.match(result)) {
        dispatch(setRestaurantReviews(result.payload.restaurantReviews))
        dispatch(setMessage(`Restaurant review has been delete successfully.`))
        dispatch(setSeverity('success'))
        dispatch(setOpen(true))
      }
      setRestaurantReviewLoader(false)
      setReviewMode('new')
    }
  }

  const onRating = async (
    rating: IRestaurantDishRatingReview,
  ): Promise<void> => {
    setDishLoader(true)
    setSelectedDishRating(rating)
    const payload = {
      alias: alias ?? '',
      dishId: rating.id ?? '',
      starCount: rating.starCount ?? 0,
    }
    const result = await dispatch(saveRestaurantDish(payload))

    if (saveRestaurantDish.fulfilled.match(result)) {
      dispatch(setEaterDishReviews(result.payload?.reviews))
    }

    setDishLoader(false)
  }

  return (
    <div>
      {reviewMode !== 'readonly' && (
        <Typography
          variant="body1"
          component="p"
          fontWeight={600}
          color={theme.palette.grey[700]}
          sx={{
            paddingTop: '24px',
          }}
        >
          {reviewMode === 'edit'
            ? 'Edit Your Review'
            : 'You have been here, How was your experience?'}
        </Typography>
      )}

      <div
        style={{
          padding: '24px 0',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <RestaurantReview
              review={restaurantReview}
              isLoading={restaurantReviewLoader}
              mode={reviewMode}
              onReview={(item) => {
                void onReviewUpdate(item)
              }}
              onReviewAction={(name) => {
                void onReviewAction(name)
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="body2"
              component="p"
              fontWeight={500}
              color={theme.palette.grey[700]}
              sx={{
                paddingBottom: '8px',
              }}
            >
              {reviewMode === 'new'
                ? 'What dishes did you like?'
                : 'Reviewed Dishes'}
            </Typography>
            <InputComponent
              id="dishSearch-review"
              value={dishSearch}
              setValue={onDishSearchChange}
              placeholder="Search dish name"
              type="outline"
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                maxHeight: '480px',
                overflowY: 'auto',
                marginTop: '10px',
              }}
            >
              {dish.map((item, index) => (
                <div key={index} style={{ width: '100%' }}>
                  <RestaurantDishesCard
                    isLoading={
                      dishLoader && selectedDishRating.id === item.dish.dishID
                    }
                    id={item?.dish?.dishID}
                    image={
                      item?.dish?.imageurl && item.dish.imageurl.length !== 0
                        ? item?.dish?.imageurl
                        : '/images/restaurant-logo.jpg'
                    }
                    title={item?.dish?.name}
                    description={item?.dish?.description}
                    onChangeRating={(rating) => {
                      void onRating(rating)
                    }}
                    isEditable
                    rating={item?.dish?.starCount ?? 0}
                    tagChipData={[
                      {
                        percentage: `${item?.probability?.yes.toFixed(2)}`,
                        title: 'Match',
                      },
                    ]}
                  />
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default ReviewsTab
