import {
  Box,
  styled as muiStyled,
  Typography,
  TypographyProps,
} from '@mui/material'
import styled from 'styled-components'

export const HeroSectionImgOne = styled(Box).attrs(() => ({
  sx: {
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${'/images/homepage/hero-background-1.png'})`,
    backgroundRepeat: 'no-repeat',
    top: { xs: '150px', md: '50px' },
    left: '0px',
    width: '65%',
    height: { xs: '50%', md: '70%' },
  },
}))(({ theme }) => ({}))

export const HeroSectionImgTwo = styled(Box).attrs(() => ({
  sx: {
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url(${'/images/homepage/hero-background-2.png'})`,
    backgroundRepeat: 'no-repeat',
    top: { xs: '400px', md: '0px' },
    right: '0px',
    width: '65%',
    height: { xs: '72vw', md: '60%' },
  },
}))(({ theme }) => ({}))

export const HeroSectionGridContainer = muiStyled('div')(({ theme }) => {
  return {
    padding: '110px 0px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '32px 0px 10px 0px',
    },
  }
})

export const HeroSectionGridContainerTypographyChip = muiStyled(
  Typography,
)<TypographyProps>(({ theme }) => {
  return {
    letterSpacing: '0.09rm',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50px',
    padding: '8px 16px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      padding: '4px 12px',
    },
  }
})

export const HeroSectionGridContainerTypographyTitle = muiStyled(
  Typography,
)<TypographyProps>(({ theme }) => {
  return {
    marginTop: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down(370)]: {
      fontSize: '1.8rem',
    },
  }
})

export const HeroSectionGridContainerTypographyTitleSecond = muiStyled(
  Typography,
)<TypographyProps>(({ theme }) => {
  return {
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down(370)]: {
      fontSize: '1.8rem',
    },
  }
})

export const HeroSectionGridContainerTypographyInfo = muiStyled(
  Typography,
)<TypographyProps>(({ theme }) => {
  return {
    paddingTop: '24px',
    paddingBottom: 10,
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      maxWidth: '80%',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '95%',
    },
  }
})

export const HeroSectionAsOnContainer = styled(Box).attrs(() => ({
  item: true,
  xs: 12,
  sx: {
    flexDirection: 'column',
    textAlign: 'center',
  },
}))(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '16px',
}))

export const HeroSectionGridContainerTypographyAsOn = muiStyled(
  Typography,
)<TypographyProps>(() => {
  return {
    fontWeight: 500,
  }
})

export const HeroSectionImgAsOnContainer = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 32,
    paddingTop: '10px',
    [theme.breakpoints.down(500)]: {
      gap: 20,
    },
  }
})

export const HeroSectionImg = muiStyled('img')(({ theme }) => {
  return {
    height: '38px',
    [theme.breakpoints.up('md')]: {
      filter: 'grayscale(100%)',
    },
  }
})

export const HeroSectionContainerSideImg = muiStyled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  }
})
