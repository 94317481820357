import * as React from 'react'
import Modal from '@mui/material/Modal'
import {
  Box,
  CircularProgress,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import CustomButton from '../ui/CustomButton'
import { IconX } from '@tabler/icons-react'

interface BasicModalProps {
  open: boolean
  handleClose: () => void
  children: React.ReactNode
  title?: string
  buttonName: string
  cancelButtonName?: string
  deleteModal?: boolean
  onCancel: () => void
  onConfirm: () => void
  cancelIcon?: boolean
  modelWidth?: string
  isLoader?: boolean
  saveBtnDisabled?: boolean
  modelOpenType?: 'popup' | 'drawer'
}

const BasicModal: React.FC<BasicModalProps> = ({
  open,
  handleClose,
  children,
  title,
  buttonName,
  deleteModal,
  onCancel,
  onConfirm,
  cancelButtonName = 'Cancel',
  cancelIcon = true,
  modelWidth = '400px',
  isLoader = false,
  saveBtnDisabled = false,
  modelOpenType = 'popup',
}) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const FallbackModal = React.useCallback(
    () => (
      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            color={theme.palette.grey[800]}
            component="p"
            variant="h4"
            fontSize="18px !important"
          >
            {title}
          </Typography>
          {cancelIcon && (
            <IconX
              stroke={2}
              style={{
                cursor: 'pointer',
              }}
              onClick={handleClose}
            />
          )}
        </div>

        <Box>{children}</Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <CustomButton
            title={cancelButtonName}
            onClick={onCancel}
            fullWidth
            disabled={isLoader}
            sx={{
              backgroundColor: 'white',
              borderColor: theme.palette.primary.light,
              color: theme.palette.primary.light,
              fontSize: '14px !important',
              paddingY: '8px',
            }}
          />
          <CustomButton
            title={buttonName}
            icon={
              isLoader ? <CircularProgress color="inherit" size={16} /> : null
            }
            disabled={isLoader || saveBtnDisabled}
            disabledTextColor="white"
            fullWidth
            backgroundColor={
              buttonName === 'Delete'
                ? theme.palette.error.main
                : theme.palette.primary.light
            }
            onClick={onConfirm}
            sx={{
              backgroundColor: deleteModal
                ? theme.palette.error.main
                : buttonName === 'Delete'
                ? theme.palette.error.main
                : theme.palette.primary.light,
              color: 'white',
              border: 'none',
              fontSize: '14px !important',
              paddingY: '9px',
            }}
            type="submit"
          />
        </Box>
      </div>
    ),
    [
      title,
      cancelButtonName,
      buttonName,
      deleteModal,
      isLoader,
      saveBtnDisabled,
      onCancel,
      onConfirm,
      cancelIcon,
      handleClose,
    ],
  )

  return (
    <>
      {modelOpenType === 'drawer' && isSmallScreen ? (
        <Drawer
          open={open}
          onClose={handleClose}
          anchor={'bottom'}
          sx={{
            '& .MuiPaper-root': {
              borderTopLeftRadius: '16px',
              borderTopRightRadius: '16px',
              p: '16px',
            },
          }}
        >
          <FallbackModal />
        </Drawer>
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              borderRadius: '16px',
              p: { xs: '16px', md: '24px' },
              width: isSmallScreen ? 'calc(100% - 20px)' : modelWidth,
            }}
          >
            <FallbackModal />
          </Box>
        </Modal>
      )}
    </>
  )
}

export default BasicModal
