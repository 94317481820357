import { useAuth0 } from '@auth0/auth0-react'
import { clearLocalStorage } from '../helpers/auth-helper'

interface AuthHelper {
  logoutAuthUser: () => void
}

export const useAuthHelper = (): AuthHelper => {
  const { logout } = useAuth0()

  const logoutAuthUser = (): void => {
    clearLocalStorage()
    void logout({ logoutParams: { returnTo: window.location.origin } })
  }

  return { logoutAuthUser }
}
