import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'

import Rating from '@mui/material/Rating'
import CustomButton from '../ui/CustomButton'
import TagChip from '../ui/TagChip'
import { IChipData } from '../../types'
import { useAuth0 } from '@auth0/auth0-react'
import { IRestaurantDishRatingReview } from '../../pages/eater/restaurant/components/ReviewsTab'
import { IconStarFilled } from '@tabler/icons-react'

interface FoodCardProps {
  image: string
  title: string
  description: string
  isEditable?: boolean
  price?: number
  rating?: number
  isButton?: boolean
  id: string
  onRemove?: () => void
  onChangeRating?: (item: IRestaurantDishRatingReview) => void
  tagChipData?: IChipData[]
  isLoading?: boolean
}

const RestaurantDishesCard: React.FC<FoodCardProps> = ({
  image,
  rating,
  description,
  title,
  isEditable = false,
  isButton = false,
  price,
  id,
  onRemove,
  onChangeRating,
  tagChipData,
  isLoading,
}) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { isAuthenticated } = useAuth0()
  const [loaded, setLoaded] = useState(false)
  const [newRating, setNewRating] = React.useState<number | null>(rating ?? 0)

  useEffect(() => {
    if (rating) {
      setNewRating(rating)
    }
  }, [rating])

  const handleRemove = (): void => {
    if (onRemove && !isLoading) {
      onRemove()
    }
  }

  const onRating = (rating: number | null): void => {
    setNewRating(rating)
    if (onChangeRating)
      onChangeRating({ id, starCount: rating !== null ? rating : 0 })
  }

  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <Card
        sx={{
          width: '100%',
          display: 'flex',
          border: 'none',
          boxShadow: 'none',
          p: 1,
          cursor: 'pointer',
          borderRadius: '16px',
          '&.MuiCard-root:hover': {
            backgroundColor: theme.palette.grey[50],
          },
          filter: isLoading ? 'blur(2px)' : 'none',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            minWidth: isSmallScreen ? '124px' : '216px',
            height: isSmallScreen ? '96px' : '136px',
          }}
        >
          {!loaded && (
            <Skeleton
              variant="rectangular"
              sx={{
                height: '100%',
                width: '100%',
                borderRadius: '16px',
                border: `2px solid ${theme.palette.grey[200]}`,
                boxShadow: '0px 0px 2px 0px #1A1A1A40',
              }}
            />
          )}
          <CardMedia
            sx={{
              height: '100%',
              width: '100%',
              borderRadius: '16px',
              objectFit: 'cover',
              border: `2px solid ${theme.palette.grey[200]}`,
              boxShadow: '0px 0px 2px 0px #1A1A1A40',
              display: loaded ? 'block' : 'none',
            }}
            component="img"
            loading="eager"
            image={image}
            alt={`resactly-${title}-Dish`}
            onLoad={() => setLoaded(true)}
          />
          <div
            style={{
              position: 'absolute',
              top: '6px',
              left: '6px',
              zIndex: 100,
            }}
          >
            {tagChipData && (
              <TagChip
                data={tagChipData}
                bgColor="liteOrange"
                textBlur={!isAuthenticated}
              />
            )}
          </div>
        </Box>

        <CardContent
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            p: '0px 0px 0px 16px',
            paddingBottom: '0px !important',
          }}
        >
          <div>
            <Typography
              variant="body2"
              color={theme.palette.grey[800]}
              textTransform="capitalize"
            >
              {title.toLocaleLowerCase()}
            </Typography>
            <Typography variant="subtitle1" color={theme.palette.grey[800]}>
              {description}
            </Typography>
          </div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <>
              {Number(price) > 0 && (
                <Typography variant="body2" color={theme.palette.grey[800]}>
                  ${price}
                </Typography>
              )}
              {typeof rating === 'number' && (
                <Rating
                  name={isEditable ? 'simple-controlled' : 'read-only'}
                  value={newRating}
                  readOnly={!isLoading ? !isEditable : true}
                  onChange={(event, newValue) => {
                    onRating(newValue)
                  }}
                  sx={{
                    '& .MuiRating-icon': {
                      margin: '0 2px',
                    },
                    '& .MuiRating-iconFilled': {
                      color: theme.palette.warning.light,
                    },
                    '& .MuiRating-iconHover': {
                      color: theme.palette.warning[300],
                    },
                  }}
                  icon={<IconStarFilled size={20} />}
                  emptyIcon={
                    <IconStarFilled size={20} color={theme.palette.grey[200]} />
                  }
                />
              )}
              {isEditable && isButton && (
                <CustomButton
                  title="Remove"
                  backgroundColor={theme.palette.common.white}
                  textColor={theme.palette.primary.light}
                  hoverColor={theme.palette.primary.light}
                  sx={{
                    borderColor: theme.palette.grey[300],
                    borderRadius: '10px',
                  }}
                  onClick={handleRemove}
                />
              )}
            </>
          </Box>
        </CardContent>
      </Card>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
            transition: 'transform 0.3s ease-in-out',
          }}
        >
          <CircularProgress color="inherit" />
        </div>
      )}
    </div>
  )
}

export default RestaurantDishesCard
