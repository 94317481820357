import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  isAnyOf,
} from '@reduxjs/toolkit'
import { RootState } from './'
import { UserInfo, Login, NewsletterSubscriptionRequest } from '../types'
import okaoAxios, { okaoAxiosNoAuth } from '../helpers/axios'
import { Location } from '../helpers/location-helper'
import { AlertColor } from '@mui/material/Alert'
import { favoriteRestaurant } from './restaurantSlice'
import { setAccessTokenToLocal } from '../helpers/auth-helper'
import {
  Restaurant,
  RestaurantOnboardRequest,
  RestaurantUpdateContactRequest,
} from '../types/restaurantTypes'
export interface ISecureSlice {
  accessToken: string | undefined
  user: UserInfo | null
  login: Login
  location?: Location | undefined
  shouldOpen: boolean
  message: string | undefined
  severity: AlertColor | undefined
  reloadEater: boolean
  imageUrl: string
  retry: number
  subscribeSuccess: boolean
  status: string
}

const initialState: ISecureSlice = {
  accessToken: undefined,
  user: null,
  login: {},
  shouldOpen: false,
  message: undefined,
  severity: undefined,
  reloadEater: false,
  imageUrl: '',
  retry: 0,
  subscribeSuccess: false,
  status: 'idle',
}

export const getUserInfo = createAsyncThunk('api/getUserInfo', async () => {
  const response = await okaoAxios.get(
    `https://${process.env.REACT_APP_AUTH0_DOMAIN ?? ''}/userinfo`,
  )
  return response?.data
})

export const loginAudit = createAsyncThunk('api/loginAudit', async () => {
  const response = await okaoAxios.post('/login')
  return response?.data
})

export const updateRestaurantOnboard = createAsyncThunk(
  'api/updateRestaurantOnboard',
  async (nmr: RestaurantUpdateContactRequest) => {
    const response = await okaoAxios.post('/restaurants/onboard-update', nmr)
    return response?.data
  },
)

export const restaurantOnboardRequest = createAsyncThunk(
  'api/restaurantOnboard',
  async (nmr: RestaurantOnboardRequest) => {
    const response = await okaoAxios.post('/restaurants/onboard', nmr)
    return response?.data
  },
)

export const subscribeToNewsletter = createAsyncThunk(
  'api/subscribeNewsletter',
  async (nsr: NewsletterSubscriptionRequest) => {
    const response = await okaoAxiosNoAuth().post('/user-subscription', nsr)
    return response?.data
  },
)

export const deleteUserProfile = createAsyncThunk(
  'api/delete-account',
  async (params: any) => {
    const response = await okaoAxios.delete('/eater/profile/delete-account', {
      data: params,
    })
    return response
  },
)

export const recoverUserAccount = createAsyncThunk(
  'api/recoverUserAccount',
  async () => {
    const response = await okaoAxios.put('/eater/profile/recover-account')
    return response
  },
)

export const recoverUserBlockedAccount = createAsyncThunk(
  'api/recoverUserBlockedAccount',
  async () => {
    const response = await okaoAxios.post('/eater/account-unblock-request')
    return response
  },
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string | undefined>) => {
      state.accessToken = action.payload
      setAccessTokenToLocal(state.accessToken ?? '')
    },
    setLocation: (state, action: PayloadAction<Location | undefined>) => {
      state.location = action.payload
      window.localStorage.setItem('location', JSON.stringify(state.location))
    },
    setRestaurant: (state, action: PayloadAction<Restaurant>) => {
      state.login.restaurantDTO = action.payload
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.shouldOpen = action.payload
    },
    setSubscribeSuccess: (state, action: PayloadAction<boolean>) => {
      state.subscribeSuccess = action.payload
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    },
    setSeverity: (state, action: PayloadAction<AlertColor>) => {
      state.severity = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.user = action?.payload
        window.localStorage.setItem('userInfo', JSON.stringify(state.user))
      })
      .addCase(loginAudit.fulfilled, (state, action) => {
        state.login = action.payload
        if (action.payload.eaterDTO != null) {
          state.imageUrl = action.payload.eaterDTO.imageUrl
        } else {
          state.imageUrl = '/images/male.png'
        }
        window.localStorage.setItem('login', JSON.stringify(state.login))
      })
      .addCase(loginAudit.rejected, (state, action) => {
        state.shouldOpen = true
        state.message =
          'Oops! We had a little trouble logging you into Resactly. Could you please try refreshing the page? Thanks for your understanding!'
        state.severity = 'error'
        location.reload()
      })

      .addCase(favoriteRestaurant.fulfilled, (state, action) => {
        state.reloadEater = true
      })
      .addCase(updateRestaurantOnboard.fulfilled, (state, action) => {
        state.login = action.payload
        window.localStorage.setItem('login', JSON.stringify(state.login))
      })
      .addCase(restaurantOnboardRequest.fulfilled, (state, action) => {
        state.login = action.payload
        window.localStorage.setItem('login', JSON.stringify(state.login))
      })

      .addCase(subscribeToNewsletter.fulfilled, (state, action) => {
        state.subscribeSuccess = true
        state.status = 'idle'
      })
      .addCase(subscribeToNewsletter.rejected, (state, action) => {
        state.message =
          'Oops! Please log in first, then subscribe. Thanks for your understanding!'
        state.severity = 'error'
        state.status = 'error'
        state.shouldOpen = true
      })
      .addCase(deleteUserProfile.fulfilled, (state, action) => {
        state.severity = 'success'
        window.localStorage.clear()
        state.status = 'idle'
      })
      .addCase(deleteUserProfile.rejected, (state, action) => {
        state.message =
          'Oops! We had a little trouble deleting your account. Could you please try refreshing the page? Thanks for your understanding!'
        state.severity = 'error'
        state.status = 'error'
        state.shouldOpen = true
      })
      .addCase(recoverUserAccount.fulfilled, (state, action) => {
        state.login = action.payload.data
        window.localStorage.setItem(
          'login',
          JSON.stringify(action.payload.data),
        )
        state.status = 'idle'
      })
      .addCase(recoverUserAccount.rejected, (state, action) => {
        state.shouldOpen = true
        state.message =
          'Oops! We had a little trouble recovering your account. Could you please try refreshing the page? Thanks for your understanding!'
        state.severity = 'error'
        state.status = 'error'
        state.shouldOpen = true
      })
      .addCase(recoverUserBlockedAccount.fulfilled, (state, action) => {
        state.message = action.payload.data.message
        state.shouldOpen = true
        state.status = 'idle'
      })
      .addCase(recoverUserBlockedAccount.rejected, (state, action) => {
        state.shouldOpen = true
        state.message =
          'Oops! We had a little trouble while sending unblock request. Could you please try refreshing the page? Thanks for your understanding!'
        state.severity = 'error'
        state.status = 'error'
      })
      .addMatcher(
        isAnyOf(
          recoverUserAccount.pending,
          recoverUserBlockedAccount.pending,
          deleteUserProfile.pending,
          subscribeToNewsletter.pending,
        ),
        (state, action) => {
          state.status = 'loading'
        },
      )
  },
})

export const selectAccessToken = (state: RootState): string | undefined =>
  state.auth.accessToken ?? window.localStorage.getItem('access-token') ?? ''
export const selectUserInfo = (state: RootState): UserInfo | null =>
  state.auth.user
export const selectLogin = (state: RootState): Login | undefined =>
  state.auth.login ?? JSON.parse(window.localStorage.getItem('login') ?? '{}')
export const selectLocation = (state: RootState): Location | undefined =>
  state.auth.location
export const selectRestaurant = (state: RootState): Restaurant | undefined =>
  state.auth.login?.restaurantDTO ?? undefined
export const selectShouldOpen = (state: RootState): boolean =>
  state.auth.shouldOpen
export const selectShouldReloadEater = (state: RootState): boolean =>
  state.auth.reloadEater
export const selectMessage = (state: RootState): string | undefined =>
  state.auth.message
export const selectSeverity = (state: RootState): AlertColor | undefined =>
  state.auth.severity
export const selectImageUrl = (state: RootState): string => state.auth.imageUrl
export const selectSubscribeSuccess = (state: RootState): boolean =>
  state.auth.subscribeSuccess
export const selectIsLoading = (state: RootState): boolean =>
  state.auth.status === 'loading'

export const {
  setAccessToken,
  setLocation,
  setRestaurant,
  setOpen,
  setMessage,
  setSeverity,
  setSubscribeSuccess,
} = authSlice.actions
export default authSlice.reducer
